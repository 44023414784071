import React from 'react';
import { Helmet } from 'react-helmet';
import { Button, Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import { disableFlagsAfterError } from '@dietlabs/components/src/Flag/utils';
import Layout from 'Layout/Layout';

class ErrorGeneric extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        topLevel: PropTypes.bool,
        message: PropTypes.string,
        additionalMessage: PropTypes.string,
        actionLabel: PropTypes.string,
        imageUrl: PropTypes.string,
        onActionClick: PropTypes.func,
        noRouter: PropTypes.bool,
        isNetworkError: PropTypes.bool,
    };

    static defaultProps = {
        topLevel: false,
        message: undefined,
        additionalMessage: undefined,
        actionLabel: undefined,
        imageUrl: undefined,
        onActionClick: undefined,
        noRouter: false,
        isNetworkError: false,
    };

    componentDidMount() {
        disableFlagsAfterError();

        if (global.localStorage && !this.props.isNetworkError) {
            localStorage.removeItem('apollo-cache-persist');
        }
    }

    getMessage() {
        if (this.props.message) {
            return this.props.message;
        }

        const { t } = this.props;
        return t('error/message/generic');
    }

    getActionLabel() {
        if (this.props.actionLabel) {
            return this.props.actionLabel;
        }

        const { t } = this.props;

        if (window.history && window.history.length > 1) {
            return t('button/go-back');
        }

        return t('button/try-again');
    }

    handleActionClick() {
        if (this.props.onActionClick) {
            this.props.onActionClick();
        } else if (window.history && window.history.length > 1) {
            window.history.back();
        } else {
            window.location.reload();
        }
    }

    renderAdditionalMessage() {
        const { t } = this.props;
        let { additionalMessage } = this.props;

        if (additionalMessage === undefined) {
            additionalMessage = t('error/additional-message/generic');
        } else if (!additionalMessage) {
            return null;
        }

        return <p>{additionalMessage}</p>;
    }

    renderImage() {
        const { imageUrl } = this.props;

        if (!imageUrl) {
            return null;
        }

        return (
            <img
                className="mb-4 img-fluid image error-image"
                src={imageUrl}
                role="presentation"
                onError={({ target }) => target.remove()}
            />
        );
    }

    renderAction() {
        return (
            <div>
                <Button
                    color="primary"
                    className="mb-4 w-100"
                    onClick={() => this.handleActionClick()}
                >
                    {this.getActionLabel()}
                </Button>
            </div>
        );
    }

    renderErrorAlert({ withAction } = { withAction: false }) {
        return (
            <Container>
                <Row>
                    <Col xs="12" className="text-center">
                        {this.renderImage()}
                        <h2 className="title-medium semi-bold">
                            {this.getMessage()}
                        </h2>
                        {this.renderAdditionalMessage()}
                        {!withAction || this.renderAction()}
                    </Col>
                </Row>
            </Container>
        );
    }

    renderErrorPage() {
        return (
            <Layout page="error" forceReloadLinks>
                <Helmet>
                    <title>{this.getMessage()}</title>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <section className="bg-white mt-5 pt-5">
                    {this.renderErrorAlert({ withAction: true })}
                </section>
            </Layout>
        );
    }

    render() {
        if (this.props.noRouter) {
            return this.renderErrorAlert({ withAction: true });
        }

        if (this.props.topLevel) {
            return this.renderErrorPage();
        }

        return this.renderErrorAlert();
    }
}

export default ErrorGeneric;
