import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { subDays } from 'utils/date';
import DayConnector from '@dietlabs/components/src/ApolloConnectors/Hydration/FetchHydrationDay/HydrationDayConnectorApollo';
import ChangeGoalConnector from '@dietlabs/components/src/ApolloConnectors/Hydration/ChangeGoal/HydrationDayChangeGoalConnectorApollo';
import PortionAddConnector from '@dietlabs/components/src/ApolloConnectors/Hydration/AddPortion/HydrationAddConnectorApollo';
import PortionRemoveConnector from '@dietlabs/components/src/ApolloConnectors/Hydration/RemovePortion/HydrationDayPortionRemoveConnectorApollo';
import HistoryConnector from '@dietlabs/components/src/ApolloConnectors/Hydration/History/HydrationHistoryConnectorApollo';
import DayContainer from './HydrationDayContainer';

class HydrationDayPage extends Component {
    state = {
        length: 7,
    };

    setLength = length => this.setState({ length });

    render() {
        const date = new Date();

        const to = new Date();
        const from = subDays(to, this.state.length - 1);

        return (
            <DayConnector date={date}>
                {({
                    loading,
                    cacheHit,
                    error,
                    data: { goal, hydrationSum } = {},
                    queryResult,
                }) => (
                    <ChangeGoalConnector date={date} queryResult={queryResult}>
                        {(changeGoal, changeGoalMessages) => (
                            <PortionAddConnector
                                date={date}
                                queryResult={queryResult}
                            >
                                {(createAdd, addPortionMessages) => (
                                    <PortionRemoveConnector
                                        date={date}
                                        queryResult={queryResult}
                                    >
                                        {(
                                            removeLastPortion,
                                            removePortionMessages
                                        ) => {
                                            return (
                                                <HistoryConnector
                                                    dateFrom={from}
                                                    dateTo={to}
                                                >
                                                    {({
                                                        loading2,
                                                        unit,
                                                        defaultDailyGoal,
                                                        days,
                                                    }) => (
                                                        <DayContainer
                                                            loading={loading}
                                                            cacheHit={cacheHit}
                                                            hasNetworkError={Boolean(
                                                                error
                                                            )}
                                                            date={date}
                                                            changeGoal={
                                                                changeGoal
                                                            }
                                                            changeGoalMessages={
                                                                changeGoalMessages
                                                            }
                                                            removeLastPortion={
                                                                removeLastPortion
                                                            }
                                                            removeLastPortionMessages={
                                                                removePortionMessages
                                                            }
                                                            goal={goal}
                                                            value={hydrationSum}
                                                            add={createAdd}
                                                            messages={
                                                                addPortionMessages
                                                            }
                                                            loading2={loading2}
                                                            dateFrom={from}
                                                            dateTo={to}
                                                            unit={unit}
                                                            defaultDailyGoal={
                                                                defaultDailyGoal
                                                            }
                                                            days={days}
                                                            historyLength={
                                                                this.state
                                                                    .length
                                                            }
                                                            setHistoryLength={
                                                                this.setLength
                                                            }
                                                        />
                                                    )}
                                                </HistoryConnector>
                                            );
                                        }}
                                    </PortionRemoveConnector>
                                )}
                            </PortionAddConnector>
                        )}
                    </ChangeGoalConnector>
                )}
            </DayConnector>
        );
    }
}

export { HydrationDayPage };
export default withRouter(HydrationDayPage);
