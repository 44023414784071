import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';
import CurrentMeasurementsConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Measurement/CurrentMeasurements/CurrentMeasurementsConnectorApollo';
import CurrentMeasurementsContainer from 'Measurement/CurrentMeasurements/CurrentMeasurementsContainer';
import MeasurementHistoryConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Measurement/MeasurementHistory/MeasurementHistoryConnectorApollo';
import {
    MEASUREMENT_TYPES,
    PERIOD_END,
    PERIOD_LENGTH,
} from 'Measurement/constants';
import MeasurementHistoryContainer from 'Measurement/MeasurementHistory/MeasurementHistoryContainer';
import { Container, Row, Col } from 'reactstrap';
import headerBg from 'assets/images/measurements/img_measurements.png';

class MeasurementsPage extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="measurement">
                <div className="header-bg">
                    <img src={headerBg} className="img-fluid" alt="" />
                </div>
                <section className="page">
                    <Container>
                        <Row>
                            <Col>
                                <CurrentMeasurementsConnectorApollo>
                                    {currentMeasurementData => (
                                        <CurrentMeasurementsContainer
                                            {...currentMeasurementData}
                                        />
                                    )}
                                </CurrentMeasurementsConnectorApollo>

                                <MeasurementHistoryConnectorApollo
                                    initialMeasurementType={
                                        MEASUREMENT_TYPES.WEIGHT
                                    }
                                    periodLength={PERIOD_LENGTH}
                                    periodEnd={PERIOD_END}
                                >
                                    {measurementHistoryData => (
                                        <MeasurementHistoryContainer
                                            {...measurementHistoryData}
                                        />
                                    )}
                                </MeasurementHistoryConnectorApollo>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLocale(MeasurementsPage);
