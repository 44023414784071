import * as Sentry from '@sentry/browser';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ParallaxProvider } from 'react-scroll-parallax';

import getLanguage from 'utils/getLanguage';
import ErrorBoundary, {
    ErrorBoundary as ErrorBoundaryWithoutTranslator,
} from 'view/ErrorBoundary/ErrorBoundary';
import GoogleTagManager from 'view/GoogleTagManager/GoogleTagManager';
import GlobalDataLayerContainer from 'view/GoogleTagManager/GlobalDataLayerContainer';
import AuthLoginByTokenContainer from '@dietlabs/components/src/Auth/Login/ByToken/AuthLoginByTokenContainer';
import MeContainer from '@dietlabs/components/src/Me/MeContainer';
import Apollo from '@dietlabs/components/src/Apollo/Apollo';
import { TranslationProvider } from '@dietlabs/components';
import FlashMessageProvider from '@dietlabs/components/src/FlashMessage/FlashMessageProvider';
import SnackbarProvider from 'react-simple-snackbar';
import { ApolloProvider } from '@apollo/client';
import client from './Apollo/ApolloClient';
import { SENTRY_DSN } from './consts';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import SaleSourcesProviderContainer from './view/SaleSources/SaleSourcesProviderContainer';
import packageJson from '../package.json';
import translations from './locales/en/translations';

import store from './store';

const lang = getLanguage();

const quotaExceededErrorMessageRegexp = /QuotaExceededError|QUOTA_EXCEEDED_ERR|^The quota has been exceeded\.$|Setting (the )?value of '.*' exceeded the quota\.$/;
const networkErrorRegexp = /Połączenie sieciowe zostało utracone|Failed to fetch|GraphQL fetch failed|Network error/;

document.documentElement.lang = lang;

if (window.BROWSER_SUPPORTED) {
    Sentry.init({
        dsn: SENTRY_DSN,
        release: packageJson.version,
        environment: process.env.REACT_APP_ENV,
        beforeSend(event, hint) {
            const error = hint.originalException;
            if (
                error &&
                (error.message.match(quotaExceededErrorMessageRegexp) ||
                    error.message.match(networkErrorRegexp))
            ) {
                return null;
            }

            try {
                const fingerprint = [];
                if (!error.noDefaultFingerprint) {
                    fingerprint.push('{{ default }}');
                }
                if (error.fingerprint) {
                    fingerprint.push(...error.fingerprint);
                }

                // eslint-disable-next-line no-param-reassign
                event.fingerprint = fingerprint;
            } catch (e) {
                // ignore
            }

            return event;
        },
    });

    render(
        <ErrorBoundaryWithoutTranslator topLevel t={t => t} noRouter>
            <TranslationProvider
                defaultLanguage={lang}
                defaultLanguageTranslations={translations}
            >
                <ErrorBoundary topLevel noRouter>
                    <Provider store={store}>
                        <Apollo>
                            <ApolloProvider client={client}>
                                <GoogleTagManager>
                                    <SaleSourcesProviderContainer>
                                        <AuthLoginByTokenContainer>
                                            <FlashMessageProvider>
                                                <MeContainer>
                                                    <ParallaxProvider>
                                                        <SnackbarProvider>
                                                            <App />
                                                        </SnackbarProvider>
                                                    </ParallaxProvider>
                                                    <GlobalDataLayerContainer />
                                                </MeContainer>
                                            </FlashMessageProvider>
                                        </AuthLoginByTokenContainer>
                                    </SaleSourcesProviderContainer>
                                </GoogleTagManager>
                            </ApolloProvider>
                        </Apollo>
                    </Provider>
                </ErrorBoundary>
            </TranslationProvider>
        </ErrorBoundaryWithoutTranslator>,
        document.getElementById('root')
    );

    registerServiceWorker();
}
