import { PATH_SETTINGS } from 'UserSettings/routes';
import { PATH_MEASUREMENT_INDEX } from 'Measurement/routes';
import { PATHS } from '../paths';

export default [
    {
        path: PATH_SETTINGS,
        translationKey: 'menu/settings',
    },
    {
        path: PATH_MEASUREMENT_INDEX,
        translationKey: 'menu/measurements',
    },
    {
        path: PATHS.DIET_SETTINGS,
        translationKey: 'menu/diet/settings',
    },
    {
        path: PATHS.AUTH.LOGOUT,
        translationKey: 'menu/logout',
    },
];
