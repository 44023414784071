import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import CreateSubscriptionMutation from './CreateSubscriptionMutation';

class CreateSubscriptionConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    createBuy = mutate => async request => {
        const response = await mutate({
            variables: request,
        });

        return response;
    };

    render() {
        const { children } = this.props;

        return (
            <Mutation mutation={CreateSubscriptionMutation}>
                {mutate =>
                    children({
                        buy: this.createBuy(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default CreateSubscriptionConnectorApollo;
