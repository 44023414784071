import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { PATHS } from 'config/paths';
import logo from 'assets/images/mlf_logo.svg';

class SimpleMenu extends Component {
    render() {
        return (
            <section id="main-menu">
                <Container className="text-center">
                    <a href={PATHS.EXTERNAL.HOME}>
                        <img src={logo} className="my-3" alt="ML.fitness" />
                    </a>
                </Container>
            </section>
        );
    }
}

export default SimpleMenu;
