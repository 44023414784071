import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { DIET_ID_CLASSIC, DIET_ID_VEGE } from 'consts.js';
import { formatLongDate } from 'utils/dateFormatter';
import withStep from './StepContext/withStep';

class StepStart extends React.Component {
    static propTypes = {
        weight: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        dietId: PropTypes.number.isRequired,
        dietMode: PropTypes.string.isRequired,
        goalWeight: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        activityLevel: PropTypes.string.isRequired,
        sex: PropTypes.string.isRequired,
        height: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        dateOfBirth: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div className="full-screen3">
                <div className="w-100">
                    <div className="white-box mt-4">
                        <div className="user-setting-parameter-parameter">
                            <p className="left">
                                {this.props.t(
                                    'diet-settings/diet-id/label/intro'
                                )}
                                :
                            </p>
                            <p className="right">
                                {this.props.dietId === DIET_ID_CLASSIC &&
                                    this.props.t(
                                        'diet-settings/diet-type/classic'
                                    )}
                                {this.props.dietId === DIET_ID_VEGE &&
                                    this.props.t(
                                        'diet-settings/diet-type/vege'
                                    )}
                            </p>
                        </div>
                        <div className="user-setting-parameter-parameter">
                            <p className="left">
                                {this.props.t(
                                    'diet-settings/current-body-weight'
                                )}
                                :
                            </p>
                            <p className="right">
                                {this.props.weight.value}{' '}
                                {this.props.weight.unit}
                            </p>
                        </div>
                        <div className="user-setting-parameter-parameter">
                            <p className="left">
                                {this.props.t('diet-settings/goal/label/intro')}
                                :
                            </p>
                            <p className="right">
                                {this.props.dietMode === 'slimming' &&
                                    this.props.t('diet-settings/goal/slimming')}
                                {this.props.dietMode === 'stabilization' &&
                                    this.props.t(
                                        'diet-settings/goal/stabilization'
                                    )}
                                {this.props.dietMode === 'put_on_weight' &&
                                    this.props.t(
                                        'diet-settings/goal/put-on-weight'
                                    )}
                                {this.props.dietMode === 'gain_muscle_mass' &&
                                    this.props.t(
                                        'diet-settings/goal/gain-muscle-mass'
                                    )}
                            </p>
                        </div>
                        <div className="user-setting-parameter-parameter">
                            <p className="left">
                                {this.props.t('diet-settings/goal-weight')}:
                            </p>
                            <p className="right">
                                {this.props.goalWeight.value}{' '}
                                {this.props.goalWeight.unit}
                            </p>
                        </div>

                        <div className="user-setting-parameter-parameter">
                            <p className="left">
                                {this.props.t(
                                    'diet-settings/activity-level/label/intro'
                                )}
                                :
                            </p>
                            <p className="right">
                                {this.props.activityLevel ===
                                    'ACTIVITY_LEVEL_NONE' &&
                                    this.props.t(
                                        'diet-settings/activity-level/none/title'
                                    )}
                                {this.props.activityLevel ===
                                    'ACTIVITY_LEVEL_LOW' &&
                                    this.props.t(
                                        'diet-settings/activity-level/low/title'
                                    )}
                                {this.props.activityLevel ===
                                    'ACTIVITY_LEVEL_MEDIUM' &&
                                    this.props.t(
                                        'diet-settings/activity-level/medium/title'
                                    )}
                                {this.props.activityLevel ===
                                    'ACTIVITY_LEVEL_HIGH' &&
                                    this.props.t(
                                        'diet-settings/activity-level/high/title'
                                    )}
                            </p>
                        </div>
                    </div>

                    <div className="white-box">
                        <div className="user-setting-parameter-parameter">
                            <p className="left">
                                {this.props.t('diet-settings/sex/label')}:{' '}
                            </p>
                            <p className="right">{this.props.sex}</p>
                        </div>
                        <div className="user-setting-parameter-parameter">
                            <p className="left">
                                {this.props.t('diet-settings/birth-date')}:{' '}
                            </p>
                            <p className="right">
                                {formatLongDate(this.props.dateOfBirth)}
                            </p>
                        </div>
                        <div className="user-setting-parameter-parameter">
                            <p className="left">
                                {this.props.t('diet-settings/height')}:{' '}
                            </p>
                            <p className="right">
                                {this.props.height.value}{' '}
                                {this.props.height.unit}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepStart));
