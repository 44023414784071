import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from 'Layout/Layout';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import Header from 'Layout/Header';
import withGTM from 'view/GoogleTagManager/withGTM';
import { EVENT_CATEGORY_HYDRATION } from 'view/GoogleTagManager/EventCategories';

class Hydration extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        trackEvent: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.trackView();
    }

    trackView() {
        this.props.trackEvent(EVENT_CATEGORY_HYDRATION, 'view');
    }

    render() {
        const { children } = this.props;

        return (
            <Layout page="hydration">
                <section>
                    <Header>{this.props.t('route-title/hydration-day')}</Header>
                    <Container>
                        <Row>
                            <Col
                                xs={12}
                                md={{ size: 8, offset: 2 }}
                                lg={{ size: 4, offset: 4 }}
                            >
                                {children()}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withGTM(withLocale(Hydration));
