import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Row, Col, Button } from 'reactstrap';
import Form from 'Layout/Form/Form';
import { getUnitForType } from 'utils/unit';
import { PATH_MEASUREMENT_INDEX } from 'Measurement/routes';
import InputNumber from 'components/FormElements/InputNumberControlled';
import InputDate from 'components/FormElements/InputDateControlled';
import { Link } from 'react-router-dom';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import { ApiDateType, SystemOfMeasuresType } from 'types/CommonTypes';
import { UNIT_TYPES } from 'consts';

class MeasurementByDateForm extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        date: ApiDateType,
        weight: PropTypes.number,
        hip: PropTypes.number,
        waist: PropTypes.number,
        thigh: PropTypes.number,
        chest: PropTypes.number,
        arm: PropTypes.number,
        systemOfMeasures: SystemOfMeasuresType,
        onSave: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onDateChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        date: undefined,
        weight: undefined,
        hip: undefined,
        waist: undefined,
        thigh: undefined,
        chest: undefined,
        arm: undefined,
        systemOfMeasures: undefined,
    };

    validationRules = {
        date: [new RequiredRule({ translator: this.props.t })],
    };

    handleDelete = event => {
        event.preventDefault();
        this.props.onDelete(this.props.date);
    };

    render() {
        const {
            t,
            systemOfMeasures,
            date,
            weight,
            hip,
            waist,
            thigh,
            chest,
            arm,
            onSave,
            onDateChange,
        } = this.props;

        return (
            <Form
                validationRules={this.validationRules}
                initialValues={{
                    date,
                    weight,
                    hip,
                    waist,
                    thigh,
                    chest,
                    arm,
                }}
                onSubmit={onSave}
            >
                {({ values, validationErrors, validationRules, onChange }) => (
                    <React.Fragment>
                        <InputDate
                            label={t('measurments/date')}
                            name="date"
                            handleChange={event =>
                                onDateChange(event.target.value)
                            }
                            value={values.date}
                            errors={validationErrors}
                            validationRules={validationRules.date}
                        />
                        <InputNumber
                            label={t('measurments/param/body-weight')}
                            name="weight"
                            handleChange={onChange}
                            value={Number(values.weight)}
                            unit={getUnitForType(
                                UNIT_TYPES.BODY_WEIGHT,
                                systemOfMeasures
                            )}
                            errors={validationErrors}
                            validationRules={validationRules.weight}
                        />
                        <InputNumber
                            label={t('measurments/param/hip')}
                            name="hip"
                            handleChange={onChange}
                            value={Number(values.hip)}
                            unit={getUnitForType(
                                UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                                systemOfMeasures
                            )}
                            errors={validationErrors}
                            validationRules={validationRules.hip}
                        />
                        <InputNumber
                            label={t('measurments/param/waist')}
                            name="waist"
                            handleChange={onChange}
                            value={Number(values.waist)}
                            unit={getUnitForType(
                                UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                                systemOfMeasures
                            )}
                            errors={validationErrors}
                            validationRules={validationRules.waist}
                        />
                        <InputNumber
                            label={t('measurments/param/thigh')}
                            name="thigh"
                            handleChange={onChange}
                            value={Number(values.thigh)}
                            unit={getUnitForType(
                                UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                                systemOfMeasures
                            )}
                            errors={validationErrors}
                            validationRules={validationRules.thigh}
                        />
                        <Row>
                            <Col xs="6" className="pr-2">
                                <Button
                                    color="primary"
                                    outline
                                    className="outline w-100"
                                    tag={Link}
                                    to={PATH_MEASUREMENT_INDEX}
                                >
                                    {t('cancel')}
                                </Button>
                            </Col>
                            <Col xs="6" className="pl-2">
                                <Button
                                    color="primary"
                                    className="w-100"
                                    type="submit"
                                >
                                    {t('save')}
                                </Button>
                            </Col>
                        </Row>
                        <Button
                            className="deleteMeasurment"
                            onClick={this.handleDelete}
                        >
                            {t('measurments/delete-measurment-button')}
                        </Button>
                    </React.Fragment>
                )}
            </Form>
        );
    }
}

export default withLocale(MeasurementByDateForm);
