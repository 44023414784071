import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, FormGroup, Alert } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import ShoppingCartDeliveryOption from 'view/ShoppingCart/Delivery/Option/ShoppingCartDeliveryOption';
// import InputRadio from 'components/FormElements/InputRadio';
import InputRadio from 'components/FormElements/InputRadioControlled2';
import ShoppingCartIndexDeliveryDataContainer from 'view/ShoppingCart/Index/Delivery/ShoppingCartIndexDeliveryDataContainer';
import ShoppingCartIndexDeliveryUserDataContainer from 'view/ShoppingCart/Index/Delivery/ShoppingCartIndexDeliveryUserDataContainer';
import Price from 'view/Price/Price';

export class ShoppingCartIndexDeliveryComponent extends React.Component {
    componentDidMount() {
        this.handleOptions();
    }

    handleOptions() {
        if (
            !this.props.saleShippingId ||
            !this.props.options.filter(
                option => option.id === this.props.saleShippingId
            ).length
        ) {
            this.selectDefaultOption();
        }
    }

    selectDefaultOption() {
        const newSaleShippingId = this.props.options[0].id;
        this.props.setDeliveryOption(newSaleShippingId);
    }

    render() {
        return (
            <section className="payment-method-form">
                <Container>
                    {this.props.options.length > 1 ? (
                        <Fragment>
                            <h2>{this.props.t('cart/payment-method')}</h2>

                            <FormGroup tag="fieldset">
                                {this.props.options.map(method => (
                                    <InputRadio
                                        key={method.name}
                                        data-test="delivery-option"
                                        label={method.name}
                                        id={`saleShippingId${method.id}`}
                                        name="saleShippingId"
                                        value={method.id}
                                        handleChange={event =>
                                            this.props.setDeliveryOption(
                                                event.target.value,
                                                event.target.name
                                            )
                                        }
                                        checked={
                                            this.props.saleShippingId ===
                                            method.id
                                        }
                                    />
                                ))}
                            </FormGroup>
                        </Fragment>
                    ) : null}

                    {this.props.isFreeDeliveryAvailable &&
                    this.props.freeFromCartValue ? (
                        <Alert color="success" data-test="free-delivery-info">
                            {this.props.t('cart/free-delivery-info', {
                                price: this.props.freeFromCartValue.toString(),
                            })}
                        </Alert>
                    ) : (
                        ''
                    )}

                    {this.props.areShippingDataNeeded ? (
                        <ShoppingCartIndexDeliveryDataContainer />
                    ) : (
                        ''
                    )}

                    {this.props.isLoggedIn ? (
                        ''
                    ) : (
                        <ShoppingCartIndexDeliveryUserDataContainer />
                    )}
                </Container>
            </section>
        );
    }
}

ShoppingCartIndexDeliveryComponent.propTypes = {
    t: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.instanceOf(ShoppingCartDeliveryOption))
        .isRequired,
    saleShippingId: PropTypes.number,
    areShippingDataNeeded: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    setDeliveryOption: PropTypes.func.isRequired,
    isFreeDeliveryAvailable: PropTypes.bool.isRequired,
    freeFromCartValue: PropTypes.instanceOf(Price),
};

ShoppingCartIndexDeliveryComponent.defaultProps = {
    saleShippingId: undefined,
    freeFromCartValue: undefined,
};

export default withLocale(ShoppingCartIndexDeliveryComponent);
