import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import Layout from 'Layout/Layout';
import ProgressBar from 'Layout/ProgressBar';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import withStep from 'DietSettings/Steps/StepContext/withStep';
import prevIcon from 'assets/icons/prev.svg';

class DietSettings extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        progress: PropTypes.shape({
            stepsCount: PropTypes.number,
            currentStepPosition: PropTypes.number,
        }).isRequired,
        renderAction: PropTypes.func.isRequired,
        prevStep: PropTypes.func.isRequired,
    };

    render() {
        const { children, progress, renderAction, prevStep } = this.props;

        return (
            <Layout page="diet-settings">
                <div
                    className={
                        progress.currentStepPosition === 1
                            ? 'full-screen grey-gradient'
                            : 'full-screen'
                    }
                >
                    {progress.currentStepPosition > 1 &&
                    progress.currentStepPosition !== progress.stepsCount + 1 ? (
                        <header>
                            <Container>
                                <Row className="align-items-center">
                                    <Col xs="2">
                                        <Button
                                            className="prev"
                                            onClick={prevStep()}
                                            type="button"
                                        >
                                            <img src={prevIcon} alt="" />
                                        </Button>
                                    </Col>
                                    <Col xs="8">
                                        <ProgressBar progress={progress} />
                                    </Col>
                                </Row>
                            </Container>
                        </header>
                    ) : (
                        ''
                    )}

                    {progress.currentStepPosition !==
                    progress.stepsCount + 1 ? (
                        <div className="action-buttons">
                            <Container>{renderAction()}</Container>
                        </div>
                    ) : (
                        ''
                    )}

                    <Container>
                        <Row>
                            <Col
                                xs={12}
                                md={{ size: 8, offset: 2 }}
                                lg={{ size: 6, offset: 3 }}
                            >
                                {children()}
                            </Col>
                        </Row>
                    </Container>

                    {progress.currentStepPosition !==
                    progress.stepsCount + 1 ? (
                        <React.Fragment>
                            <div className="action-buttons2">
                                <Container>
                                    <Row>
                                        <Col
                                            xs={12}
                                            md={{ size: 8, offset: 2 }}
                                            lg={{ size: 6, offset: 3 }}
                                        >
                                            {renderAction()}
                                        </Col>
                                    </Row>
                                </Container>
                            </div>

                            <div className="diet-settings-bottom-push" />
                        </React.Fragment>
                    ) : (
                        ''
                    )}
                </div>
            </Layout>
        );
    }
}

export default withStep(withLocale(DietSettings));
