import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import Layout from 'Layout/Layout';
import activatedImg from 'assets/images/activated_img.png';
import { GOOGLE_PLAY_URL, APP_STORE_URL } from 'consts';

import appStore from 'assets/icons/app_store_badge.svg';
import googlePlay from 'assets/icons/google_play_badge.svg';
import qrCode from 'assets/icons/qr_code.svg';
import { withLocale } from '@dietlabs/components';

class DownloadTheAppPage extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="buy">
                <section>
                    <Container>
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <Row>
                                    <Col xs={{ size: 10, offset: 1 }}>
                                        <img
                                            className="img-fluid mb-4"
                                            src={activatedImg}
                                            alt=""
                                        />
                                    </Col>
                                </Row>
                                <p className="title-small medium black">
                                    {this.props.t('download-the-app/content')}
                                </p>
                                <p
                                    className="black"
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.t(
                                            'download-the-app/mobileApps'
                                        ),
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs="12"
                                className="text-center authConfirm__apStoreLinks"
                            >
                                <Row className="d-none d-md-block mb-2">
                                    <Col md={{ size: 2, offset: 5 }}>
                                        <img
                                            src={qrCode}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Col>
                                </Row>

                                <a href={APP_STORE_URL} className="mr-2">
                                    <img src={appStore} alt="App store" />
                                </a>
                                <a href={GOOGLE_PLAY_URL} className="ml-2">
                                    <img src={googlePlay} alt="Google Play" />
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLocale(DownloadTheAppPage);
