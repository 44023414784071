import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled';
import InputDate from 'components/FormElements/InputDateControlled';
import InputNumber from 'components/FormElements/InputNumberControlled';
import InputNumberImperialHeight from 'components/FormElements/InputNumberImperialHeightControlled';
import { withLocale } from '@dietlabs/components';
import withStep from './StepContext/withStep';

const StepSexBirthdayHeight = ({
    sex,
    height,
    errors,
    dateOfBirth,
    handleInputChange,
    t,
    systemOfMeasures,
    validateData,
    changeNextBtnState,
}) => {
    const checkErrors = () => {
        // eslint-disable-next-line no-shadow
        let areErrors = false;

        if (errors) {
            errors.details.forEach(error => {
                if (
                    (error.fieldName === 'sex' && !sex) ||
                    error.fieldName === 'dateOfBirth' ||
                    error.fieldName === 'height.value'
                ) {
                    areErrors = true;
                }
            });
        }

        changeNextBtnState(!(sex && height.value && dateOfBirth && !areErrors));
    };

    useEffect(() => {
        checkErrors();
    });

    const optionalHeightProps = {};
    if (height.value) {
        optionalHeightProps.value = Number(height.value);
    }

    return (
        <div className="full-screen3">
            <div className="mb-auto w-100">
                <FormGroup tag="fieldset">
                    <Row form className="align-items-center">
                        <Col xs={4}>
                            <Label className="mb-0 large">
                                {t('diet-settings/sex/label')}
                            </Label>
                        </Col>
                        <Col xs={4}>
                            <InputRadio
                                label={
                                    <strong className="d-block text-center">
                                        {t('diet-settings/sex/female')}
                                    </strong>
                                }
                                name="sex"
                                value="female"
                                handleChange={handleInputChange}
                                checked={sex === 'female'}
                                data-test="sex-female"
                                small
                            />
                        </Col>
                        <Col xs={4}>
                            <InputRadio
                                label={
                                    <strong className="d-block text-center">
                                        {t('diet-settings/sex/male')}
                                    </strong>
                                }
                                name="sex"
                                value="male"
                                handleChange={handleInputChange}
                                checked={sex === 'male'}
                                data-test="sex-male"
                                small
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <br />
                <InputDate
                    label={t('diet-settings/birth-date')}
                    name="dateOfBirth"
                    value={dateOfBirth}
                    errors={errors}
                    handleChange={handleInputChange}
                    onBlur={() => validateData()}
                    data-test="dateOfBirth-input"
                    inline
                />
                <br />
                {systemOfMeasures === 'imperial' ? (
                    <InputNumberImperialHeight
                        label={t('diet-settings/height')}
                        labelSize="large"
                        name="height.value"
                        unit={height.unit}
                        errors={errors}
                        step={1}
                        handleChange={handleInputChange}
                        onBlur={() => validateData()}
                        {...optionalHeightProps}
                    />
                ) : (
                    <InputNumber
                        label={t('diet-settings/height')}
                        labelSize="large"
                        name="height.value"
                        unit={height.unit}
                        errors={errors}
                        step={1}
                        handleChange={handleInputChange}
                        onBlur={() => validateData()}
                        {...optionalHeightProps}
                        inline
                    />
                )}
            </div>
        </div>
    );
};

StepSexBirthdayHeight.propTypes = {
    t: PropTypes.func.isRequired,
    sex: PropTypes.string,
    systemOfMeasures: PropTypes.oneOf(['imperial', 'si']).isRequired,
    height: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string.isRequired,
    }).isRequired,
    dateOfBirth: PropTypes.string,
    handleInputChange: PropTypes.func.isRequired,
    changeNextBtnState: PropTypes.func.isRequired,
    errors: PropTypes.shape(),
    validateData: PropTypes.func.isRequired,
};

StepSexBirthdayHeight.defaultProps = {
    sex: undefined,
    dateOfBirth: undefined,
    errors: undefined,
};

export default withStep(withLocale(StepSexBirthdayHeight));
