/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { withRouter } from 'react-router';
import { Row, Col, FormGroup, Button } from 'reactstrap';
import Layout from 'Layout/Layout';
import Loader from 'Layout/Loader';
import InputCheckbox from 'components/FormElements/InputCheckboxControlled2';
import PageContainer from 'Layout/Container/PageContainer';
import RemoveAccountError from './Components/RemoveAccountError';
import TokenExpiredError from './Components/TokenExpiredError';
import SuccessView from './Components/SuccessView';

const RemoveAccountContainer = ({ t, loading, match, removeUserAccount }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tokenExpired, setIsTokenExpired] = useState(false);
    const [success, setSuccess] = useState(false);
    const token = match.params.token;

    const handleInputChange = () => {
        setIsChecked(!isChecked);
    };

    const handleConfirmRemoveAccount = async () => {
        try {
            const response = await removeUserAccount({ variables: { token } });
            if (
                response.data.removeAccount.confirm.__typename ===
                'ValidationException'
            ) {
                setIsError(true);
            } else if (
                response.data.removeAccount.confirm.__typename ===
                'UnauthorizedException'
            ) {
                setIsTokenExpired(true);
            } else {
                setSuccess(true);
            }
        } catch (err) {
            setIsError(true);
        }
    };

    const renderNextView = () => {
        if (isError) {
            return (
                <RemoveAccountError
                    handleConfirmRemoveAccount={handleConfirmRemoveAccount}
                />
            );
        }
        if (tokenExpired) {
            return <TokenExpiredError />;
        }
        return <SuccessView />;
    };

    return (
        <>
            <Layout page="remove-account">
                <Row className="mt-4">
                    <Col xs="12">
                        <h1 className="text-center black semi-bold title-medium">
                            {t('remove-account/title')}
                        </h1>
                    </Col>
                </Row>
                <PageContainer>
                    <Row className="mt-3">
                        <Col xs="12" sm={{ size: 6, offset: 3 }}>
                            {!isError && !tokenExpired && !success ? (
                                <>
                                    <p className="black semi-bold mb-3">
                                        {t('remove-account/info-title')}
                                    </p>
                                    <ul className="list grey-dots">
                                        <li>
                                            <p className="mb-0">
                                                {t('remove-account/info_1')}
                                            </p>
                                        </li>
                                        <li>
                                            <p className="mb-0">
                                                {t('remove-account/info_2')}
                                            </p>
                                        </li>
                                        <li>
                                            <p className="mb-0">
                                                {t('remove-account/info_3')}
                                            </p>
                                        </li>
                                    </ul>
                                    <FormGroup className="mt-4">
                                        <InputCheckbox
                                            id="acceptConditions"
                                            name="condition"
                                            label={[
                                                <p>
                                                    {t(
                                                        'remove-account/confirmation'
                                                    )}
                                                </p>,
                                            ]}
                                            handleChange={() =>
                                                handleInputChange()
                                            }
                                            checked={isChecked}
                                            value={isChecked}
                                            square
                                        />
                                        <div className="text-center">
                                            <Button
                                                color="primary"
                                                disabled={!isChecked}
                                                className="w-100"
                                                onClick={
                                                    handleConfirmRemoveAccount
                                                }
                                            >
                                                {t('button/remove-account')}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </>
                            ) : (
                                renderNextView()
                            )}
                        </Col>
                    </Row>
                </PageContainer>
            </Layout>
            {loading ? <Loader fixed="no" /> : ''}
        </>
    );
};

RemoveAccountContainer.propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string.isRequired,
        }),
    }).isRequired,
    removeUserAccount: PropTypes.func.isRequired,
};

export default withRouter(withLocale(RemoveAccountContainer));
