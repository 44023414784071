import {
    ApolloClient,
    HttpLink,
    ApolloLink,
    InMemoryCache,
    concat,
    from,
} from '@apollo/client';
import { reportError } from '@dietlabs/utils';
import { onError } from 'apollo-link-error';
import { APP_VERSION } from 'consts';
import config from 'config/config';

const httpLink = new HttpLink({
    uri: config.apiGateway.url,
    credentials: 'same-origin',
    headers: {
        'X-AppEnvironment': process.env.REACT_APP_ENV,
        'X-AppVersion': `ReactWebApp/${APP_VERSION}`,
    },
});

// eslint-disable-next-line import/no-mutable-exports
export let networkErrorVar;

const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
            reportError(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        );
    }
    if (networkError) {
        const newError = new Error('Network error', 'Failed to fetch');
        newError.networkError = networkError;
        networkErrorVar = newError;
    }
});

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers and set dynamic api uri
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            'X-Authentication':
                headers['X-Authentication'] ||
                localStorage.getItem('token') ||
                null,
        },
        uri:
            localStorage.getItem('developerOptionsApiUri') ||
            config.apiGateway.url,
    }));

    return forward(operation);
});

const appLink = from([errorLink, concat(authMiddleware, httpLink)]);

const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            UserShoppingListCategoryProduct: {
                keyFields: ['key'],
            },
            UserHydrationDay: {
                keyFields: ['key'],
            },
            UserDiet: {
                keyFields: ['id'],
            },
            UserDailyDietPlanType: {
                keyFields: ['id'],
            },
            UserDietSet: {
                keyFields: ['dietSetId'],
            },
            UserDailyDietPlanEventMeal: {
                keyFields: ['key'],
            },
            UserDailyDietMeal: {
                keyFields: ['key'],
            },
            UserActivity: {
                keyFields: ['id'],
            },
            UserDailyDietMealDishIngredient: {
                keyFields: ['key'],
            },
            UserMeasurement: {
                keyFields: [],
            },
        },
    }),
    link: appLink,
});

export default client;
