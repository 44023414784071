import { MENU } from 'consts';
import BuyIndexPage from './Index/BuyIndexPage';
import BuySignUpPage from './SignUp/BuySignUpPage';
import BuyLogInPage from './LogIn/BuyLogInPage';
import BuyAddPage from './AddProduct/BuyAddPage';
import BuyThankYouPage from './ThankYou/BuyThankYouPage';
import DownloadTheAppPage from './DownloadTheApp/DownloadTheAppPage';
import {
    PATH_BUY,
    PATH_BUY_SIGNUP,
    PATH_BUY_LOGIN,
    PATH_BUY_ADD,
    PATH_BUY_THANK_YOU,
    PATH_DOWNLOAD_THE_APP,
} from './paths';

const routes = [
    {
        path: PATH_BUY,
        component: BuyIndexPage,
        private: true,
        exact: true,
        title: 'route-title/buy',
        menuType: MENU.SIMPLE,
    },
    {
        path: PATH_BUY_SIGNUP,
        component: BuySignUpPage,
        private: false,
        exact: true,
        title: 'route-title/buy',
        menuType: MENU.SIMPLE,
    },
    {
        path: PATH_BUY_LOGIN,
        component: BuyLogInPage,
        private: false,
        exact: true,
        title: 'route-title/buy',
        menuType: MENU.SIMPLE,
    },
    {
        path: PATH_BUY_ADD,
        component: BuyAddPage,
        private: false,
        exact: true,
        title: 'route-title/buy',
    },
    {
        path: PATH_BUY_THANK_YOU,
        component: BuyThankYouPage,
        private: false,
        exact: true,
        title: 'route-title/buy',
        menuType: MENU.SIMPLE,
    },
    {
        path: PATH_DOWNLOAD_THE_APP,
        component: DownloadTheAppPage,
        private: false,
        exact: true,
        title: 'route-title/download-the-app',
        menuType: MENU.SIMPLE,
    },
];

export { routes };
