import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import Layout from 'Layout/Layout';
import ExternalProviderContainer from '@dietlabs/components/src/Auth/Login/ByExternalProvider/ExternalProviderContainer';
import { RETURN_URL_LOGIN } from '@dietlabs/components/src/Auth/Login/ByExternalProvider/LoginReturnUrls';
import AuthLoginByEmailAndPasswordForm from '@dietlabs/components/src/Auth/Login/ByEmailAndPassword/AuthLoginByEmailAndPasswordForm';
import { withLocale } from '@dietlabs/components';
import { PATH_BUY_SIGNUP, PATH_BUY } from '../paths';

const BuyLogInContainer = ({ t, login, trackFirebaseEvent }) => {
    useEffect(() => {
        trackFirebaseEvent('screen_view', {
            firebase_screen: 'stripe_login',
        });
    }, []);

    return (
        <Layout page="buy">
            <Container>
                <Row>
                    <Col md={{ size: 4, offset: 4 }}>
                        <h1 className="text-center my-4 heading-3 semi-bold">
                            {t('buy/log-in')}
                        </h1>
                        <ExternalProviderContainer
                            returnUrl={RETURN_URL_LOGIN}
                            state="stripe_login"
                            roundedBtn
                            fullWidth
                        />
                        <p className="with-line text-center stroke-dark my-4">
                            <span>{t('buy/continue-with-e-mail')}</span>
                        </p>

                        <AuthLoginByEmailAndPasswordForm
                            login={login}
                            userDataOnDemand={() => {}}
                            redirectToPathAfterSuccess={PATH_BUY}
                            comesFromStripe
                        />
                        <hr />
                        <p className="small mt-3 mb-5 text-center">
                            {t('buy/back-to')}{' '}
                            <Link to={PATH_BUY_SIGNUP}>{t('buy/sign-up')}</Link>
                        </p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

BuyLogInContainer.propTypes = {
    t: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    trackFirebaseEvent: PropTypes.func.isRequired,
};

export default withFirebase(withLocale(BuyLogInContainer));
