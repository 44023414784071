import React from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import InputPassword from 'components/FormElements/InputPasswordControlled';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import { validateField } from 'view/Validation/validateField';
import ValidationErrors from 'view/Validation/ValidationErrors';
import Loader from '../../Layout/Loader';

class ChangePasswordForm extends React.Component {
    state = {
        currentPassword: '',
        newPassword: '',
        preload: false,
        success: false,
        errors: new ValidationErrors(),
        changePasswordError: false,
    };

    static propTypes = {
        t: PropTypes.func.isRequired,
        className: PropTypes.string,
        changePassword: PropTypes.func.isRequired,
        username: PropTypes.string.isRequired,
    };

    static defaultProps = {
        className: null,
    };

    validationRules = {
        currentPassword: [new RequiredRule({ translator: this.props.t })],
        newPassword: [new RequiredRule({ translator: this.props.t })],
    };

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        this.validateInput(event.target.name, event.target.value);
    };

    validateInput = (field, value) => {
        if (this.validationRules[field]) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ preload: true });

        this.props
            .changePassword(this.state.currentPassword, this.state.newPassword)
            .then(response => {
                if (response.data.me.changePassword.code === 200) {
                    this.setState({
                        currentPassword: '',
                        newPassword: '',
                        preload: false,
                        success: true,
                        errors: new ValidationErrors(),
                        changePasswordError: false,
                    });
                } else {
                    this.setState({
                        success: false,
                        errors: response.data.me.changePassword,
                        preload: false,
                        changePasswordError: true,
                    });
                }
            });
    }

    render() {
        return (
            <Container className={this.props.className}>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <div className="white-box">
                            <Form
                                onSubmit={event => this.handleSubmit(event)}
                                data-test="change-password-form"
                            >
                                <h2 className="title-small semi-bold">
                                    {this.props.t('settings/change-password')}
                                </h2>
                                {this.state.changePasswordError ? (
                                    <Alert
                                        color="danger"
                                        data-test="change-password-error"
                                    >
                                        {this.props.t('error/message/generic')}
                                    </Alert>
                                ) : (
                                    ''
                                )}

                                {this.state.success ? (
                                    <Alert
                                        color="success"
                                        data-test="change-password-success"
                                    >
                                        {this.props.t(
                                            'settings/change-password/success'
                                        )}
                                        <br />
                                    </Alert>
                                ) : (
                                    ''
                                )}

                                <input
                                    name="email"
                                    autoComplete="username"
                                    value={this.props.username}
                                    hidden
                                    readOnly
                                />

                                <InputPassword
                                    label={this.props.t(
                                        'settings/change-password/current-password'
                                    )}
                                    name="currentPassword"
                                    autoComplete="current-password"
                                    value={this.state.currentPassword}
                                    errors={this.state.errors}
                                    handleChange={this.handleInputChange}
                                    validationRules={
                                        this.validationRules.password
                                    }
                                    data-test="password-input"
                                />
                                <InputPassword
                                    label={this.props.t(
                                        'settings/change-password/new-password'
                                    )}
                                    name="newPassword"
                                    autoComplete="new-password"
                                    value={this.state.newPassword}
                                    errors={this.state.errors}
                                    handleChange={this.handleInputChange}
                                    validationRules={
                                        this.validationRules.password
                                    }
                                    data-test="new-password-input"
                                />

                                <div className="text-center">
                                    <Button
                                        color="primary"
                                        className="w-100 mb-0"
                                        data-test="save-button"
                                    >
                                        {this.props.t(
                                            'settings/change-password'
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
                {this.state.preload ? <Loader /> : ''}
            </Container>
        );
    }
}

export default withLocale(ChangePasswordForm);
