import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    FormFeedback,
} from 'reactstrap';
import PropTypes from 'prop-types';
import useWindowsSize from 'utils/useWindowsSize';
import { InputErrorType } from 'types/CommonTypes';
import withRequired from './withRequired';

const InputNumberImperialHeight = ({
    value,
    errors,
    step,
    onBlur,
    required,
    disabled,
    name,
    label,
    labelSize,
    hide,
    handleChange,
}) => {
    const { width } = useWindowsSize();

    // eslint-disable-next-line no-shadow
    const convertInchesToFeetsAndInches = value => {
        const feets = Math.floor(value / 12);
        const inches = Math.floor(value - feets * 12);

        return { feets, inches };
    };
    const [feetsAndInches, setFeetsAndInches] = useState({
        feets: convertInchesToFeetsAndInches(value).feets,
        inches: convertInchesToFeetsAndInches(value).inches,
    });

    useEffect(() => {
        const myEvent = {
            target: {
                name: 'height',
                value: feetsAndInches.feets * 12 + feetsAndInches.inches,
            },
        };

        handleChange(myEvent);
    }, [feetsAndInches]);

    const handleChanges = event => {
        const fieldName = event.target.name;
        const valInt = parseInt(event.target.value, 10);

        setFeetsAndInches(prevState => ({
            ...prevState,
            [fieldName]: valInt,
        }));
    };

    let errorMgs;

    if (errors) {
        errors.details.forEach(error => {
            if (error.fieldName === name) {
                errorMgs = error.messages;
            }
        });
    }

    const formGroupClasses = [];
    if (hide) {
        formGroupClasses.push('d-none');
    } else {
        formGroupClasses.push('d-block');
    }
    if (errorMgs) {
        formGroupClasses.push('is-invalid');
    }

    return (
        <FormGroup className={formGroupClasses.join(' ')}>
            <Row form className="align-items-center">
                {label ? (
                    <Col xs={width > 360 ? 6 : 4}>
                        <Label for={name} className={`mb-0 ${labelSize || ''}`}>
                            {required ? <sup>*</sup> : ''} {label}
                        </Label>
                    </Col>
                ) : (
                    ''
                )}
                <Col xs={width > 360 ? { size: 3, offset: 0 } : { size: 4 }}>
                    <InputGroup
                        className={
                            errorMgs
                                ? 'form-control is-invalid'
                                : 'form-control'
                        }
                    >
                        <Input
                            type="number"
                            step={step}
                            name="feets"
                            value={feetsAndInches.feets}
                            onChange={handleChanges}
                            onBlur={onBlur}
                            required={required}
                            disabled={!!disabled}
                        />
                        <InputGroupAddon addonType="append">ft</InputGroupAddon>
                    </InputGroup>
                </Col>
                <Col xs={width > 360 ? { size: 3 } : { size: 4 }}>
                    <InputGroup
                        className={
                            errorMgs
                                ? 'form-control is-invalid'
                                : 'form-control'
                        }
                    >
                        <Input
                            type="number"
                            step={step}
                            name="inches"
                            min={0}
                            max={11}
                            value={feetsAndInches.inches}
                            onChange={handleChanges}
                            onBlur={onBlur}
                            required={required}
                            disabled={!!disabled}
                        />
                        <InputGroupAddon addonType="append">in</InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
            <Row form>
                <Col xs={{ size: 8, offset: 4 }}>
                    <FormFeedback>
                        {errorMgs
                            ? errorMgs.map((message, k) => (
                                  <li key={k}>{message}</li>
                              ))
                            : ''}
                    </FormFeedback>
                </Col>
            </Row>
        </FormGroup>
    );
};

InputNumberImperialHeight.propTypes = {
    errors: InputErrorType,
    label: PropTypes.node,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    step: PropTypes.number,
    validationErrors: PropTypes.object, //eslint-disable-line
    onBlur: PropTypes.func,
};

InputNumberImperialHeight.defaultProps = {
    errors: undefined,
    required: false,
    disabled: false,
    value: undefined,
    step: 0.01,
    validationErrors: undefined,
    label: null,
    onBlur: undefined,
};

export default withRequired(InputNumberImperialHeight);
