import React from 'react';
import CreateSubscriptionConnectorApollo from '../Connector/Apollo/CreateSubscription/CreateSubscriptionConnectorApollo';
import BuyIndexContainer from './BuyIndexContainer';

const BuyIndexPage = () => {
    return (
        <CreateSubscriptionConnectorApollo>
            {({ buy }) => <BuyIndexContainer {...{ buy }} />}
        </CreateSubscriptionConnectorApollo>
    );
};

export default BuyIndexPage;
