/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import MeasurementUnits from 'components/Helpers/MeasurementUnits';
import iconChangeActive from 'assets/icons/arrow_icon_off.svg';
import { withLocale } from '@dietlabs/components';
import withGTM from 'view/GoogleTagManager/withGTM';
import Loader from 'Layout/Loader';
import PropTypes from 'prop-types';

class DayPlanIngredientReplacement extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        measurements: PropTypes.arrayOf(PropTypes.object).isRequired,
        replacementId: PropTypes.number.isRequired,
        productAmountId: PropTypes.number.isRequired,
        productKey: PropTypes.string.isRequired,
        replaceProduct: PropTypes.shape().isRequired,
        t: PropTypes.func.isRequired,
        replaceProductSuccess: PropTypes.func.isRequired,
    };

    state = {
        preloadInside: false,
    };

    changeIngredient = event => {
        if (event) {
            event.stopPropagation();
        }
        this.setState({ preloadInside: true }, async () => {
            const replacement = {
                productAmountId: this.props.productAmountId,
                replacementId: this.props.replacementId,
                key: this.props.productKey,
                name: this.props.name,
                measurements: this.props.measurements,
            };
            await this.props.replaceProduct.replace(replacement);
            this.setState({ preloadInside: false });
            this.props.replaceProductSuccess();
        });
    };

    render() {
        const { name, measurements } = this.props;

        return (
            <React.Fragment>
                <li
                    onClick={event => this.changeIngredient(event)}
                    data-test="replace-product"
                >
                    <MeasurementUnits measurements={measurements} />
                    <span className="stay-together">
                        <button type="button" className="btn-change">
                            <img
                                className="img-fluid"
                                src={iconChangeActive}
                                alt={this.props.t('meal/exchange-ingredient')}
                            />
                        </button>
                    </span>
                    {name}
                </li>
                {this.state.preloadInside ? <Loader fixed="no" /> : ''}
            </React.Fragment>
        );
    }
}

export { DayPlanIngredientReplacement };
export default withGTM(withLocale(DayPlanIngredientReplacement));
