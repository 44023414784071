import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Loader from 'Layout/Loader';
import MessageWithButton from 'Layout/Messages/MessageWithButton';
import withStep from './StepContext/withStep';

class StepFinish extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        generateDiet: PropTypes.func.isRequired,
        generateDietError: PropTypes.bool.isRequired,
        prevStep: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.generateDiet();
    }

    render() {
        const { t, generateDietError, prevStep } = this.props;

        return (
            <div className="full-screen2">
                <div className="text-center my-auto w-100">
                    {generateDietError ? (
                        <MessageWithButton
                            color="danger"
                            renderButton={() => (
                                <Button color="primary" onClick={prevStep()}>
                                    {this.props.t('Try again')}
                                </Button>
                            )}
                        >
                            <h2 className="alert-heading">
                                {this.props.t('Something went wrong')}
                            </h2>
                            <p>
                                {this.props.t(
                                    'Incident has been reported and we will work to solve it'
                                )}
                            </p>
                        </MessageWithButton>
                    ) : (
                        <React.Fragment>
                            <h1 className="title-medium semi-bold">
                                {t('diet-settings/finish')}
                            </h1>
                            <p>{t('diet-settings/please-wait')}</p>
                            <Loader isWrapper={false} />
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepFinish));
