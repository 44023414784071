import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { ReactComponent as SuccessIcon } from 'assets/icons/removeAccount/success.svg';
import removeAuthCredentials from '@dietlabs/components/src/Auth/removeAuthCredentials';
import { PATHS } from '../../../config/paths';
import callNativeMobileApp from '../../../components/Helpers/callNativeMobileApp';

const SuccessView = ({ t }) => {
    useEffect(() => {
        const id = setTimeout(() => {
            removeAuthCredentials();
            callNativeMobileApp({ action: 'forced_logout' });
            window.location = PATHS.AUTH.LOGIN;
        }, 10000);
        return () => clearTimeout(id);
    }, []);
    return (
        <div className="text-center">
            <SuccessIcon />
            <p className="black semi-bold mt-3">
                {t('remove-account/success')}
            </p>
            <p>{t('remove-account/success-info')}</p>
        </div>
    );
};

SuccessView.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withLocale(SuccessView);
