/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import UserDataQuery from '@dietlabs/components/src/UserData/Connector/Apollo/Fetch/UserDataQuery';

const Survicate = ({ client, location, mobileAppMode, mobileApp }) => {
    const SURVEY_ID = '4b16b1971714d427';
    const [activeSurvey, setActiveSurvey] = useState(false);

    useEffect(() => {
        if (mobileAppMode && mobileApp.os.startsWith('android')) {
            if (localStorage.getItem('sex') && !activeSurvey && window._sva) {
                const userData = client.readQuery({
                    query: UserDataQuery,
                });
                const created = new Date(userData.me.created);
                const date = new Date();
                date.setDate(date.getDate() - 5);

                if (created.getTime() < date.getTime()) {
                    setTimeout(() => {
                        const options = {
                            forceDisplay: true,
                            displayMethod: 'immediately',
                        };

                        window._sva.showSurvey(SURVEY_ID, options);
                    }, 5000);
                }
                setActiveSurvey(true);
            }
        }

        return () => {};
    }, [location]);

    return null;
};

Survicate.propTypes = {
    mobileAppMode: PropTypes.bool.isRequired,
    mobileApp: PropTypes.shape({
        os: PropTypes.string,
    }),
    client: PropTypes.shape({
        readQuery: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default withRouter(withApollo(withMobileAppMode(Survicate)));
