import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import ReportPaymentMutation from './ReportPaymentMutation';

class ReportPaymentConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    createReportPayment = mutate => async ({ token }) => {
        const variables = {
            token,
        };
        const response = await mutate({ variables });

        return response;
    };

    render() {
        const { children } = this.props;

        return (
            <Mutation mutation={ReportPaymentMutation}>
                {mutate =>
                    children({
                        reportPayment: this.createReportPayment(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default ReportPaymentConnectorApollo;
