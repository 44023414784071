import { PATHS } from '../paths';
import { PATH_KNOWLEDGE } from '../../Knowledge/paths';

export default [
    {
        path: PATHS.EXTERNAL.HOME,
        externalUrl: PATHS.EXTERNAL.HOME,
        translationKey: 'menu/home',
    },
    {
        path: PATH_KNOWLEDGE,
        translationKey: 'menu/coach',
    },
    {
        path: PATHS.CONTACT,
        translationKey: 'menu/contact',
    },
    {
        path: PATHS.AUTH.LOGIN,
        translationKey: 'menu/login',
    },
];
