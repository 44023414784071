import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { withLocale } from '@dietlabs/components';
import { Container } from 'reactstrap';
import HpbaMarkdown from 'Content/utils/HpbaMarkdown';
import ContactPlaceholder from './ContactPlaceholder';
import ContactForm from './ContactForm';

class ContactContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        content: PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
        }).isRequired,
        userData: PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
        }),
        contactEmail: PropTypes.shape({
            sendEmail: PropTypes.func,
            response: PropTypes.shape({
                details: PropTypes.string,
                code: PropTypes.number,
            }),
            loading: PropTypes.bool,
            error: PropTypes.shape(),
        }).isRequired,
    };

    static defaultProps = {
        userData: undefined,
    };

    render() {
        return (
            <Layout page="cms-article">
                <div className="bg-white">
                    <Container className="content">
                        <header className="d-none d-md-block">
                            <h1 className="title-large text-center bold d-none d-md-block mb-4">
                                {this.props.t('contact/title')}
                            </h1>
                        </header>
                        <h2 className="my-5 text-center title-large semi-bold">
                            {this.props.t('contact/faq')}
                        </h2>
                        <div className="cms-content contact">
                            <h2 className="primary">
                                {this.props.content.title}
                            </h2>

                            <HpbaMarkdown
                                content={this.props.content.content.markdown}
                            />
                        </div>
                        <ContactForm
                            name={
                                this.props.userData
                                    ? this.props.userData.name
                                    : ''
                            }
                            email={
                                this.props.userData
                                    ? this.props.userData.email
                                    : ''
                            }
                            contactEmail={this.props.contactEmail}
                        />
                    </Container>
                </div>
            </Layout>
        );
    }
}

export default withLoading(withLocale(ContactContainer), ContactPlaceholder);
