import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Alert } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { PATHS } from 'config/paths';
import { PATH_DAY_PLAN_INDEX } from 'DailyDietPlan/DayPlanPaths';

export class ShoppingCartThankYouStartUsingComponent extends React.Component {
    render() {
        const {
            userHasDiet,
            userEmail,
            isUserActive,
            isDietInPayment,
        } = this.props;
        return (
            <section className="bg-white">
                <Container className="text-center">
                    <Row>
                        {isDietInPayment ? (
                            <Col data-test="thank-you/diet">
                                {userHasDiet ? (
                                    <React.Fragment>
                                        <h2 data-test="thank-you/has-diet">
                                            {this.props.t(
                                                'thank-you/go-to-diet-plan'
                                            )}
                                        </h2>
                                        <p>
                                            {this.props.t(
                                                'thank-you/go-to-diet-plan/content'
                                            )}
                                        </p>
                                        <Button
                                            color="primary"
                                            tag={Link}
                                            to={PATH_DAY_PLAN_INDEX}
                                        >
                                            {this.props.t(
                                                'thank-you/go-to-diet-plan'
                                            )}
                                        </Button>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <h2 data-test="thank-you/has-not-diet">
                                            {this.props.t(
                                                'thank-you/start-configuring-your-diet'
                                            )}
                                        </h2>
                                        <p>
                                            {this.props.t(
                                                'thank-you/start-configuring-your-diet/content'
                                            )}
                                        </p>
                                        <Button
                                            color="primary"
                                            tag={Link}
                                            to={PATHS.DIET_SETTINGS}
                                        >
                                            {this.props.t(
                                                'thank-you/start-configuring-your-diet/button'
                                            )}
                                        </Button>
                                    </React.Fragment>
                                )}
                            </Col>
                        ) : (
                            ''
                        )}
                    </Row>

                    {!isUserActive ? (
                        <Alert
                            color="danger"
                            className="mt-5"
                            data-test="thank-you/alert"
                        >
                            {this.props.t('thank-you/warning', {
                                email: userEmail,
                            })}
                        </Alert>
                    ) : (
                        ''
                    )}
                </Container>
            </section>
        );
    }
}

ShoppingCartThankYouStartUsingComponent.propTypes = {
    t: PropTypes.func.isRequired,
    userHasDiet: PropTypes.bool.isRequired,
    userEmail: PropTypes.string.isRequired,
    isUserActive: PropTypes.bool.isRequired,
    isDietInPayment: PropTypes.bool.isRequired,
};

export default withLocale(ShoppingCartThankYouStartUsingComponent);
