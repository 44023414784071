import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';

import ShoppingListConnectorApollo from '@dietlabs/components/src/ApolloConnectors/ShoppingList/ShoppingListConnectorApollo';
import ShoppingListMarkProductConnectorApollo from '@dietlabs/components/src/ApolloConnectors/ShoppingList/ShoppingListMarkProductConnectorApollo ';
import ShoppingListIndexContainer from 'ShoppingList/Index/ShoppingListIndexContainer';

import Layout from 'Layout/Layout';

class ShoppingListPage extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    state = {
        period: undefined,
    };

    handlePeriodChange = period => this.setState({ period });

    render() {
        return (
            <Layout page="shopping-list">
                <ShoppingListConnectorApollo period={this.state.period}>
                    {shoppingListData => (
                        <ShoppingListMarkProductConnectorApollo
                            {...shoppingListData.shoppingList}
                        >
                            {({ handleMarkProduct }) => (
                                <ShoppingListIndexContainer
                                    {...{
                                        handleMarkProduct,
                                    }}
                                    {...shoppingListData}
                                    handlePeriodChange={this.handlePeriodChange}
                                />
                            )}
                        </ShoppingListMarkProductConnectorApollo>
                    )}
                </ShoppingListConnectorApollo>
            </Layout>
        );
    }
}

export { ShoppingListPage };
export default withLocale(ShoppingListPage);
