import React from 'react';
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    InputGroup,
    FormFeedback,
} from 'reactstrap';
import PropTypes from 'prop-types';
import useWindowsSize from 'utils/useWindowsSize';
import { DateType, InputErrorType } from 'types/CommonTypes';
import { formatIsoDate } from 'utils/dateFormatter';
import withRequired from './withRequired';

const InputDate = ({
    name,
    value,
    min,
    max,
    handleChange,
    onBlur,
    required,
    errors,
    inline,
    label,
}) => {
    const { width } = useWindowsSize();

    const renderInput = errorMgs => (
        <React.Fragment>
            <InputGroup
                className={
                    errorMgs ? 'form-control is-invalid' : 'form-control'
                }
            >
                <Input
                    type="date"
                    name={name}
                    value={value}
                    min={min}
                    max={max}
                    onChange={handleChange}
                    onBlur={onBlur}
                    required={required}
                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    placeholder={formatIsoDate(new Date())}
                />
            </InputGroup>

            <FormFeedback>
                {errorMgs
                    ? errorMgs.map((message, k) => <li key={k}>{message}</li>)
                    : ''}
            </FormFeedback>
        </React.Fragment>
    );

    let errorMgs;

    if (errors) {
        errors.details.forEach(error => {
            if (error.fieldName === name) {
                errorMgs = error.messages;
            }
        });
    }

    return (
        <FormGroup className={errorMgs && 'is-invalid'}>
            <Row form className="align-items-center">
                {label ? (
                    <Label
                        for={name}
                        className={
                            // eslint-disable-next-line no-nested-ternary
                            inline
                                ? width > 360
                                    ? 'col-6 mb-0 large'
                                    : 'col-4 mb-0 large'
                                : ''
                        }
                    >
                        {required ? <sup>*</sup> : ''} {label}
                    </Label>
                ) : (
                    ''
                )}

                {inline ? (
                    <Col xs={width > 360 ? 6 : 8}>{renderInput(errorMgs)}</Col>
                ) : (
                    renderInput(errorMgs)
                )}
            </Row>
        </FormGroup>
    );
};

InputDate.propTypes = {
    errors: InputErrorType,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    value: DateType,
    min: DateType,
    max: DateType,
    validationErrors: PropTypes.object, //eslint-disable-line
    onBlur: PropTypes.func,
    inline: PropTypes.bool,
};

InputDate.defaultProps = {
    errors: undefined,
    label: null,
    required: false,
    value: undefined,
    min: undefined,
    max: undefined,
    onBlur: undefined,
    inline: false,
};

export default withRequired(InputDate);
