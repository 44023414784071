import React, { Component } from 'react';
import { Container, Row, Col, FormGroup, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import headerBg from 'assets/images/shopping-list/img_groceries.png';

class ShoppingListPlaceholder extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="header-bg">
                    <img src={headerBg} className="img-fluid" alt="" />
                </div>
                <section className="page">
                    <Container>
                        <Row>
                            <Col xs="12" sm={{ size: 8, offset: 2 }}>
                                <h3 className="title-large semi-bold text-center">
                                    {t('shopping-list/date-range')}
                                </h3>
                                <Row>
                                    <Col xs="6" className="pr-2">
                                        <FormGroup>
                                            <div className="form-control">
                                                <div className="input-placeholder placeholder">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6" className="pl-2">
                                        <FormGroup>
                                            <div className="form-control">
                                                <div className="input-placeholder placeholder">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        md={{ size: 6, offset: 3 }}
                                        className="text-center"
                                    >
                                        <Button
                                            size="sm"
                                            color="placeholder"
                                            className="w-100"
                                        >
                                            {t(
                                                'shopping-list/show-list-button'
                                            )}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col xs="12" sm="6">
                                <ul className="ingredients">
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                </ul>
                            </Col>
                            <Col xs="12" sm="6">
                                <ul className="ingredients">
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default withLocale(ShoppingListPlaceholder);
