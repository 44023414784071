import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withLocale } from '@dietlabs/components';
import Select from 'components/FormElements/InputSelectControlled';

class HistoryChartPeriodSelect extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        value: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    periods = new Map([
        [7, this.props.t('week', { count: 1 })],
        [14, this.props.t('week', { count: 2 })],
        [31, this.props.t('month', { count: 1 })],
        [92, this.props.t('month', { count: 3 })],
        [183, this.props.t('month', { count: 6 })],
    ]);

    render() {
        return (
            <Select
                handleChange={this.props.onChange}
                name="history-length"
                value={this.props.value}
                blue
            >
                {Array.from(this.periods.entries()).map(([value, label]) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </Select>
        );
    }
}

export { HistoryChartPeriodSelect };
export default withLocale(HistoryChartPeriodSelect);
