import gql from 'graphql-tag';

export default gql`
    mutation InsertFavoriteToDayplan(
        $date: Date!
        $replacementId: Int!
        $skipValidation: Boolean
    ) {
        me {
            insertFavoriteToDayplan(
                date: $date
                replacementId: $replacementId
                skipValidation: $skipValidation
            ) {
                __typename
                ... on InsertToDayPlanValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                    skipable
                    title
                    description
                }
                ... on BasicMutationSuccessWithId {
                    id
                }
            }
        }
    }
`;
