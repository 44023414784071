import React from 'react';
import PropTypes from 'prop-types';
import { Container, Button, Modal, ModalBody } from 'reactstrap';
import { withLocale } from '@dietlabs/components';

export class ShoppingCartUpSellProductDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
        }));
    }

    addAndToggle(id) {
        this.props.add(id);
        this.toggle();
    }

    render() {
        const product = this.props.product;

        return (
            <React.Fragment>
                <Button
                    color="link"
                    data-test="about-product-button"
                    onClick={this.toggle}
                >
                    {this.props.t('cart/upsell/about')}
                </Button>
                <Modal
                    isOpen={this.state.showModal}
                    toggle={this.toggle}
                    centered
                    size="lg"
                    key={product.id}
                >
                    <ModalBody>
                        <Container fluid>
                            <div className="product-details">
                                <span
                                    className="product-name"
                                    data-test="product-name"
                                    dangerouslySetInnerHTML={{
                                        __html: product.name,
                                    }}
                                ></span>
                                {product.price.promo.amount !==
                                product.price.regular.amount ? (
                                    <React.Fragment>
                                        <span
                                            data-test="product-old-price"
                                            className="product-old-price"
                                        >
                                            {`${product.price.regular}`}
                                        </span>
                                        <span className="product-save">
                                            {this.props.t(
                                                'cart/upsell/you-save'
                                            )}{' '}
                                            {`${product.diffPrice}`}
                                        </span>
                                        <span
                                            data-test="product-price"
                                            className="product-price"
                                        >
                                            {`${product.price.promo}`}
                                        </span>
                                    </React.Fragment>
                                ) : (
                                    <span
                                        data-test="product-price"
                                        className="product-price"
                                    >
                                        {`${product.price.regular}`}
                                    </span>
                                )}

                                {this.props.productIsInCart(
                                    product.id,
                                    this.props.cartProducts
                                ) ? (
                                    <Button data-test="product-added" disabled>
                                        {this.props.t('cart/upsell/added')}
                                    </Button>
                                ) : (
                                    <Button
                                        color="green"
                                        data-test="add-product-button"
                                        onClick={() =>
                                            this.addAndToggle(product.id)
                                        }
                                    >
                                        {this.props.t(
                                            'cart/upsell/add-product'
                                        )}
                                    </Button>
                                )}
                            </div>
                        </Container>
                        <button
                            type="button"
                            className="close"
                            onClick={() => this.toggle()}
                            data-test="close-button"
                        >
                            {this.props.t('close')} &times;
                        </button>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

ShoppingCartUpSellProductDetails.propTypes = {
    t: PropTypes.func.isRequired,
    add: PropTypes.func.isRequired,
    productIsInCart: PropTypes.func.isRequired,
};

export default withLocale(ShoppingCartUpSellProductDetails);
