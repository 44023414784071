import { useQuery, useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { createDateObject, formatIsoDate, addDays } from 'utils/dateFormatter';
import * as types from 'DailyDietPlan/DayPlanTypes';
import clearDailyDietPlanCacheObjects from 'Apollo/clearDailyDietPlanCacheObjects';
import DailyDietPlanQuery from './DailyDietPlanQuery';

const DailyPlanConnectorApollo = ({ children, date }) => {
    const client = useApolloClient();

    const { loading, error, data, refetch } = useQuery(DailyDietPlanQuery, {
        variables: {
            date: formatIsoDate(date),
            canBeCopiedToDate: formatIsoDate(addDays(date, 1)),
        },
    });

    const result = {
        loading,
        error,
        refetch,
    };

    if (!loading) {
        if (data.me?.dailyDietPlan) {
            if (data.me.dailyDietPlan.__typename !== types.DAY_PLAN) {
                clearDailyDietPlanCacheObjects(client);
            }

            result.dailyDietPlan = { ...data.me.dailyDietPlan };
            result.dietId =
                data.me.dailyDietPlan.__typename === types.DAY_PLAN
                    ? data.me.dailyDietPlan.dayDiet.dietId
                    : undefined;

            if (result.dailyDietPlan.date) {
                result.dailyDietPlan.date = createDateObject(
                    result.dailyDietPlan.date
                );
            }

            result.dietAvailabilityDate =
                data.me.dailyDietPlan.__typename === types.DIET_IN_FUTURE &&
                data.me.dailyDietPlan.date
                    ? createDateObject(data.me.dailyDietPlan.date)
                    : undefined;
        }

        result.categories = data.me.diet.set.categories;
        result.isHolidayMenu = Boolean(data.me.diet.set.holiday);
        result.alreadyCopied =
            data.me.diet.nextDaySet.dietSetId === data.me.diet.set.dietSetId;
    }

    return children(result);
};

DailyPlanConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
};

export default DailyPlanConnectorApollo;
