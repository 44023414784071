import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

class MessageWithButton extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        renderButton: PropTypes.func.isRequired,
        color: PropTypes.oneOf(['success', 'danger', 'info']),
    };

    static defaultProps = {
        color: 'info',
    };

    render() {
        const { children, renderButton, color } = this.props;
        return (
            <Alert color={color} className="mb-4">
                {children}
                {renderButton()}
            </Alert>
        );
    }
}

export default MessageWithButton;
