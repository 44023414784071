import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { callNativeApp } from 'components/Helpers/callNativeApp';
import { Link } from 'react-router-dom';
import { PATH_DAY_PLAN_INDEX } from 'DailyDietPlan/DayPlanPaths';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import withMobileAppMode from 'Layout/withMobileAppMode';
import icon from 'assets/images/groceries_img.png';

class NoDiet extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
    };

    static handleClick(event) {
        if (this.props.mobileAppMode) {
            event.preventDefault();
            callNativeApp(PATH_DAY_PLAN_INDEX, 'diet');
        }
    }

    render() {
        return (
            <section className="mt-5 pt-5">
                <Container>
                    <Row>
                        <Col
                            xs="12"
                            md={{ size: 6, offset: 3 }}
                            className="text-center"
                        >
                            <img
                                className="img-fluid mb-4"
                                src={icon}
                                role="presentation"
                                alt=""
                            />
                            <p>
                                {this.props.t('redirect/shopping-list/message')}
                            </p>
                            <p>
                                <Button
                                    color="primary"
                                    tag={Link}
                                    to={PATH_DAY_PLAN_INDEX}
                                    onClick={event =>
                                        this.constructor.handleClick(event)
                                    }
                                    className="w-100"
                                >
                                    {this.props.t(
                                        'redirect/diet-settings/button'
                                    )}
                                </Button>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default withMobileAppMode(withLocale(NoDiet));
