import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardFooter, Button } from 'reactstrap';

export default class SaleProducts extends Component {
    render() {
        return (
            <Card className="product placeholder">
                <CardHeader>
                    <span className="product-name placeholder mb-1">
                        &nbsp;
                    </span>
                </CardHeader>
                <CardBody>
                    <span className="product-price placeholder mb-1">
                        &nbsp;
                    </span>
                    <span className="product-description placeholder">
                        &nbsp;
                    </span>
                </CardBody>
                <CardFooter>
                    <Button color="placeholder">&nbsp;</Button>
                </CardFooter>
            </Card>
        );
    }
}
