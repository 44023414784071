import { useEffect } from 'react';
import PropTypes from 'prop-types';

const BuyIndexContainer = ({ buy }) => {
    const clearDataAndRedirectToStripe = url => {
        global.localStorage.removeItem('buyProductId');
        global.localStorage.removeItem('buyFromLandinng');
        global.localStorage.removeItem('buyPromoCode');
        window.location = url;
    };

    const handleBuy = async () => {
        const productVariantId = +global.localStorage.getItem('buyProductId');
        const cancelUrl = global.localStorage.getItem('buyFromLandinng');
        const promoCode = global.localStorage.getItem('buyPromoCode');

        if (productVariantId && cancelUrl) {
            let request;

            if (promoCode) {
                request = {
                    productVariantId,
                    successUrl: `${process.env.REACT_APP_URL}/buy/thank-you/%PAYMENT_TOKEN%`,
                    cancelUrl,
                    promoCode,
                };
            } else {
                request = {
                    productVariantId,
                    successUrl: `${process.env.REACT_APP_URL}/buy/thank-you/%PAYMENT_TOKEN%`,
                    cancelUrl,
                };
            }

            try {
                const response = await buy(request);
                const {
                    __typename,
                    url,
                    paymentWithoutPromotionCode,
                } = response.data.sale.createSubscription;

                if (__typename === 'Payment') {
                    clearDataAndRedirectToStripe(url);
                } else if (__typename === 'SubscriptionCreateException') {
                    throw new Error(
                        `Failed to create subscriprion, SubscriptionCreateException`
                    );
                } else if (__typename === 'ValidationException') {
                    throw new Error(
                        `Failed to create subscriprion, ValidationException`
                    );
                } else if (__typename === 'UnauthorizedException') {
                    throw new Error(
                        `Failed to create subscriprion, UnauthorizedException`
                    );
                } else if (__typename === 'InvalidPromoCodeException') {
                    if (paymentWithoutPromotionCode.__typename === 'Payment') {
                        clearDataAndRedirectToStripe(
                            paymentWithoutPromotionCode.url
                        );
                    } else if (
                        paymentWithoutPromotionCode.__typename ===
                        'SubscriptionCreateException'
                    ) {
                        throw new Error(
                            `Failed to create subscriprion, SubscriptionCreateException`
                        );
                    }
                }
            } catch (e) {
                throw new Error(
                    `Failed to create subscriprion, got error: ${e}`
                );
            }
        }
    };

    useEffect(() => {
        handleBuy();
    }, []);

    return null;
};

BuyIndexContainer.propTypes = {
    buy: PropTypes.func.isRequired,
};

export default BuyIndexContainer;
