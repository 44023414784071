import React from 'react';

const withDebounce = ChildComponent => {
    class HOC extends React.Component {
        state = {
            isDebounce: true,
        };

        debounce = async (func, delay) => {
            if (this.state.isDebounce) {
                await setTimeout(() => {
                    func();
                    this.setState({ isDebounce: true });
                }, delay);
                this.setState({ isDebounce: false });
            }
        };

        render() {
            return <ChildComponent {...this.props} debounce={this.debounce} />;
        }
    }

    return HOC;
};

export default withDebounce;
