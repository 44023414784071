import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';

export class ShoppingCartThankYouServiceActivationComponent extends React.Component {
    render() {
        const {
            containsPhysicalProduct,
            isCod,
            isDietInPayment,
            isTrainingInPayment,
        } = this.props;
        return (
            <React.Fragment>
                {containsPhysicalProduct || isCod ? (
                    <section data-test="thank-you/how-to-activate">
                        <Container>
                            <h2>
                                {this.props.t(
                                    'thank-you/how-to-activate-the-service'
                                )}
                            </h2>
                            <Row>
                                <Col md="6">
                                    <ol>
                                        <li>
                                            {this.props.t('thank-you/step1')}
                                        </li>
                                        <li>
                                            {isCod
                                                ? this.props.t(
                                                      'thank-you/step2a'
                                                  )
                                                : this.props.t(
                                                      'thank-you/step2b'
                                                  )}
                                            {this.props.t('thank-you/step2c')}
                                        </li>
                                        <li>
                                            {this.props.t('thank-you/step3')}
                                        </li>
                                    </ol>
                                </Col>

                                {isCod &&
                                (isDietInPayment || isTrainingInPayment) ? (
                                    <Col md="6" data-test="thank-you/flyer">
                                        <p>
                                            {this.props.t(
                                                'thank-you/activation-code-and-instruction'
                                            )}
                                        </p>

                                        <div className="flyer">
                                            <header>
                                                {this.props.t(
                                                    'thank-you/flyer-header'
                                                )}
                                            </header>
                                            <main>
                                                <p>
                                                    {this.props.t(
                                                        'thank-you/flyer-content1'
                                                    )}
                                                </p>
                                                <p>
                                                    {this.props.t(
                                                        'thank-you/flyer-content2',
                                                        {
                                                            site_url:
                                                                process.env
                                                                    .REACT_APP_URL,
                                                        }
                                                    )}
                                                </p>
                                                <p>
                                                    {this.props.t(
                                                        'thank-you/flyer-content3'
                                                    )}
                                                </p>
                                            </main>
                                            <footer>
                                                {this.props.t(
                                                    'thank-you/flyer-footer'
                                                )}
                                            </footer>
                                        </div>

                                        <p className="code">
                                            <strong>xxx-xxx-xxx</strong>
                                        </p>
                                    </Col>
                                ) : (
                                    ''
                                )}
                            </Row>
                        </Container>
                    </section>
                ) : (
                    ''
                )}
            </React.Fragment>
        );
    }
}

ShoppingCartThankYouServiceActivationComponent.propTypes = {
    t: PropTypes.func.isRequired,
    isDietInPayment: PropTypes.bool.isRequired,
    isTrainingInPayment: PropTypes.bool.isRequired,
    isCod: PropTypes.bool.isRequired,
    containsPhysicalProduct: PropTypes.bool.isRequired,
};

export default withLocale(ShoppingCartThankYouServiceActivationComponent);
