import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

import withRequired from './withRequired';

class InputSwitch extends Component {
    static propTypes = {
        checked: PropTypes.bool,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        name: PropTypes.string.isRequired,
        handleChange: PropTypes.func.isRequired,
        label: PropTypes.node,
        required: PropTypes.bool,
        errors: PropTypes.shape({
            details: PropTypes.arrayOf(
                PropTypes.shape({
                    fieldName: PropTypes.string,
                    messages: PropTypes.arrayOf(PropTypes.string),
                })
            ),
        }),
    };

    static defaultProps = {
        checked: false,
        value: 1,
        label: undefined,
        required: false,
        errors: undefined,
    };

    render() {
        let errorMgs;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === this.props.name) {
                    errorMgs = error.messages;
                }
            });
        }

        const labelClassNames = ['form-switch-label'];
        if (errorMgs) {
            labelClassNames.push('is-invalid');
        }

        return (
            <FormGroup className="form-switch">
                <Label className={labelClassNames.join(' ')}>
                    <div className={errorMgs ? 'border has-error' : 'border'}>
                        <Input
                            type="checkbox"
                            name={this.props.name}
                            value={this.props.value}
                            checked={this.props.checked}
                            onChange={this.props.handleChange}
                            required={this.props.required}
                        />

                        {'  '}
                        <span className="switch" />
                        {this.props.required ? <sup>*</sup> : null}
                        {'  '}
                        <p className="d-inline description">
                            {this.props.label}
                        </p>
                    </div>
                </Label>

                {!errorMgs ? null : (
                    <FormFeedback>
                        {errorMgs.map(msg => (
                            <li key={msg}>{msg}</li>
                        ))}
                    </FormFeedback>
                )}
            </FormGroup>
        );
    }
}

export default withRequired(InputSwitch);
