import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import Odometer from 'react-odometerjs';

class HydrationDayProgress extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        unit: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        goal: PropTypes.number.isRequired,
    };

    state = {
        odometer: 0,
    };

    componentDidMount() {
        this.setState({ odometer: this.props.value });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                odometer: this.props.value,
            });
        }
    }

    render() {
        const { t, unit, value, goal } = this.props;

        // 2 * PI * R = 553
        const percentage = (value / goal) * 553;

        return (
            <>
                <div className="hydration-chart">
                    <svg className="svg-donut" width="200px" height="200px">
                        <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                        >
                            <circle
                                className="donut"
                                strokeDasharray={`${percentage}, 900`}
                                stroke="#FF3B30"
                                strokeWidth="24"
                                strokeLinecap="round"
                                transform="translate(100.000000, 100.000000) scale(1, -1) translate(-100.000000, -100.000000) "
                                cx="100"
                                cy="100"
                                r="88"
                                style={{
                                    '--endvalue': percentage,
                                }}
                            ></circle>
                        </g>
                    </svg>

                    <div className="text">
                        <span className="current-value">
                            <Odometer value={this.state.odometer} format="d" />{' '}
                            {unit}
                        </span>
                        <br />
                        {t('from')} {goal} {unit}
                    </div>
                </div>
            </>
        );
    }
}

export { HydrationDayProgress };
export default withLocale(HydrationDayProgress);
