import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import BuyThankYouContainer from './BuyThankYouContainer';
import ReportPaymentConnectorApollo from '../Connector/Apollo/ReportPayment/ReportPaymentConnectorApollo';

const BuyThankYouPage = props => {
    const token = props.match.params.token;

    return (
        <ReportPaymentConnectorApollo>
            {({ reportPayment }) => {
                return (
                    <BuyThankYouContainer
                        reportPayment={reportPayment}
                        token={token}
                    />
                );
            }}
        </ReportPaymentConnectorApollo>
    );
};

BuyThankYouPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default withRouter(BuyThankYouPage);
