import React from 'react';
import PropTypes from 'prop-types';
import { PATHS } from 'config/paths';
import { withRouter, Redirect } from 'react-router';
import queryString from 'query-string';
import { PATH_BUY_SIGNUP } from '../paths';

const BuyAddPage = props => {
    const productId = props.match.params.productId;

    const queryStringObject = queryString.parse(global.location.search);
    const fromLanging = queryStringObject.from;
    const promoCode = queryStringObject.pc;

    if (productId) {
        global.localStorage.setItem('buyProductId', productId);
        global.localStorage.setItem('buyFromLandinng', fromLanging);
        if (promoCode) {
            global.localStorage.setItem('buyPromoCode', promoCode);
        }
        return <Redirect to={`${PATH_BUY_SIGNUP}/${global.location.search}`} />;
    }

    window.location = PATHS.EXTERNAL.HOME;
    return null;
};

BuyAddPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            productId: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default withRouter(BuyAddPage);
