import gql from 'graphql-tag';

export default gql`
    mutation CreateSubscription(
        $productVariantId: Int!
        $successUrl: String!
        $cancelUrl: String!
        $promoCode: String
    ) {
        sale {
            createSubscription(
                productVariantId: $productVariantId
                successUrl: $successUrl
                cancelUrl: $cancelUrl
                promoCode: $promoCode
            ) {
                __typename
                ... on Payment {
                    url
                }
                ... on SubscriptionCreateException {
                    message
                }
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
                ... on UnauthorizedException {
                    code
                }
                ... on InvalidPromoCodeException {
                    message
                    paymentWithoutPromotionCode {
                        ... on Payment {
                            url
                        }
                        ... on SubscriptionCreateException {
                            message
                        }
                    }
                }
            }
        }
    }
`;
