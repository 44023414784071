import gql from 'graphql-tag';

const query = gql`
    query HomePageQuery($promoCode: String, $tag: String) {
        sale {
            productVariants(promoCode: $promoCode, tag: $tag) {
                ...saleProductVariantDetails
            }
        }
    }

    fragment saleProductVariantDetails on SaleProductVariant {
        id
        name
        thumbnailUrl
        saleProduct {
            price {
                regular {
                    amount
                    currency
                }
                promo {
                    amount
                    currency
                }
            }
            productCardTitle
            isBestseller
            shortDescription
            longDescription
            features
            lengthLabel
        }
    }
`;

export default query;
