import React from 'react';
import { Col, Container, Form, FormGroup, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import InputRadio from '../../components/FormElements/InputRadioControlled2';
import withLoading from '../../Layout/withLoading';
import SystemOfMeasurementsFormPlaceholder from './SystemOfMeasurementsFormPlaceholder';

class SystemOfMeasurementsForm extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        className: PropTypes.string,
        setSystemOfMeasures: PropTypes.shape({
            changeSettings: PropTypes.func.isRequired,
        }).isRequired,
        value: PropTypes.oneOf(['si', 'imperial']).isRequired,
    };

    static defaultProps = {
        className: null,
    };

    render() {
        return (
            <Container className={this.props.className}>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <div className="white-box">
                            <Form>
                                <FormGroup tag="fieldset" className="mb-0">
                                    <h2 className="title-small semi-bold">
                                        {this.props.t('settings/metric-system')}
                                    </h2>
                                    <InputRadio
                                        id="systemOfMeasures1"
                                        label={this.props.t('settings/si')}
                                        name="systemOfMeasures"
                                        value="si"
                                        handleChange={event => {
                                            return this.props.setSystemOfMeasures.changeSettings(
                                                event.target.value
                                            );
                                        }}
                                        checked={this.props.value === 'si'}
                                        radioRight
                                    />

                                    <InputRadio
                                        id="systemOfMeasures2"
                                        label={this.props.t(
                                            'settings/imperial'
                                        )}
                                        name="systemOfMeasures"
                                        value="imperial"
                                        handleChange={event =>
                                            this.props.setSystemOfMeasures.changeSettings(
                                                event.target.value
                                            )
                                        }
                                        checked={
                                            this.props.value === 'imperial'
                                        }
                                        radioRight
                                    />
                                </FormGroup>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withLoading(
    withLocale(SystemOfMeasurementsForm),
    SystemOfMeasurementsFormPlaceholder
);
