import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled';
import { withLocale } from '@dietlabs/components';
import withStep from './StepContext/withStep';

class StepActivityLevel extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        activityLevel: PropTypes.string,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
    };

    static defaultProps = {
        activityLevel: undefined,
    };

    componentDidMount() {
        this.props.changeNextBtnState(!this.props.activityLevel);
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.activityLevel);
    }

    render() {
        const { activityLevel, handleInputChange } = this.props;

        return (
            <div className="full-screen3">
                <div className="mb-auto w-100">
                    <FormGroup tag="fieldset">
                        <Label className="large">
                            {this.props.t('diet-settings/activity-level/label')}
                        </Label>

                        <InputRadio
                            label={[
                                <strong
                                    className="d-block"
                                    key="activity-level-none-title"
                                >
                                    {this.props.t(
                                        'diet-settings/activity-level/none/title'
                                    )}
                                </strong>,
                                <p
                                    className="small"
                                    key="activity-level-none-description"
                                >
                                    {this.props.t(
                                        'diet-settings/activity-level/none/description'
                                    )}
                                </p>,
                            ]}
                            name="activityLevel"
                            value="ACTIVITY_LEVEL_NONE"
                            handleChange={handleInputChange}
                            checked={activityLevel === 'ACTIVITY_LEVEL_NONE'}
                            data-test="activity-level-none"
                        />

                        <InputRadio
                            label={[
                                <strong
                                    className="d-block"
                                    key="activity-level-low-title"
                                >
                                    {this.props.t(
                                        'diet-settings/activity-level/low/title'
                                    )}
                                </strong>,
                                <p
                                    className="small"
                                    key="activity-level-low-description"
                                >
                                    {this.props.t(
                                        'diet-settings/activity-level/low/description'
                                    )}
                                </p>,
                            ]}
                            name="activityLevel"
                            value="ACTIVITY_LEVEL_LOW"
                            handleChange={handleInputChange}
                            checked={activityLevel === 'ACTIVITY_LEVEL_LOW'}
                            data-test="activity-level-low"
                        />

                        <InputRadio
                            label={[
                                <strong
                                    className="d-block"
                                    key="activity-level-medium-title"
                                >
                                    {this.props.t(
                                        'diet-settings/activity-level/medium/title'
                                    )}
                                </strong>,
                                <p
                                    className="small"
                                    key="activity-level-medium-description"
                                >
                                    {this.props.t(
                                        'diet-settings/activity-level/medium/description'
                                    )}
                                </p>,
                            ]}
                            name="activityLevel"
                            value="ACTIVITY_LEVEL_MEDIUM"
                            handleChange={handleInputChange}
                            checked={activityLevel === 'ACTIVITY_LEVEL_MEDIUM'}
                            data-test="activity-level-medium"
                        />

                        <InputRadio
                            label={[
                                <strong
                                    className="d-block"
                                    key="activity-level-high-title"
                                >
                                    {this.props.t(
                                        'diet-settings/activity-level/high/title'
                                    )}
                                </strong>,
                                <p
                                    className="small"
                                    key="activity-level-high-description"
                                >
                                    {this.props.t(
                                        'diet-settings/activity-level/high/description'
                                    )}
                                </p>,
                            ]}
                            name="activityLevel"
                            value="ACTIVITY_LEVEL_HIGH"
                            handleChange={handleInputChange}
                            checked={activityLevel === 'ACTIVITY_LEVEL_HIGH'}
                            data-test="activity-level-high"
                        />
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepActivityLevel));
