import React from 'react';
import PropTypes from 'prop-types';
import imageUrl from 'assets/images/error_404_img.png';
import ErrorGeneric from './ErrorGeneric';

const Error404 = props => {
    const { t, ...otherProps } = props;
    return (
        <ErrorGeneric
            t={t}
            message={t('error/message/generic')}
            imageUrl={imageUrl}
            additionalMessage={t('error/additional-message/not-found')}
            {...otherProps}
        />
    );
};

Error404.propTypes = {
    t: PropTypes.func.isRequired,
};

export default Error404;
