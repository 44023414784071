import React, { Component } from 'react';
import glass from 'assets/icons/hydration/glass.svg';
import bottle from 'assets/icons/hydration/bottle.svg';
import custom from 'assets/icons/hydration/custom.svg';
import AddPlaceholder from './HydrationAddButtonPlaceholder';

class HydrationAddPlaceholder extends Component {
    render() {
        return (
            <div className="text-center">
                <AddPlaceholder value={8} unit="oz" icon={glass} />

                <AddPlaceholder
                    value={32}
                    unit="oz"
                    icon={bottle}
                    className="mx-2"
                />

                <AddPlaceholder value={null} unit="oz" icon={custom} />
            </div>
        );
    }
}

export default HydrationAddPlaceholder;
