import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Alert } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import ShoppingCartPaymentMethod from 'view/ShoppingCart/Payment/Method/ShoppingCartPaymentMethod';
import { SHOPPING_CART_PAYMENT_MAKE_SUCCESS } from 'view/ShoppingCart/Confirm/Payment/Make/ShoppingCartPaymentMakeActionType';
import { PATHS } from 'config/paths';
import Loader from 'Layout/Loader';
import { reportError } from '@dietlabs/utils';
import scrollToElement from 'components/Helpers/scrollToElement';

import gpay from 'assets/icons/payments/gpay.svg';
import ing from 'assets/icons/payments/ing.svg';
import ipko from 'assets/icons/payments/ipko.svg';
import mbank from 'assets/icons/payments/mbank.svg';
import santander from 'assets/icons/payments/santander.svg';

export class ShoppingCartConfirmPaymentOnlineComponent extends React.Component {
    constructor(props) {
        super(props);

        this.scrollAnchorRef = React.createRef();

        this.state = {
            preload: false,
            paymentMethodError: false,
        };
    }

    render() {
        return (
            <section ref={this.scrollAnchorRef}>
                <Container>
                    <h2>{this.props.t('cart/payment')}</h2>
                    {this.state.paymentMethodError ? (
                        <Alert color="danger" className="mt-3 mb-0">
                            {this.props.t('cart/payment/error-occured')}
                        </Alert>
                    ) : (
                        ''
                    )}
                    <br />
                    {this.props.paymentMethods.map(method => (
                        <div
                            className="payment-method d-flex align-items-center"
                            data-test="payment-method-button-pay"
                            onClick={() => this.choosePaymentMethod(method)}
                            key={`${method.methodId}_${method.channelId}`}
                        >
                            <Row
                                data-test="payment-method-row"
                                className="align-items-center w-100"
                            >
                                <Col xs={4} className="text-center">
                                    {method.wideViewportLogoUrl ? (
                                        <React.Fragment>
                                            <img
                                                src={method.wideViewportLogoUrl}
                                                className="payment-logo img-fluid d-none d-md-block"
                                            />
                                            <img
                                                src={method.logoUrl}
                                                className="payment-logo img-fluid d-md-none"
                                            />
                                        </React.Fragment>
                                    ) : (
                                        <img
                                            src={method.logoUrl}
                                            className="payment-logo img-fluid"
                                        />
                                    )}
                                </Col>
                                <Col xs={8}>
                                    <h3>{method.name}</h3>
                                    {method.methodId === 1 &&
                                    method.channelId === null ? (
                                        <ul className="payment-online-method-list">
                                            <li className="payment-online-method-item">
                                                <img
                                                    src={gpay}
                                                    lang="en"
                                                    alt="Google Pay"
                                                    className="payment-online-method-logo"
                                                />
                                            </li>
                                            <li className="payment-online-method-item">
                                                <img
                                                    src={mbank}
                                                    alt="mBank"
                                                    className="payment-online-method-logo"
                                                />
                                            </li>
                                            <li className="payment-online-method-item">
                                                <img
                                                    src={ipko}
                                                    alt="iPKO"
                                                    className="payment-online-method-logo"
                                                />
                                            </li>
                                            <li className="payment-online-method-item">
                                                <img
                                                    src={santander}
                                                    alt="Santander Bank"
                                                    className="payment-online-method-logo"
                                                />
                                            </li>
                                            <li className="payment-online-method-item">
                                                <img
                                                    src={ing}
                                                    alt="ING"
                                                    className="payment-online-method-logo"
                                                />
                                            </li>
                                            <li className="payment-online-method-item-et-al text-secondary">
                                                <abbr
                                                    title={this.props.t(
                                                        'et-al/full'
                                                    )}
                                                    className="sr-only"
                                                >
                                                    {this.props.t(
                                                        'et-al/abbrev'
                                                    )}
                                                </abbr>
                                            </li>
                                        </ul>
                                    ) : (
                                        <p>{method.description}</p>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Container>
                {this.state.preload ? <Loader /> : ''}
            </section>
        );
    }

    choosePaymentMethod(paymentMethod) {
        this.setState({
            preload: true,
            paymentMethodError: false,
        });
        this.props
            .make(
                this.props.token,
                paymentMethod.methodId,
                paymentMethod.channelId,
                `${document.location.protocol}//${
                    document.location.host
                }${PATHS.CART.THANK_YOU.replace(':token', this.props.token)}`,
                `${document.location.protocol}//${
                    document.location.host
                }${PATHS.CART.CONFIRM.replace(':token', this.props.token)}`
            )
            .then(response => {
                if (response.type === SHOPPING_CART_PAYMENT_MAKE_SUCCESS) {
                    global.location.href = response.url;
                } else {
                    this.setState({ preload: false, paymentMethodError: true });
                    scrollToElement(this.scrollAnchorRef.current);
                    reportError(new Error(`Payment error`));
                }
            });
    }
}

ShoppingCartConfirmPaymentOnlineComponent.propTypes = {
    t: PropTypes.func.isRequired,
    paymentMethods: PropTypes.arrayOf(
        PropTypes.instanceOf(ShoppingCartPaymentMethod).isRequired
    ).isRequired,
    token: PropTypes.string.isRequired,
    make: PropTypes.func.isRequired,
};

export default withLocale(ShoppingCartConfirmPaymentOnlineComponent);
