import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { ApiDateType } from 'types/CommonTypes';
import { createDateObject, formatIsoDate } from 'utils/dateFormatter';

import DietPlanConnectorApollo from '@dietlabs/components/src/ApolloConnectors/DailyDietPlan/Fetch/DietPlanConnectorApollo';
import DailyDietPlanConnectorApollo from '@dietlabs/components/src/ApolloConnectors/DailyDietPlan/Fetch/DailyDietPlanConnectorApollo';
import DailyDietPlanCacheConnectorApollo from '@dietlabs/components/src/ApolloConnectors/DailyDietPlan/Fetch/DailyDietPlanCacheConnectorApollo';
import MealDetailsCacheConnectorApollo from '@dietlabs/components/src/ApolloConnectors/DailyDietPlan/FetchMeal/MealDetailsCacheConnectorApollo';
import DayPlanIndexContainer from './DayPlanIndexContainer';
import withApolloClient from '../../Apollo/withApolloClient';

class DayPlanIndex extends Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                date: ApiDateType,
            }).isRequired,
            path: PropTypes.string,
        }).isRequired,
        client: PropTypes.shape().isRequired,
        routeChildrenMounted: PropTypes.bool.isRequired,
    };

    render() {
        let date;

        if (this.props.match.params.date) {
            date = createDateObject(this.props.match.params.date);
        } else {
            date = createDateObject(formatIsoDate(new Date()));
        }

        // hack - wait until routeChildren component did mount and send routeChanged post msg to native aplication
        if (this.props.routeChildrenMounted) {
            return (
                // idea: pass first data from nativeApp to dayplan to prevent load data from backend.
                <DietPlanConnectorApollo>
                    {({ ...dietPlanData }) => (
                        <DailyDietPlanConnectorApollo date={date}>
                            {({ ...dailyDietPlanData }) => (
                                <DailyDietPlanCacheConnectorApollo date={date}>
                                    {({ prefetchSurroundingDays }) => (
                                        <MealDetailsCacheConnectorApollo>
                                            {({ prefetchMealDetails }) => (
                                                <DayPlanIndexContainer
                                                    date={date}
                                                    {...dietPlanData}
                                                    {...dailyDietPlanData}
                                                    prefetchSurroundingDays={
                                                        prefetchSurroundingDays
                                                    }
                                                    prefetchMealDetails={
                                                        prefetchMealDetails
                                                    }
                                                    loading={
                                                        dailyDietPlanData.loading ||
                                                        dietPlanData.loading
                                                    }
                                                />
                                            )}
                                        </MealDetailsCacheConnectorApollo>
                                    )}
                                </DailyDietPlanCacheConnectorApollo>
                            )}
                        </DailyDietPlanConnectorApollo>
                    )}
                </DietPlanConnectorApollo>
            );
        }

        return null;
    }
}

export default withApolloClient(withRouter(DayPlanIndex));
