import MakeDecisionPage from './Index/MakeDecisionPage';

const PATH_MAKE_DECISION = '/make-decision';

const routes = [
    {
        path: PATH_MAKE_DECISION,
        component: MakeDecisionPage,
        private: true,
        exact: false,
        title: 'route-title/make-decision',
        mobileAppTabIdentifier: 'more',
    },
];

export { routes };
