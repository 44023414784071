import gql from 'graphql-tag';

export default gql`
    mutation ReportPayment($token: String!, $operatorData: String) {
        sale {
            reportPayment(token: $token, operatorData: $operatorData) {
                __typename
                ... on Payment {
                    user {
                        email
                    }
                }
                ... on NotReportedPayment {
                    user {
                        email
                    }
                    reportData
                }
                ... on CanceledPayment {
                    reason
                }

                ... on ValidationException {
                    code
                }
            }
        }
    }
`;
