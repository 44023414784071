import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import ExternalProviderContainer from '@dietlabs/components/src/Auth/Login/ByExternalProvider/ExternalProviderContainer';
import { RETURN_URL_LOGIN } from '@dietlabs/components/src/Auth/Login/ByExternalProvider/LoginReturnUrls';
import UserAgreementNotifyConnector from '@dietlabs/components/src/ApolloConnectors/UserSettings/Notifications/UserAgreementNotifyConnector';
import ChangePasswordConnector from '@dietlabs/components/src/ApolloConnectors/UserSettings/ChangePassword/ChangePasswordConnector';
import SettingsConnectorApollo from '@dietlabs/components/src/ApolloConnectors/UserSettings/CurrentSettings/CurrentSettingsConnectorApollo';
import HydrationSettingsConnector from '@dietlabs/components/src/ApolloConnectors/UserSettings/Hydration/HydrationSettingsConnectorApollo';
import ChangeSettingsConnectorApollo from '@dietlabs/components/src/ApolloConnectors/UserSettings/SystemOfMeasurements/ChangeSettingsConnectorApollo';
import Placeholder from './ProductsAndSubscriptions/SettingsProductsAndSubscriptionsPlaceholder';
import SettingsProductsAndSubscriptionsComponent from './ProductsAndSubscriptions/SettingsProductsAndSubscriptionsComponent';
import HydrationSettings from './Hydration/HydrationSettings';
import UserEmailForm from './UserEmail/UserEmailForm';
import RemoveAccountButton from './RemoveAccount/Components/RemoveAccountButton';
import Layout from '../Layout/Layout';
import SystemOfMeasurementsForm from './SystemOfMeasurements/SystemOfMeasurementsForm';
import NotificationsSettings from './Notifications/NotificationsSettingsForm';
import ChangePasswordForm from './ChangePassword/ChangePasswordForm';

class UserSettings extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    renderActiveSubscriptions = result => {
        if (result.loading) {
            return <Placeholder className="mt-2" />;
        }
        // Filter out undefined values
        const filteredProps = Object.entries(result)
            .filter(([, v]) => v !== undefined)
            .reduce((c, [k, v]) => ({ ...c, [k]: v }), {});
        return <SettingsProductsAndSubscriptionsComponent {...filteredProps} />;
    };

    renderSocialMediaLogin = (
        isAbleToLoginWithGoogle,
        isAbleToLoginWithFacebook,
        isAbleToLoginWithApple
    ) => (
        <Container>
            <Row>
                <Col xs="12" sm={{ size: 8, offset: 2 }}>
                    <div className="white-box">
                        <h2 className="title-small semi-bold">
                            {this.props.t(
                                'auth/login/social-media-login-header'
                            )}
                        </h2>
                        {isAbleToLoginWithGoogle &&
                        isAbleToLoginWithFacebook &&
                        isAbleToLoginWithApple ? (
                            this.props.t('auth/login/social-media-set-up')
                        ) : (
                            <ExternalProviderContainer
                                returnUrl={RETURN_URL_LOGIN}
                                displayGoogleButton={!isAbleToLoginWithGoogle}
                                displayFacebookButton={
                                    !isAbleToLoginWithFacebook
                                }
                                displayAppleButton={!isAbleToLoginWithApple}
                                roundedBtn
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );

    render() {
        return (
            <Layout page="settings">
                <header>
                    <h1 className="text-center d-none d-md-block title-large semi-bold">
                        {this.props.t('settings/title')}
                    </h1>
                </header>
                <section className="pt-0">
                    <SettingsConnectorApollo>
                        {result => (
                            <React.Fragment>
                                <UserEmailForm
                                    email={result.email}
                                    loading={result.loading}
                                />
                                <ChangeSettingsConnectorApollo
                                    rawQueryResult={result.rawData}
                                >
                                    {systemOfMeasures => (
                                        <SystemOfMeasurementsForm
                                            setSystemOfMeasures={
                                                systemOfMeasures
                                            }
                                            value={result.systemOfMeasures}
                                            loading={result.loading}
                                        />
                                    )}
                                </ChangeSettingsConnectorApollo>

                                <HydrationSettingsConnector
                                    rawQueryResult={result.rawData}
                                >
                                    {save => (
                                        <HydrationSettings
                                            loading={result.loading}
                                            defaultDailyGoal={
                                                result.hydrationSettings
                                                    .defaultDailyGoal
                                            }
                                            save={save}
                                        />
                                    )}
                                </HydrationSettingsConnector>

                                <UserAgreementNotifyConnector
                                    rawQueryResult={result.rawData}
                                >
                                    {notify => (
                                        <NotificationsSettings
                                            agreements={result.agreements}
                                            onNotifyChange={
                                                notify.changeAgreement
                                            }
                                            loading={result.loading}
                                        />
                                    )}
                                </UserAgreementNotifyConnector>

                                {result.passwordIsSet ? (
                                    <ChangePasswordConnector>
                                        {changePassword => (
                                            <ChangePasswordForm
                                                changePassword={
                                                    changePassword.changePassword
                                                }
                                                username={result.email}
                                            />
                                        )}
                                    </ChangePasswordConnector>
                                ) : null}

                                {this.renderActiveSubscriptions(result)}

                                {this.renderSocialMediaLogin(
                                    result.isAbleToLoginWithGoogle,
                                    result.isAbleToLoginWithFacebook,
                                    result.isAbleToLoginWithApple
                                )}
                                <RemoveAccountButton loading={result.loading} />
                            </React.Fragment>
                        )}
                    </SettingsConnectorApollo>
                </section>
            </Layout>
        );
    }
}

export default withLocale(UserSettings);
