import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import withGTM from 'view/GoogleTagManager/withGTM';

import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';

import Loader from 'Layout/Loader';

class DayPlanSummary extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        canBeCopiedToDate: PropTypes.bool.isRequired,
        copyDayPlan: PropTypes.shape().isRequired,
        alreadyCopied: PropTypes.bool.isRequired,
        print: PropTypes.bool.isRequired,
        trackEvent: PropTypes.func.isRequired,
        toggleModalWithDayReplacements: PropTypes.func.isRequired,
    };

    state = {
        alreadyClicked: this.props.alreadyCopied,
        preload: false,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.alreadyCopied !== this.props.alreadyCopied) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                alreadyClicked: this.props.alreadyCopied,
                preload: false,
            });
        }
    }

    copyDay = async () => {
        if (!this.state.alreadyClicked) {
            this.setState({
                alreadyClicked: true,
                preload: true,
            });
            await this.props.copyDayPlan.copy();
            if (
                this.props.copyDayPlan.response.me.dietSetCopy.__typename ===
                'BasicMutationSuccess'
            ) {
                this.props.trackEvent(
                    EVENT_CATEGORY_DIET_PLAN,
                    'copy to next day'
                );
            } else {
                throw new Error(
                    `Copy day plan to next day failed: ${this.props.copyDayPlan.response.me.dietSetCopy.__typename}`
                );
            }
        }
    };

    render() {
        const { canBeCopiedToDate, print, t } = this.props;

        return (
            <React.Fragment>
                <section className="summary py-0">
                    <Container className="text-center">
                        <Row>
                            <Col md={{ size: 8, offset: 2 }}>
                                {canBeCopiedToDate && !print ? (
                                    <Row>
                                        <Col
                                            xs="12"
                                            sm={{ size: 8, offset: 2 }}
                                        >
                                            <Button
                                                color="primary"
                                                size="sm"
                                                outline={
                                                    this.state.alreadyClicked
                                                }
                                                className="mb-3"
                                                onClick={() => this.copyDay()}
                                                data-test="copy-button"
                                                style={{ width: '20rem' }}
                                            >
                                                {this.state.alreadyClicked
                                                    ? t(
                                                          'meal/copy-day-button-done'
                                                      )
                                                    : t('meal/copy-day-button')}
                                            </Button>
                                        </Col>
                                    </Row>
                                ) : (
                                    ''
                                )}

                                <Button
                                    color="primary"
                                    size="sm"
                                    className="mb-3"
                                    onClick={() =>
                                        this.props.toggleModalWithDayReplacements()
                                    }
                                    style={{ width: '20rem' }}
                                >
                                    {t('meal/exchange-day-button')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {this.state.preload ? <Loader /> : ''}
            </React.Fragment>
        );
    }
}

export { DayPlanSummary };
export default withGTM(withLocale(DayPlanSummary));
