import React, { Component } from 'react';
import { FormGroup, Label, Input, InputGroup, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import { InputErrorType } from 'types/CommonTypes';
import { ReactComponent as DownArrow } from 'assets/icons/chevron-down.svg';
import withRequired from './withRequired';

function getOptionElements(root) {
    if (root.type === 'option') {
        return [root];
    }
    if (Array.isArray(root)) {
        return [].concat(...root.map(getOptionElements));
    }
    return getOptionElements(root.props.children);
}

class InputSelect extends Component {
    renderMissingOption() {
        if (this.props.value === undefined) {
            return null;
        }

        let optionElements;
        try {
            optionElements = getOptionElements(this.props.children);
        } catch (error) {
            throw new Error(
                `Failed to get options from children: ${this.props.children}`
            );
        }
        const optionValues = optionElements
            .filter(e => e.type === 'option')
            .map(e => ('value' in e.props ? e.props.value : e.props.children));

        const { value } = this.props;
        if (optionValues.map(String).includes(String(value))) {
            return null;
        }

        return <option className="missing-current-value">{value}</option>;
    }

    render() {
        let errorMgs;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === this.props.name) {
                    errorMgs = error.messages;
                }
            });
        }

        const inputGroupClassNames = [
            'form-control',
            'select',
            errorMgs && 'is-invalid',
            this.props.blue && 'select-blue',
        ];

        return (
            <FormGroup>
                {this.props.label ? (
                    <Label for={this.props.name}>
                        {this.props.required ? <sup>*</sup> : ''}{' '}
                        {this.props.label}
                    </Label>
                ) : (
                    ''
                )}

                <InputGroup className={inputGroupClassNames}>
                    <Input
                        type="select"
                        name={this.props.name}
                        value={this.props.value}
                        placeholder={this.props.placeholder}
                        onChange={this.props.handleChange}
                        required={this.props.required}
                        disabled={!!this.props.disabled}
                    >
                        {this.renderMissingOption()}
                        {this.props.children}
                    </Input>
                    <span className="SVGInline">
                        <DownArrow />
                    </span>
                </InputGroup>
                <FormFeedback>
                    {errorMgs
                        ? errorMgs.map((message, k) => (
                              <li key={k}>{message}</li>
                          ))
                        : ''}
                </FormFeedback>
            </FormGroup>
        );
    }
}

InputSelect.propTypes = {
    errors: InputErrorType,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.node,
    validationErrors: PropTypes.object, //eslint-disable-line
};

InputSelect.defaultProps = {
    errors: undefined,
    label: null,
    required: false,
    disabled: false,
    placeholder: null,
    value: undefined,
    children: null,
    validationErrors: undefined,
};

export default withRequired(InputSelect);
