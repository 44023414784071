import React from 'react';
import PropTypes from 'prop-types';

class ProgressBar extends React.Component {
    static propTypes = {
        progress: PropTypes.shape({
            stepsCount: PropTypes.number,
            currentStepPosition: PropTypes.number,
        }).isRequired,
    };

    render() {
        const pills = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < this.props.progress.stepsCount; i++) {
            pills.push(i);
        }

        return (
            <div className="progress-bar-mlf">
                {pills.map(item => (
                    <span
                        className={
                            item < this.props.progress.currentStepPosition
                                ? 'item active'
                                : 'item'
                        }
                        key={item}
                    ></span>
                ))}
            </div>
        );
    }
}

export default ProgressBar;
