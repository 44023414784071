import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import CachedDataMessage from '@dietlabs/components/src/CachedDataMessage/CachedDataMessage';
import Table from 'Layout/Table/Table';
import TableRowPlaceholder from 'Layout/Table/TableRowPlaceholder';
import { FullMeasurementType } from 'types/CommonTypes';
import { PATH_MEASUREMENT_ADD } from 'Measurement/routes';
import { Button, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import CurrentMeasurementsTableRow from './CurrentMeasurementsTableRow';

export class CurrentMeasurementsContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        weight: FullMeasurementType,
        hip: FullMeasurementType,
        waist: FullMeasurementType,
        thigh: FullMeasurementType,
        chest: FullMeasurementType,
        arm: FullMeasurementType,
        cacheHit: PropTypes.bool.isRequired,
        hasNetworkError: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        weight: undefined,
        hip: undefined,
        waist: undefined,
        thigh: undefined,
        chest: undefined,
        arm: undefined,
    };

    render() {
        const { t, loading, weight, hip, waist, thigh } = this.props;

        return (
            <Row>
                <CachedDataMessage
                    cacheHit={this.props.cacheHit}
                    hasNetworkError={this.props.hasNetworkError}
                />
                <Col xs="12" sm={{ size: 8, offset: 2 }}>
                    <section className="py-0">
                        <h2 className="title-large semi-bold text-center">
                            {t('measurments/current-measurments-title')}
                        </h2>

                        <Table>
                            {loading ? (
                                Array.from(Array(6)).map((data, key) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <TableRowPlaceholder key={key} />
                                ))
                            ) : (
                                <React.Fragment>
                                    <CurrentMeasurementsTableRow
                                        {...weight}
                                        label={t(
                                            'measurments/param/body-weight'
                                        )}
                                    />
                                    <CurrentMeasurementsTableRow
                                        {...hip}
                                        label={t('measurments/param/hip')}
                                    />
                                    <CurrentMeasurementsTableRow
                                        {...waist}
                                        label={t('measurments/param/waist')}
                                    />
                                    <CurrentMeasurementsTableRow
                                        {...thigh}
                                        label={t('measurments/param/thigh')}
                                    />
                                </React.Fragment>
                            )}
                        </Table>

                        <div className="text-center">
                            <Button
                                color="primary"
                                tag={Link}
                                to={PATH_MEASUREMENT_ADD}
                            >
                                {t('measurments/add-measurments-button')}
                            </Button>
                        </div>
                        <hr />
                    </section>
                </Col>
            </Row>
        );
    }
}
export default withLocale(CurrentMeasurementsContainer);
