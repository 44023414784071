import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { withLocale } from '@dietlabs/components';

const Loader = ({ t, fixed, isWrapper = true }) =>
    isWrapper ? (
        <div
            className={[
                'loader-wrapper',
                fixed === 'no' ? 'no-fixed' : null,
            ].join(' ')}
        >
            <Spinner color="primary">{t('loading/message')}</Spinner>
        </div>
    ) : (
        <Spinner color="primary">{t('loading/message')}</Spinner>
    );

Loader.propTypes = {
    fixed: PropTypes.oneOf(['no', null]),
    isWrapper: PropTypes.bool,
    t: PropTypes.func.isRequired,
};
Loader.defaultProps = {
    fixed: null,
    isWrapper: true,
};

export { Loader };
export default withLocale(Loader);
