/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { ReactComponent as LeftArrow } from 'assets/icons/chevron-left.svg';
import { ReactComponent as RightArrow } from 'assets/icons/chevron-right.svg';
import { monthNames, weekDays } from './monthsAndDays';
import CalendarYearView from './CalendarYearView';
import useCalendar from './useCalendar';
import CalendarMonthView from './CalendarMonthView';
import { formatIsoDate } from '../utils/dateFormatter';

const Calendar = ({
    t,
    getPickedDay,
    min,
    max,
    minYear,
    maxYear,
    ownDate,
    resetDateAfterOk,
    showCalendar,
    toggleCalendar,
}) => {
    const {
        year,
        month,
        days,
        changeMonth,
        pickDay,
        pickedDate,
        resetPickedDate,
        pickYear,
        pickMonth,
        step,
        showDateSelection,
        setShowDateSelection,
        dates,
    } = useCalendar(min, max, minYear, maxYear, ownDate);

    const pickedDateYMDFormat = formatIsoDate(pickedDate);

    const toggleModal = () => {
        toggleCalendar();
        setShowDateSelection(false);
        if (resetDateAfterOk) {
            resetPickedDate(dates);
        }
    };

    const renderStep = () => {
        if (showDateSelection && step === 0) {
            return (
                <CalendarYearView
                    minYear={minYear}
                    maxYear={maxYear}
                    pickYear={pickYear}
                    pickedYear={year}
                />
            );
        }
        if (showDateSelection && step === 1) {
            return (
                <CalendarMonthView
                    monthNames={monthNames}
                    pickMonth={pickMonth}
                    pickedMonth={month}
                    t={t}
                />
            );
        }
        return '';
    };

    const handleDataSelection = () => {
        setShowDateSelection(true);
    };

    const handleGetPickedDay = () => {
        getPickedDay(pickedDateYMDFormat);
        toggleModal();
    };
    return (
        <>
            <Modal
                isOpen={showCalendar}
                toggle={toggleModal}
                centered
                contentClassName="modal-calendar"
            >
                <div className="calendar">
                    <div className="calendar-header">
                        <div className="picked-date">{pickedDateYMDFormat}</div>
                    </div>
                    <div className="calendar-body">
                        <div className="navigation">
                            <div
                                className="date"
                                role="button"
                                tabIndex={0}
                                onClick={handleDataSelection}
                            >
                                <span className="month">
                                    {`${t(
                                        `calendar/${monthNames[month]}`
                                    )} ${year}`}
                                </span>
                                <button type="button">
                                    <RightArrow />
                                </button>
                            </div>
                            <div className="month-changer">
                                <button
                                    type="button"
                                    className="prev-month"
                                    onClick={() => changeMonth('prev')}
                                >
                                    <LeftArrow />
                                </button>
                                <button
                                    type="button"
                                    className="next-month"
                                    onClick={() => changeMonth('next')}
                                >
                                    <RightArrow />
                                </button>
                            </div>
                        </div>
                        <div className="calendar-week-day">
                            {weekDays.map((day, i) => (
                                <div key={i}>{t(`calendar/${day}`)}</div>
                            ))}
                        </div>
                        <div className="calendar-days">
                            {days.map((day, i) => {
                                if (day.value) {
                                    if (day.disabled) {
                                        return (
                                            <div
                                                key={i}
                                                role="button"
                                                tabIndex={0}
                                                className="calendar-day disabled"
                                            >
                                                <p>{day.value}</p>
                                            </div>
                                        );
                                    }

                                    return (
                                        <div
                                            key={i}
                                            role="button"
                                            tabIndex={0}
                                            className={`calendar-day ${
                                                day.active ? 'active' : ''
                                            }${day.current ? 'current' : ''}`}
                                            onClick={() => pickDay(day.value)}
                                        >
                                            <p>{day.value}</p>
                                        </div>
                                    );
                                }
                                return (
                                    <div key={i}>
                                        <p>{day.value}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="calendar-footer">
                        <button
                            type="button"
                            onClick={() => resetPickedDate(dates)}
                        >
                            {t('calendar/reset')}
                        </button>
                        <button type="button" onClick={handleGetPickedDay}>
                            {t('calendar/ok')}
                        </button>
                    </div>
                    {renderStep()}
                </div>
            </Modal>
        </>
    );
};

Calendar.propTypes = {
    t: PropTypes.func.isRequired,
    getPickedDay: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    minYear: PropTypes.number,
    maxYear: PropTypes.number,
    ownDate: PropTypes.shape({
        year: PropTypes.number,
        month: PropTypes.number,
        day: PropTypes.number,
    }),
    resetDateAfterOk: PropTypes.bool,
    showCalendar: PropTypes.bool.isRequired,
    toggleCalendar: PropTypes.func.isRequired,
};

Calendar.defaultProps = {
    getPickedDay: undefined,
    min: null,
    max: null,
    minYear: 1900,
    maxYear: 2100,
    ownDate: undefined,
    resetDateAfterOk: false,
};

export default withLocale(Calendar);
