import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { Link } from 'react-router-dom';
import { createDateObject } from 'utils/dateFormatter';
import { withLocale } from '@dietlabs/components';
import { Container, Button } from 'reactstrap';
import HpbaMarkdown from 'Content/utils/HpbaMarkdown';
import ContentPlaceholder from 'Content/Index/ContentPlaceholder';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Redirect } from 'react-router';
import { PATHS } from 'config/paths';
import { scrollToTop } from 'components/Helpers/scrollToTop';
import { PATH_ALL_ARTICLES } from '../paths';

class Article extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        article: PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            category: PropTypes.shape({
                name: PropTypes.string,
                label: PropTypes.shape({
                    color: PropTypes.string,
                }),
            }).isRequired,
            lead: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
            photoUrl: PropTypes.string.isRequired,
            summary: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
        }).isRequired,
        accessTo: PropTypes.string,
        trackFirebaseEvent: PropTypes.func.isRequired,
        loadSaleProduct: PropTypes.func.isRequired,
        loadContent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        accessTo: undefined,
    };

    state = {
        fromArticleList: false,
    };

    componentDidMount() {
        scrollToTop();
        if (this.props.article) {
            this.props.trackFirebaseEvent('screen_view', {
                firebase_screen: 'V_Feed',
                feed_ID: this.props.article.id,
            });
        }
        if (window.history.state) {
            if (window.history.state.state) {
                if (window.history.state.state.fromArticleList) {
                    this.setState({
                        fromArticleList: true,
                    });
                } else {
                    this.setState({
                        fromArticleList: false,
                    });
                }
            }
        } else {
            this.setState({
                fromArticleList: false,
            });
        }
    }

    renderLead = () => {
        if (this.props.article.lead.markdown !== '') {
            return (
                <React.Fragment>
                    <div className="article-lead">
                        <HpbaMarkdown
                            content={this.props.article.lead.markdown}
                            loadSaleProduct={this.props.loadSaleProduct}
                        />
                    </div>
                </React.Fragment>
            );
        }

        return '';
    };

    renderContent = hasAccess => {
        if (this.props.article.content.markdown !== '') {
            return (
                <React.Fragment>
                    <div className="cms-content">
                        <HpbaMarkdown
                            content={this.props.article.content.markdown}
                            loadSaleProduct={this.props.loadSaleProduct}
                            loadContent={this.props.loadContent}
                            hasAccess={hasAccess}
                            articleId={this.props.article.id}
                        />
                        <div className="clearfix" />
                    </div>
                </React.Fragment>
            );
        }

        return '';
    };

    renderSummary = () => {
        if (this.props.article.summary.markdown !== '') {
            return (
                <React.Fragment>
                    <div className="article-summary">
                        <HpbaMarkdown
                            content={this.props.article.summary.markdown}
                            loadSaleProduct={this.props.loadSaleProduct}
                        />
                    </div>
                    <hr />
                </React.Fragment>
            );
        }
        return '';
    };

    handleBack = () => {
        this.props.trackFirebaseEvent('user_action', {
            action: 'tap-back_to_feed',
            location: 'V_Feed_ID',
            feed_ID: this.props.article.id,
        });
        window.history.back();
    };

    render() {
        let hasAccess = false;
        if (this.props.accessTo) {
            const now = new Date();
            const today = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
            );
            const date = createDateObject(this.props.accessTo);
            if (date >= today) {
                hasAccess = true;
            }
        }

        if (this.props.article) {
            return (
                <React.Fragment>
                    <Layout page="cms-article">
                        <div className="bg-white">
                            <header className="d-none d-md-block article-header">
                                <Container className="d-md-flex align-items-center">
                                    <Button
                                        color="primary"
                                        outline
                                        onClick={this.handleBack}
                                    >
                                        {this.props.t('knowledge/back')}
                                    </Button>
                                    <h1 className="text center article-details">
                                        {this.props.t(
                                            'knowledge/article-details'
                                        )}
                                    </h1>
                                </Container>
                            </header>

                            <section className="pt-0">
                                <Container>
                                    <span
                                        className="category"
                                        style={{
                                            color: `#${this.props.article.category.label.color}`,
                                            backgroundColor: `#${this.props.article.category.label.color}1A`,
                                        }}
                                    >
                                        {this.props.article.category.name}
                                    </span>
                                    <h1 className="article-title">
                                        {this.props.article.title}
                                    </h1>
                                    {this.renderLead()}
                                    <div className="article-img">
                                        <LazyLoadImage
                                            className="img-fluid"
                                            effect="blur"
                                            src={this.props.article.photoUrl}
                                        />
                                    </div>

                                    {this.renderContent(hasAccess)}
                                    {this.renderSummary()}

                                    <hr />
                                    <div className="text-center">
                                        <Button
                                            color="primary"
                                            className="w-100"
                                            tag={Link}
                                            to={PATH_ALL_ARTICLES}
                                            outline
                                            onClick={() =>
                                                this.props.trackFirebaseEvent(
                                                    'user_action',
                                                    {
                                                        action:
                                                            'tap-back_to_feed',
                                                        location: 'V_Feed_ID',
                                                        feed_ID: this.props
                                                            .article.id,
                                                    }
                                                )
                                            }
                                        >
                                            {this.state.fromArticleList
                                                ? this.props.t(
                                                      'knowledge/back-to-article-list'
                                                  )
                                                : this.props.t(
                                                      'knowledge/other-articles'
                                                  )}
                                        </Button>
                                    </div>
                                </Container>
                            </section>
                        </div>
                    </Layout>
                </React.Fragment>
            );
        }
        return <Redirect to={PATHS.ERROR_404} />;
    }
}

export default withLoading(
    withFirebase(withLocale(Article)),
    ContentPlaceholder
);
