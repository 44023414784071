import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Row, Col, Button, Form } from 'reactstrap';
import FormGroupPlaceholder from 'Layout/Form/FormGroupPlaceholder';

class MeasurementByDateFormPlaceholder extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        const { t } = this.props;
        return (
            <Form>
                <FormGroupPlaceholder label={t('measurments/date')} required />
                <FormGroupPlaceholder
                    label={t('measurments/param/body-weight')}
                />
                <FormGroupPlaceholder label={t('measurments/param/hip')} />
                <FormGroupPlaceholder label={t('measurments/param/waist')} />
                <FormGroupPlaceholder label={t('measurments/param/thigh')} />
                <Row>
                    <Col xs="6" className="pr-2">
                        <Button color="placeholder" className="w-100">
                            {t('cancel')}
                        </Button>
                    </Col>
                    <Col xs="6" className="pl-2">
                        <Button color="placeholder" className="w-100">
                            {t('save')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default withLocale(MeasurementByDateFormPlaceholder);
