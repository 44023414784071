import MeasurementIndexPage from 'Measurement/Index/MeasurementIndexPage';
import MeasurementEditPage from 'Measurement/Edit/MeasurementEditPage';
import MeasurementAddPage from 'Measurement/Edit/MeasurementAddPage';

const PATH_MEASUREMENT_INDEX = '/measurements';
const PATH_MEASUREMENT_ADD = '/measurements/add';
const PATH_MEASUREMENT_EDIT =
    '/measurements/edit/:date([0-9]{4}-[0-9]{2}-[0-9]{2})';

const routes = [
    {
        path: PATH_MEASUREMENT_INDEX,
        component: MeasurementIndexPage,
        private: true,
        exact: true,
        title: 'route-title/measurements',
        mobileAppTabIdentifier: 'more',
    },
    {
        path: PATH_MEASUREMENT_ADD,
        render: MeasurementAddPage,
        private: true,
        exact: false,
        title: 'route-title/measurements-add',
        mobileAppTabIdentifier: 'more',
    },
    {
        path: PATH_MEASUREMENT_EDIT,
        render: MeasurementEditPage,
        private: true,
        exact: false,
        title: 'route-title/measurements-edit',
        mobileAppTabIdentifier: 'more',
    },
    {
        path: '/measure',
        redirectTo: PATH_MEASUREMENT_INDEX,
        exact: true,
    },
    {
        path: '/measure/:whatever(.*)',
        redirectTo: PATH_MEASUREMENT_ADD,
        exact: true,
    },
];

export {
    PATH_MEASUREMENT_INDEX,
    PATH_MEASUREMENT_ADD,
    PATH_MEASUREMENT_EDIT,
    routes,
};
