import React from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as RightArrow } from 'assets/icons/chevron-right.svg';
import { PATH_REMOVE_ACCOUNT_REQUEST } from '../paths';

const RemoveAccountButton = ({ loading, t }) => {
    if (loading) {
        return '';
    }
    return (
        <Container>
            <Row>
                <Col xs="12" md={{ size: 8, offset: 2 }}>
                    <div className="white-box">
                        <h2 className="title-small semi-bold">
                            {t('settings/remove-account')}
                        </h2>
                        <Button
                            color="arrow"
                            className="w-100"
                            tag={Link}
                            to={PATH_REMOVE_ACCOUNT_REQUEST}
                        >
                            <p className="d-inline-block black">
                                {t('button/remove-account')}
                            </p>
                            <span className="SVGInline">
                                <RightArrow />
                            </span>
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

RemoveAccountButton.propTypes = {
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};

export default withLocale(RemoveAccountButton);
