import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import scrollToElement from 'components/Helpers/scrollToElement';

import InputRadio from 'components/FormElements/InputRadioControlled';
import InputNumber from 'components/FormElements/InputNumberControlled';
import { withLocale } from '@dietlabs/components';
import withStep from './StepContext/withStep';

class StepGoal extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        dietMode: PropTypes.string,
        goalWeight: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        errors: PropTypes.shape(),
        validateData: PropTypes.func.isRequired,
    };

    static defaultProps = {
        dietMode: undefined,
        errors: undefined,
    };

    inputGoalRef = React.createRef();

    componentDidMount() {
        this.checkErrors();

        if (
            this.props.dietMode === 'slimming' ||
            this.props.dietMode === 'put_on_weight'
        ) {
            setTimeout(() => {
                scrollToElement(this.inputGoalRef.current);
            }, 1500);
        }
    }

    componentDidUpdate() {
        this.checkErrors();
    }

    checkErrors = () => {
        let errors = false;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === 'goalWeight.value') {
                    errors = true;
                }
            });
        }

        this.props.changeNextBtnState(
            !(
                this.props.dietMode === 'stabilization' ||
                (this.props.dietMode && this.props.goalWeight.value && !errors)
            )
        );
    };

    render() {
        const { dietMode, goalWeight, handleInputChange, errors } = this.props;

        const optionalGoalWeightProps = {};
        if (goalWeight.value) {
            optionalGoalWeightProps.value = Number(goalWeight.value);
        }

        return (
            <div className="full-screen3">
                <div className="mb-auto w-100">
                    <FormGroup tag="fieldset">
                        <Label className="large">
                            {this.props.t('diet-settings/goal/label')}
                        </Label>

                        <InputRadio
                            label={[
                                <strong key="slimming-title">
                                    {this.props.t(
                                        'diet-settings/goal/slimming'
                                    )}
                                </strong>,
                                <p className="small" key="slimming-description">
                                    {this.props.t(
                                        'diet-settings/goal/slimming/description'
                                    )}
                                </p>,
                            ]}
                            name="dietMode"
                            value="slimming"
                            handleChange={handleInputChange}
                            checked={dietMode === 'slimming'}
                            data-test="dietMode-slimming"
                            onClick={() =>
                                scrollToElement(this.inputGoalRef.current)
                            }
                        />
                        <InputRadio
                            label={[
                                <strong key="stabilization-title">
                                    {this.props.t(
                                        'diet-settings/goal/stabilization'
                                    )}
                                </strong>,
                                <p
                                    className="small"
                                    key="stabilization-description"
                                >
                                    {this.props.t(
                                        'diet-settings/goal/stabilization/description'
                                    )}
                                </p>,
                            ]}
                            name="dietMode"
                            value="stabilization"
                            handleChange={handleInputChange}
                            checked={dietMode === 'stabilization'}
                            data-test="dietMode-stabilization"
                        />
                        <InputRadio
                            label={[
                                <strong key="put-on-weight-title">
                                    {this.props.t(
                                        'diet-settings/goal/put-on-weight'
                                    )}
                                </strong>,
                                <p
                                    className="small"
                                    key="put-on-weight-description"
                                >
                                    {this.props.t(
                                        'diet-settings/goal/put-on-weight/description'
                                    )}
                                </p>,
                            ]}
                            name="dietMode"
                            value="put_on_weight"
                            handleChange={handleInputChange}
                            checked={dietMode === 'put_on_weight'}
                            data-test="dietMode-put-on-weight"
                            onClick={() =>
                                scrollToElement(this.inputGoalRef.current)
                            }
                        />
                    </FormGroup>

                    <InputNumber
                        label={this.props.t('diet-settings/goal-weight')}
                        labelSize="large"
                        name="goalWeight.value"
                        unit={goalWeight.unit}
                        errors={errors}
                        step={0.1}
                        handleChange={handleInputChange}
                        onBlur={() => this.props.validateData()}
                        {...optionalGoalWeightProps}
                        hide={!dietMode || dietMode === 'stabilization'}
                        data-test="goal-weight-input"
                        inputWrapperRef={this.inputGoalRef}
                    />
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepGoal));
