import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import deepClone from 'utils/deepClone';
import { scrollToTop } from 'components/Helpers/scrollToTop';
import { Redirect } from 'react-router';
import { PATH_DAY_PLAN_INDEX } from 'DailyDietPlan/DayPlanPaths';
import { formatIsoDate } from 'utils/dateFormatter';
import { withLocale } from '@dietlabs/components';
import { validateFields } from 'view/Validation/validateFields';
import DateFormatRule from 'view/Validation/ValidationRules/DateFormatRule';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import { withSnackbar } from 'react-simple-snackbar';
import DietSettingsPlaceholder from './DietSettingsPlaceholder';
import DietSettings from './DietSettings';

import withDebounce from '../withDebounce';
import StepProvider from '../Steps/StepContext/StepProvider';
import StepActions from '../Steps/StepActions';

import StepStart from '../Steps/StepStart';
import StepDietId from '../Steps/StepDietId';
// eslint-disable-next-line import/extensions
import StepSexBirthdatHeight from '../Steps/StepSexBirthdatHeight';
import StepWeight from '../Steps/StepWeight';
import StepGoal from '../Steps/StepGoal';
import StepActivityLevel from '../Steps/StepActivityLevel';
import StepStartDate from '../Steps/StepStartDate';
import StepFinish from '../Steps/StepFinish';

import dietSettingsSteps from '../dietSettingsSteps';
import { dietSettingsGetNextStep } from '../utils/NextStep/DietSettingsNextStepAction';
import { dietSettingsGetPreviousStep } from '../utils/PreviousStep/DietSettingsPreviousStepAction';
import { dietSettingsGetProgress } from '../utils/Progress/DietSettingsProgressAction';

class DietSettingsIndexContainer extends React.Component {
    weightDefaultUnit =
        this.props.dietSettings.systemOfMeasures === 'imperial' ? 'lb' : 'kg';

    heightDefaultUnit =
        this.props.dietSettings.systemOfMeasures === 'imperial' ? 'in' : 'cm';

    heightDefaultValue =
        this.props.dietSettings.systemOfMeasures === 'imperial' ? 60 : 160;

    state = {
        systemOfMeasures: this.props.dietSettings.systemOfMeasures,
        currentStep: 'start',
        progress: { stepsCount: 0, currentStepPosition: 0 },
        sex: this.props.dietSettings.sex || undefined,
        dateOfBirth: this.props.dietSettings.birthDate || '1980-01-01',
        height: {
            value: this.props.dietSettings.height
                ? this.props.dietSettings.height.value
                : this.heightDefaultValue,
            unit: this.props.dietSettings.height
                ? this.props.dietSettings.height.unit
                : this.heightDefaultUnit,
        },
        weight: {
            value: this.props.dietSettings.lastMeasurement.weight[0]
                ? this.props.dietSettings.lastMeasurement.weight[0].value
                : undefined,
            unit: this.props.dietSettings.lastMeasurement.weight[0]
                ? this.props.dietSettings.lastMeasurement.weight[0].unit
                : this.weightDefaultUnit,
        },
        dietMode: this.props.dietSettings.diet.mode || undefined,
        goalWeight: {
            value:
                (this.props.dietSettings.goalWeight || {}).value || undefined,
            unit:
                (this.props.dietSettings.goalWeight || {}).unit ||
                this.weightDefaultUnit,
        },
        activityLevel: this.props.dietSettings.diet.activityLevel || undefined,
        dietId: this.props.dietSettings.diet.id || undefined,
        dietType: this.props.dietSettings.diet.dietType || undefined,
        startDate: formatIsoDate(new Date()),
        isNextButtonDisabled: false,
        errors: undefined,
        redirect: false,
        redirectTrainings: false,
        generateDietError: false,
        preload: false,
        preloadNextStep: false,
    };

    validationRules = {
        dateOfBirth: [
            new RequiredRule({ translator: this.props.t }),
            new DateFormatRule({ translator: this.props.t }),
        ],
        startDate: [
            new RequiredRule({ translator: this.props.t }),
            new DateFormatRule({ translator: this.props.t }),
        ],
        weight: [new RequiredRule({ translator: this.props.t })],
        height: [new RequiredRule({ translator: this.props.t })],
        goalWeight: [new RequiredRule({ translator: this.props.t })],
        sex: [new RequiredRule({ translator: this.props.t })],
    };

    static propTypes = {
        dietSettings: PropTypes.shape().isRequired,
        validateData: PropTypes.func.isRequired,
        generateDiet: PropTypes.func.isRequired,
        debounce: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        openSnackbar: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.updateProgressBar(this.state.currentStep);
    }

    handleInputChange = event => {
        const fieldName = event.target.name.split('.')[0];
        const targetValue = event.target.value;
        const valInt = parseInt(targetValue, 10) || 0;
        const valFloat = Math.round(parseFloat(targetValue) * 10) / 10 || 0;
        if (fieldName === 'weight' || fieldName === 'goalWeight') {
            this.setState(prevState => ({
                [fieldName]: {
                    ...prevState[fieldName],
                    value: valFloat,
                },
            }));
        } else if (fieldName === 'height') {
            this.setState(prevState => ({
                [fieldName]: {
                    ...prevState[fieldName],
                    value: valInt,
                },
            }));
        } else if (fieldName === 'dietId') {
            this.setState({
                [fieldName]: valInt,
            });
        } else if (
            (fieldName === 'dateOfBirth' || fieldName === 'startDate') &&
            targetValue === ''
        ) {
            this.setState({
                [fieldName]: undefined,
            });
        } else {
            this.setState({ [fieldName]: targetValue });
        }
        const validableFields = [
            'dateOfBirth',
            'weight',
            'height',
            'goalWeight',
            'startDate',
        ];
        validableFields.forEach(field => {
            if (field === fieldName) {
                this.props.debounce(this.validateData, 1000);
            }
        });
    };

    createRequest() {
        const request = {
            sex: this.state.sex,
            weight: this.state.weight.value ? this.state.weight : undefined,
            height: this.state.height.value ? this.state.height : undefined,
            dateOfBirth: this.state.dateOfBirth,
            dietMode: this.state.dietMode,
            goalWeight: this.state.goalWeight.value
                ? this.state.goalWeight
                : undefined,
            dietId: this.state.dietId,
            dietType: this.state.dietType,
            activityLevel: this.state.activityLevel,
            startDate: this.state.startDate,
        };

        if (request.dietMode === 'stabilization') {
            request.goalWeight = deepClone(request.weight);
        }
        return request;
    }

    validateData = async () => {
        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        if (frontEndErrors.length === 0) {
            const request = this.createRequest();
            const response = await this.props.validateData(request);

            if (response.data.me.validateDietSettings.code !== 200) {
                this.setState({
                    errors: response.data.me.validateDietSettings,
                });
                return response.data.me.validateDietSettings;
            }
        }

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        return this.state.errors;
    };

    generateDiet = async () => {
        // reset generate diet error
        this.setState({ generateDietError: false });
        const request = this.createRequest();
        this.setState({ preload: true });

        try {
            const response = await this.props.generateDiet(request);
            const { code } = response.data.me.dietGenerate;
            this.setState({ preload: false });
            if (code === 200) {
                // set sex for trsanslator
                global.localStorage.setItem('sex', this.state.sex);
                this.setState({ redirect: true });
                this.props.openSnackbar(
                    <p>{this.props.t('diet-settings/changed')}</p>
                );
            } else {
                this.setState({ generateDietError: true });
                throw new Error(
                    `Failed to generate diet, got status code ${code}`
                );
            }
        } catch (e) {
            this.setState({ generateDietError: true });
            throw new Error(`Failed to generate diet, got error: ${e}`);
        }
    };

    animate = (action, duration) => {
        const wrapper = document.getElementById('wrapper');
        wrapper.classList.add('animated', 'animated-fast', 'fadeOut');

        setTimeout(
            () => wrapper.classList.remove(...wrapper.classList),
            duration
        );
        setTimeout(() => wrapper.classList.add('hide'), duration);
        setTimeout(() => action(), duration);
        setTimeout(() => wrapper.classList.add('animated', 'fadeIn'), duration);
    };

    prevStep = () => {
        const state = {
            ...this.props.dietSettings,
            dietId: this.state.dietId,
        };

        const prevStep = dietSettingsGetPreviousStep(
            dietSettingsSteps,
            state,
            this.state.currentStep
        );
        this.animate(() => this.changeStep(prevStep), 200);
    };

    nextStep = async () => {
        let errors = false;

        if (this.state.currentStep === 'sex-birthday-height') {
            this.setState({ preloadNextStep: true });
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (
                    error.fieldName === 'sex' ||
                    error.fieldName === 'dateOfBirth' ||
                    error.fieldName === 'height.value'
                ) {
                    errors = true;
                }
            });
        } else if (this.state.currentStep === 'weight') {
            this.setState({ preloadNextStep: true });
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (error.fieldName === 'weight.value') {
                    errors = true;
                }
            });
        } else if (this.state.currentStep === 'goal') {
            this.setState({ preloadNextStep: true });
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (error.fieldName === 'goalWeight.value') {
                    errors = true;
                }
            });
        } else if (this.state.currentStep === 'start-date') {
            this.setState({ preloadNextStep: true });
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (error.fieldName === 'startDate') {
                    errors = true;
                }
            });
        }

        if (!errors) {
            this.setState({ errors: undefined });

            const state = {
                ...this.props.dietSettings,
                dietId: this.state.dietId,
            };

            const nextStep = dietSettingsGetNextStep(
                dietSettingsSteps,
                state,
                // eslint-disable-next-line react/no-access-state-in-setstate
                this.state.currentStep
            );

            this.setState({ preloadNextStep: false });

            if (!nextStep) {
                this.generateDiet();
            } else {
                this.animate(() => this.changeStep(nextStep), 200);
            }
        } else {
            this.setState({ preloadNextStep: false });
        }
    };

    setDefaultActivityLevel = () => {
        this.setState({ activityLevel: 'ACTIVITY_LEVEL_NONE' });
    };

    changeStep = nextStep => {
        scrollToTop();
        this.setState({
            currentStep: nextStep,
        });
        this.updateProgressBar(nextStep);
    };

    updateProgressBar = nextStep => {
        const state = {
            ...this.props.dietSettings,
            dietId: this.state.dietId,
        };

        const progress = dietSettingsGetProgress(
            dietSettingsSteps,
            state,
            nextStep
        );

        if (this.state.progress !== progress) {
            this.setState({ progress });
        }
    };

    changeNextBtnState = isDisable => {
        if (this.state.isNextButtonDisabled !== isDisable) {
            this.setState({ isNextButtonDisabled: isDisable });
        }
    };

    renderStep = currentStep => {
        switch (currentStep) {
            case 'start':
                return (
                    <StepStart
                        systemOfMeasures={this.state.systemOfMeasures}
                        dietId={this.state.dietId}
                        weight={this.state.weight}
                        dietMode={this.state.dietMode}
                        goalWeight={this.state.goalWeight}
                        activityLevel={this.state.activityLevel}
                        sex={this.state.sex}
                        dateOfBirth={this.state.dateOfBirth}
                        height={this.state.height}
                    />
                );
            case 'diet-id':
                return <StepDietId dietId={this.state.dietId} />;
            case 'sex-birthday-height':
                return (
                    <StepSexBirthdatHeight
                        sex={this.state.sex}
                        systemOfMeasures={this.state.systemOfMeasures}
                        height={this.state.height}
                        dateOfBirth={this.state.dateOfBirth}
                        errors={this.state.errors}
                        validateData={this.validateData}
                    />
                );
            case 'weight':
                return (
                    <StepWeight
                        weight={this.state.weight}
                        errors={this.state.errors}
                        validateData={this.validateData}
                    />
                );
            case 'goal':
                return (
                    <StepGoal
                        dietMode={this.state.dietMode}
                        goalWeight={this.state.goalWeight}
                        errors={this.state.errors}
                        validateData={this.validateData}
                    />
                );
            case 'activity-level':
                return (
                    <StepActivityLevel
                        activityLevel={this.state.activityLevel}
                    />
                );
            case 'start-date':
                return (
                    <StepStartDate
                        startDate={this.state.startDate}
                        errors={this.state.errors}
                        validateData={this.validateData}
                    />
                );
            case 'finish':
                return (
                    <StepFinish
                        generateDiet={this.generateDiet}
                        generateDietError={this.state.generateDietError}
                    />
                );

            default:
                return <StepStart />;
        }
    };

    renderAction = () => (
        <StepProvider
            prevStep={() => this.prevStep}
            nextStep={() => this.nextStep}
            handleInputChange={this.handleInputChange}
            changeNextBtnState={this.changeNextBtnState}
            preload={this.state.preloadNextStep}
        >
            <StepActions
                isNextButtonDisabled={this.state.isNextButtonDisabled}
                currentStep={this.state.currentStep}
            />
        </StepProvider>
    );

    render() {
        if (this.state.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: `${PATH_DAY_PLAN_INDEX}/${formatIsoDate(
                            new Date()
                        )}`,
                    }}
                    targetTab="diet"
                />
            );
        }

        return (
            <React.Fragment>
                <StepProvider
                    prevStep={() => this.prevStep}
                    nextStep={() => this.nextStep}
                    handleInputChange={this.handleInputChange}
                    changeNextBtnState={this.changeNextBtnState}
                    preload={this.state.preloadNextStep}
                >
                    <DietSettings
                        progress={this.state.progress}
                        renderAction={this.renderAction}
                    >
                        {() => (
                            <div id="wrapper" className="wrapper">
                                {this.renderStep(this.state.currentStep)}
                            </div>
                        )}
                    </DietSettings>
                </StepProvider>
            </React.Fragment>
        );
    }
}

export default withDebounce(
    withLoading(
        withSnackbar(withLocale(DietSettingsIndexContainer)),
        DietSettingsPlaceholder
    )
);
