/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Button, Modal, ModalBody } from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled';
import { withLocale } from '@dietlabs/components';
import helpIcon from 'assets/icons/help.svg';
import closeIcon from 'assets/icons/close.svg';
import { DIET_ID_CLASSIC, DIET_ID_VEGE } from 'consts.js';
import withStep from './StepContext/withStep';

class StepDietId extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        dietId: PropTypes.number,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
    };

    static defaultProps = {
        dietId: null,
    };

    state = {
        showModal: false,
    };

    toggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
        }));
    };

    componentDidMount() {
        this.props.changeNextBtnState(!this.props.dietId);
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.dietId);
    }

    render() {
        const { dietId, handleInputChange } = this.props;

        return (
            <div className="full-screen3">
                <div className="mb-auto w-100">
                    <FormGroup tag="fieldset">
                        <Label className="large text-prewrap">
                            {this.props.t('diet-settings/diet-id/label')}
                            <Button
                                color="link"
                                onClick={this.toggle}
                                className="ml-2 p-0"
                            >
                                <img src={helpIcon} alt="" />
                            </Button>
                        </Label>

                        <Modal
                            isOpen={this.state.showModal}
                            toggle={this.toggle}
                            centered
                            size="lg"
                        >
                            <ModalBody>
                                <Button
                                    color="link"
                                    onClick={this.toggle}
                                    className="close"
                                >
                                    <img src={closeIcon} alt="" />
                                </Button>
                                <h3 className="title-medium semi-bold">
                                    Classic Zone
                                </h3>
                                <p>
                                    If you are looking for tasty, healthy and
                                    varied meals, then this meal plan will be
                                    just right for you! It comprises simple but
                                    well-balanced menus with all food groups,
                                    including eggs, dairy products, meat, and
                                    fish. Whole grain products and vegetables
                                    provide fiber that will keep you feeling
                                    full.
                                </p>

                                <h3 className="title-medium semi-bold">
                                    Healthy Vegetarian
                                </h3>
                                <p>
                                    If you avoid meat and want to have a
                                    balanced vegetarian diet, you must try this
                                    plan! You’ll see that you can still have
                                    tasty and healthy meals, even without meat
                                    or fish. You will find here a lot of plant
                                    products, but also dairy and eggs.
                                </p>
                            </ModalBody>
                        </Modal>

                        <InputRadio
                            label={[
                                <strong key="1">
                                    {this.props.t(
                                        'diet-settings/diet-type/classic'
                                    )}
                                </strong>,
                            ]}
                            name="dietId"
                            value={DIET_ID_CLASSIC}
                            handleChange={handleInputChange}
                            checked={dietId === DIET_ID_CLASSIC}
                        />

                        <InputRadio
                            label={[
                                <strong key="2">
                                    {this.props.t(
                                        'diet-settings/diet-type/vege'
                                    )}
                                </strong>,
                            ]}
                            name="dietId"
                            value={DIET_ID_VEGE}
                            handleChange={handleInputChange}
                            checked={dietId === DIET_ID_VEGE}
                        />
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepDietId));
