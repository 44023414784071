import React from 'react';
import Layout from 'Layout/Layout';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Container } from 'reactstrap';

class DietSettings extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
    };

    render() {
        const { t, children } = this.props;

        return (
            <Layout page="make-decision">
                <Container className="text-center">
                    <header>
                        <h1 className="title-medium semi-bold">
                            {t('make-decision/title')}
                        </h1>
                    </header>
                    <section className="pt-0 pb-5">{children()}</section>
                </Container>
            </Layout>
        );
    }
}

export default withLocale(DietSettings);
