/* eslint-disable eqeqeq */
import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import deepClone from 'utils/deepClone';
import { withLocale } from '@dietlabs/components';
import { Container, Row, Col, Collapse, Button, Modal } from 'reactstrap';
import { ReactComponent as DownArrow } from 'assets/icons/chevron-down.svg';
import achievementBg from 'assets/images/achievements/bg_animation.png';
import { formatMediumDate } from 'utils/dateFormatter';
import AchievementsPlaceholder from './AchievementsPlaceholder';

const DATA_SOURCE = {
    WORKOUTS_DONE: 'WORKOUTS_DONE',
    IF_DONE: 'IF_DONE',
    HYDRATION_GOALS_ACHIEVED: 'HYDRATION_GOALS_ACHIEVED',
};

const getIndexOfitemInArray = (array, item) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < array.length; i++) {
        if (array[i][0].id === item[0].id) {
            return i;
        }
    }
    return null;
};

class Achievements extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        achievements: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                isAchived: PropTypes.bool,
                name: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                thumbnailUrl: PropTypes.string.isRequired,
                imageUrl: PropTypes.string.isRequired,
                userAchievement: PropTypes.arrayOf(
                    PropTypes.shape({
                        createdAt: PropTypes.string,
                    })
                ),
            })
        ).isRequired,
    };

    state = {
        groupedAchievements: {},
        collapse: {},
        pickedAchievement: null,
    };

    componentDidMount() {
        this.setState({ groupedAchievements: this.groupByCategories() });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.groupedAchievements !== this.state.groupedAchievements) {
            Object.entries(this.state.groupedAchievements).forEach(
                ([, subCategories]) => {
                    Object.entries(subCategories).forEach(([subCategory]) => {
                        // eslint-disable-next-line no-shadow
                        this.setState(prevState => ({
                            collapse: {
                                ...prevState.collapse,
                                [subCategory]: false,
                            },
                        }));
                    });
                }
            );
        }
    }

    // eslint-disable-next-line class-methods-use-this
    lastRevealedAchievement(achievements) {
        let referenceDate = new Date('2000-01-01');
        let lastRevealedAchievement = achievements[0].id;

        achievements.forEach(achievement => {
            if (achievement.isAchieved) {
                const date = new Date(achievement.userAchievement[0].createdAt);

                if (date >= referenceDate) {
                    referenceDate = date;
                    lastRevealedAchievement = achievement.id;
                }
            }
        });
        return lastRevealedAchievement;
    }

    groupByCategories() {
        const groupedMainCategory = this.groupBy(
            this.props.achievements,
            'groupIdentifier'
        );

        const groupedWithSubCategory = {};

        Object.entries(groupedMainCategory).forEach(([key, value]) => {
            groupedWithSubCategory[key] = this.groupBy(
                value,
                'rule',
                'datasource'
            );
        });

        // Mock achievements

        // groupedWithSubCategory['workouts-done'].WORKOUTS_DONE.forEach(
        //     (item, index) => {
        //         if (index < 21) {
        //             // eslint-disable-next-line no-param-reassign
        //             item.isAchieved = true;
        //             if (index < 14) {
        //                 // eslint-disable-next-line no-param-reassign
        //                 item.userAchievement = [
        //                     {
        //                         createdAt: '2022-05-12T15:37:00+02:00',
        //                         __typename: 'UserAchievement',
        //                     },
        //                 ];
        //             } else {
        //                 // eslint-disable-next-line no-param-reassign
        //                 item.userAchievement = [
        //                     {
        //                         createdAt: '2022-06-12T15:37:00+02:00',
        //                         __typename: 'UserAchievement',
        //                     },
        //                 ];
        //             }
        //         }
        //     }
        // );

        const achievementsSortedBySpecificValue = Object.keys(
            groupedWithSubCategory
        )

            .sort((a, b) => {
                const achievementsOrder = [
                    'workouts-done',
                    'hydration-done',
                    'if-done',
                ];

                const aAchievementIndex = achievementsOrder.indexOf(a);
                const bAchievementIndex = achievementsOrder.indexOf(b);

                if (aAchievementIndex === bAchievementIndex) return a - b;

                return aAchievementIndex - bAchievementIndex;
            })
            .reduce((accumulator, key) => {
                accumulator[key] = groupedWithSubCategory[key];

                return accumulator;
            }, {});

        return achievementsSortedBySpecificValue;
    }

    groupBy = (array, key, key2) => {
        const list = {};

        if (key2) {
            // eslint-disable-next-line array-callback-return
            array.map(el => {
                list[el[key][key2]] = array.filter(
                    e => e[key][key2] === el[key][key2]
                );
            });
        } else {
            // eslint-disable-next-line array-callback-return
            array.map(el => {
                list[el[key]] = array.filter(e => e[key] === el[key]);
            });
        }

        return list;
    };

    chunkArray = (array, perChunk) => {
        const result = array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk);

            if (!resultArray[chunkIndex]) {
                // eslint-disable-next-line no-param-reassign
                resultArray[chunkIndex] = []; // start a new chunk
            }

            resultArray[chunkIndex].push(item);

            return resultArray;
        }, []);

        return result;
    };

    toggle(collapseCategory) {
        this.setState(prevState => ({
            collapse: {
                ...prevState.collapse,
                [collapseCategory]: !prevState.collapse[collapseCategory],
            },
        }));
    }

    handleModal(achievement) {
        if (!this.state.pickedAchievement) {
            this.setState({ pickedAchievement: achievement });
        } else {
            this.setState({ pickedAchievement: null });
        }
    }

    getFirstsElementsOfArray = (array, numberOfFirstElemements) => {
        return array.slice(0, numberOfFirstElemements);
    };

    render() {
        const renderCategoryTitle = category => {
            if (category === 'workouts-done') {
                return this.props.t('achievements/category/trainings');
            }
            if (category === 'if-done') {
                return this.props.t('achievements/category/fasting');
            }
            if (category === 'hydration-done') {
                return this.props.t('achievements/category/hydration');
            }

            return '';
        };

        const renderSubCategoryTitle = subCategory => {
            if (subCategory === DATA_SOURCE.WORKOUTS_DONE) {
                return this.props.t('achievements/title/trainings');
            }

            if (subCategory === DATA_SOURCE.HYDRATION_GOALS_ACHIEVED) {
                return this.props.t('achievements/title/hydration');
            }

            if (subCategory === DATA_SOURCE.IF_DONE) {
                return this.props.t('achievements/title/fasting');
            }

            return '';
        };

        const renderPrevAchievements = array => {
            return array.map(item => {
                return item.map(achievement => {
                    return (
                        <Col
                            xs="4"
                            key={achievement.id}
                            className={
                                achievement.isAchieved
                                    ? 'achievement is-achieved'
                                    : 'achievement'
                            }
                            onClick={() => this.handleModal(achievement)}
                        >
                            <img
                                src={achievement.thumbnailUrl}
                                className="img-fluid"
                                alt={achievement.name}
                            />
                        </Col>
                    );
                });
            });
        };

        const renderFirstsAchievements = (
            achievements,
            firstElemenetsToRender
        ) => {
            const firstAchievements = this.getFirstsElementsOfArray(
                achievements,
                firstElemenetsToRender
            );

            return firstAchievements.map(achievement => {
                return (
                    <Col
                        xs="4"
                        key={achievement.id}
                        className={
                            achievement.isAchieved
                                ? 'achievement is-achieved'
                                : 'achievement'
                        }
                        onClick={() => this.handleModal(achievement)}
                    >
                        <img
                            src={achievement.thumbnailUrl}
                            className="img-fluid"
                            alt={achievement.name}
                        />
                    </Col>
                );
            });
        };

        const getAchievementsToRenders = (
            chunkedArray,
            rowWithLastAchievements,
            indexOfLastAchievements
        ) => {
            if (indexOfLastAchievements % 2 !== 0) {
                if (
                    rowWithLastAchievements[rowWithLastAchievements.length - 1]
                        .isAchieved
                ) {
                    const nextRow = chunkedArray[indexOfLastAchievements + 1];
                    const prevAchievements = chunkedArray.slice(
                        0,
                        indexOfLastAchievements
                    );
                    const nextAchievements = chunkedArray.slice(
                        indexOfLastAchievements + 2
                    );
                    const firstRenderArray = [
                        ...rowWithLastAchievements,
                        ...nextRow,
                    ];
                    return {
                        prevAchievements,
                        firstRenderArray,
                        nextAchievements,
                    };
                }
                const prevAchievements = chunkedArray.slice(
                    0,
                    indexOfLastAchievements - 1
                );
                const nextAchievements = chunkedArray.slice(
                    indexOfLastAchievements + 1
                );

                const prevRow = chunkedArray[indexOfLastAchievements - 1];
                const firstRenderArray = [
                    ...prevRow,
                    ...rowWithLastAchievements,
                ];

                return { prevAchievements, firstRenderArray, nextAchievements };
            }
            if (indexOfLastAchievements === 0) {
                const prevAchievements = [];
                const nextAchievements = chunkedArray.slice(
                    indexOfLastAchievements + 2
                );
                const nextRow = chunkedArray[indexOfLastAchievements + 1];
                const firstRenderArray = [
                    ...rowWithLastAchievements,
                    ...nextRow,
                ];

                return { prevAchievements, firstRenderArray, nextAchievements };
            }
            if (indexOfLastAchievements === chunkedArray.length - 1) {
                const prevRow = chunkedArray[indexOfLastAchievements - 1];
                const nextAchievements = [];
                const prevAchievements = chunkedArray.slice(
                    0,
                    indexOfLastAchievements - 1
                );
                const firstRenderArray = [
                    ...prevRow,
                    ...rowWithLastAchievements,
                ];
                return { prevAchievements, firstRenderArray, nextAchievements };
            }
            const nextRow = chunkedArray[indexOfLastAchievements + 1];
            const prevAchievements = chunkedArray.slice(
                0,
                indexOfLastAchievements
            );
            const nextAchievements = chunkedArray.slice(
                indexOfLastAchievements + 2
            );

            const firstRenderArray = [...rowWithLastAchievements, ...nextRow];

            return { prevAchievements, firstRenderArray, nextAchievements };
        };

        return (
            <Layout page="achievements">
                <Container>
                    <Modal
                        isOpen={Boolean(this.state.pickedAchievement)}
                        toggle={() => this.handleModal()}
                        centered
                    >
                        {this.state.pickedAchievement ? (
                            <section
                                className={
                                    this.state.pickedAchievement.isAchieved
                                        ? 'featured-achievement is-achieved'
                                        : 'featured-achievement'
                                }
                            >
                                <Container>
                                    {this.state.pickedAchievement
                                        .isAchieved && (
                                        <img
                                            src={achievementBg}
                                            className="bg"
                                            alt=""
                                        />
                                    )}

                                    <img
                                        src={
                                            this.state.pickedAchievement
                                                .imageUrl
                                        }
                                        className="img-fluid"
                                        alt={this.state.pickedAchievement.name}
                                    />
                                    <span className="title-small d-block position-relative semi-bold">
                                        {this.state.pickedAchievement.name}
                                    </span>
                                    <span className="position-relative title-extra-small paragraph-text d-block mt-3">
                                        {this.state.pickedAchievement.isAchieved
                                            ? formatMediumDate(
                                                  new Date(
                                                      this.state.pickedAchievement.userAchievement[0].createdAt
                                                  )
                                              )
                                            : this.props.t(
                                                  'achievements/not-yet-achieved'
                                              )}
                                    </span>
                                    <Row className="justify-content-center">
                                        <Col xs="12" md="10">
                                            <Button
                                                className="mt-4 w-100"
                                                color="primary"
                                                onClick={() =>
                                                    this.handleModal()
                                                }
                                            >
                                                {this.props.t('ok')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        ) : (
                            ''
                        )}
                    </Modal>
                    <section className="pt-0">
                        {Object.entries(this.state.groupedAchievements).map(
                            ([category, subCategories]) => {
                                const categoryTitle = renderCategoryTitle(
                                    category
                                );
                                return (
                                    <div
                                        className="achieve-wrapper"
                                        key={category}
                                    >
                                        <h2 className="title-small semi-bold">
                                            {categoryTitle}
                                        </h2>

                                        {Object.entries(subCategories).map(
                                            ([subCategory, achievements]) => {
                                                const subCategoryTitle = renderSubCategoryTitle(
                                                    subCategory
                                                );

                                                const lastRevealedId = this.lastRevealedAchievement(
                                                    achievements
                                                );

                                                const chunkedArray = deepClone(
                                                    this.chunkArray(
                                                        achievements,
                                                        3
                                                    )
                                                );

                                                const rowWithLastAchievements = deepClone(
                                                    chunkedArray.find(array =>
                                                        array.find(
                                                            item =>
                                                                item.id ===
                                                                lastRevealedId
                                                        )
                                                    )
                                                );

                                                const indexOfLastAchievements = getIndexOfitemInArray(
                                                    chunkedArray,
                                                    rowWithLastAchievements
                                                );

                                                const {
                                                    prevAchievements,
                                                    firstRenderArray,
                                                    nextAchievements,
                                                } = getAchievementsToRenders(
                                                    chunkedArray,
                                                    rowWithLastAchievements,
                                                    indexOfLastAchievements
                                                );

                                                return (
                                                    <div
                                                        className="achieve-box mb-5"
                                                        key={subCategoryTitle}
                                                    >
                                                        <h3 className="text-center title-extra-small semi-bold">
                                                            {subCategoryTitle}
                                                        </h3>
                                                        <Row>
                                                            <Collapse
                                                                isOpen={
                                                                    this.state
                                                                        .collapse[
                                                                        subCategory
                                                                    ]
                                                                }
                                                                className="w-100"
                                                            >
                                                                <Row className="w-100 m-0">
                                                                    {renderPrevAchievements(
                                                                        prevAchievements
                                                                    )}
                                                                </Row>
                                                            </Collapse>
                                                            {renderFirstsAchievements(
                                                                firstRenderArray
                                                            )}
                                                            <Collapse
                                                                isOpen={
                                                                    this.state
                                                                        .collapse[
                                                                        subCategory
                                                                    ]
                                                                }
                                                                className="w-100"
                                                            >
                                                                <Row className="w-100 m-0">
                                                                    {renderPrevAchievements(
                                                                        nextAchievements
                                                                    )}
                                                                </Row>
                                                            </Collapse>
                                                        </Row>
                                                        {achievements.length >
                                                            6 && (
                                                            <div className="text-center">
                                                                <Button
                                                                    color="text"
                                                                    onClick={() =>
                                                                        this.toggle(
                                                                            subCategory
                                                                        )
                                                                    }
                                                                >
                                                                    {this.state
                                                                        .collapse[
                                                                        subCategory
                                                                    ]
                                                                        ? 'Hide'
                                                                        : 'Show all'}
                                                                    <span
                                                                        className={
                                                                            this
                                                                                .state
                                                                                .collapse[
                                                                                subCategory
                                                                            ]
                                                                                ? 'dropdown-arrow ml-2 up'
                                                                                : 'dropdown-arrow ml-2'
                                                                        }
                                                                    >
                                                                        <span className="SVGInline">
                                                                            <DownArrow />
                                                                        </span>
                                                                    </span>
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                );
                            }
                        )}
                    </section>
                </Container>
            </Layout>
        );
    }
}

export default withLoading(withLocale(Achievements), AchievementsPlaceholder);
