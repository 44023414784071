import React, { Component } from 'react';
import { Container, Row, Col, Button, Alert } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { Link } from 'react-router-dom';
import { PATHS } from 'config/paths';
import { PATH_MEASUREMENT_ADD } from 'Measurement/routes';
import { PATH_DIET_SETTINGS_INDEX } from 'DietSettings/routes';
import { formatIsoDate } from 'utils/dateFormatter';
import { getHolidayIconName } from 'DailyDietPlan/utils/getHolidayIconName';
import withGTM from 'view/GoogleTagManager/withGTM';
// import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { CSSTransition } from 'react-transition-group';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';
import Footer from 'Layout/Footer';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { PATH_DAY_PLAN_INDEX } from '../../DayPlanPaths';

class DayPlan extends Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date).isRequired,
        kcal: PropTypes.number.isRequired,
        // phase: PropTypes.shape({
        //     identifier: PropTypes.string.isRequired,
        //     name: PropTypes.string.isRequired,
        // }).isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        renderTimeline: PropTypes.func.isRequired,
        availableDays: PropTypes.arrayOf(PropTypes.object.isRequired)
            .isRequired,
        goal: PropTypes.shape({
            reachedBecauseOfLoseWeight: PropTypes.bool.isRequired,
            reachedBecauseOfPutOnWeight: PropTypes.bool.isRequired,
            lostBecauseOfLoseWeight: PropTypes.bool.isRequired,
            lostBecauseOfPutOnWeight: PropTypes.bool.isRequired,
        }).isRequired,
        isHolidayMenu: PropTypes.bool.isRequired,
        isTimeToNagForCurrentMeasurement: PropTypes.bool.isRequired,
        // trackEvent: PropTypes.func.isRequired,
        recipeNotesForNextDay: PropTypes.arrayOf(PropTypes.string).isRequired,
        renderMealLinks: PropTypes.func.isRequired,
        macro: PropTypes.shape({
            animalProtein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            carbohydrates: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            fat: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            protein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
        // fastingStatistics: PropTypes.shape({
        //     dietsCount: PropTypes.number,
        //     currentDietLength: PropTypes.number,
        //     currentDietProgress: PropTypes.number,
        // }).isRequired,

        // mobileApp: PropTypes.shape({
        //     active: PropTypes.bool,
        //     os: PropTypes.string,
        //     version: PropTypes.string,
        // }).isRequired,

        showModal: PropTypes.bool.isRequired,
        renderMealDetails: PropTypes.func.isRequired,

        showModalWithReplacements: PropTypes.bool.isRequired,
        renderMealReplacementsList: PropTypes.func.isRequired,
        renderChangeDay: PropTypes.func.isRequired,
        toggleModalWithDayReplacements: PropTypes.func.isRequired,
        showModalWithDayReplacements: PropTypes.bool.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        dietId: PropTypes.number.isRequired,
    };

    state = {
        showDietIsReadyInfo: global.localStorage.getItem('dietIsReady'),
    };

    componentDidMount() {
        // this.trackView();

        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'V_Dayplan',
        });
    }

    componentDidUpdate() {
        // if (formatIsoDate(prevProps.date) !== formatIsoDate(this.props.date)) {
        //     this.trackView();
        // }
    }

    // to do: ask is this necessary
    // trackView() {
    //     const now = new Date();
    //     const today = new Date(
    //         now.getFullYear(),
    //         now.getMonth(),
    //         now.getDate()
    //     );

    //     const dayDelta = Math.floor(
    //         (this.props.date - today) / 1000 / 60 / 60 / 24
    //     );

    //     const label = dayDelta > 0 ? `+${dayDelta}` : dayDelta;
    //     this.props.trackEvent(EVENT_CATEGORY_DIET_PLAN, 'view', label);
    // }

    renderDayPlanReadyAlert() {
        if (this.state.showDietIsReadyInfo) {
            return (
                <Alert color="info">
                    <h4>{this.props.t('diet-is-ready/title')}</h4>
                    <p>{this.props.t('diet-is-ready/content')}</p>
                    <Button
                        color="primary"
                        size="sm"
                        className="mr-3 px-5"
                        onClick={() => {
                            global.localStorage.removeItem('dietIsReady');
                            this.setState({ showDietIsReadyInfo: false });
                        }}
                    >
                        {this.props.t('ok')}
                    </Button>
                    <Button
                        color="link"
                        size="sm"
                        onClick={() => {
                            global.localStorage.removeItem('dietIsReady');
                            this.setState({ showDietIsReadyInfo: false });
                        }}
                        tag={Link}
                        to={PATH_DIET_SETTINGS_INDEX}
                    >
                        {this.props.t('diet-is-ready/change-settings')}
                    </Button>
                </Alert>
            );
        }

        return '';
    }

    renderGoalAlert() {
        if (this.props.goal) {
            if (
                this.props.goal.lostBecauseOfLoseWeight ||
                this.props.goal.lostBecauseOfPutOnWeight
            ) {
                return (
                    <Alert color="danger" data-test="goal-lost">
                        <h4>{this.props.t('info-pages/goal-lost/title')}</h4>
                        <p className="pink mb-3">
                            {this.props.t('info-pages/goal-lost/content')}
                        </p>
                        <Button
                            color="primary"
                            size="sm"
                            tag={Link}
                            to={PATHS.MAKE_DECISION}
                        >
                            {this.props.t('info-pages/goal-lost/button')}
                        </Button>
                    </Alert>
                );
            }

            if (
                this.props.goal.reachedBecauseOfLoseWeight ||
                this.props.goal.reachedBecauseOfPutOnWeight
            ) {
                return (
                    <Alert color="info" data-test="goal-reached">
                        <h4>{this.props.t('info-pages/goal-reached/title')}</h4>
                        <p className="navy mb-3">
                            {this.props.t('info-pages/goal-reached/content')}
                        </p>
                        <Button
                            color="primary"
                            size="sm"
                            tag={Link}
                            to={PATHS.MAKE_DECISION}
                        >
                            {this.props.t('info-pages/goal-reached/button')}
                        </Button>
                    </Alert>
                );
            }
        }

        return '';
    }

    renderMeasurementAlert() {
        if (this.props.isTimeToNagForCurrentMeasurement) {
            return (
                <Alert color="info">
                    <p>
                        {this.props.t('alert/periodic-measurements-1')}
                        <br />
                        <br />
                        {this.props.t('alert/periodic-measurements-2')}
                    </p>
                    <Button
                        color="primary"
                        size="sm"
                        tag={Link}
                        to={{
                            pathname: PATH_MEASUREMENT_ADD,
                            state: {
                                from: {
                                    pathname: `${PATH_DAY_PLAN_INDEX}/${formatIsoDate(
                                        this.props.date
                                    )}`,
                                },
                            },
                        }}
                    >
                        {this.props.t(
                            'info-pages/current-weight-needed/button'
                        )}
                    </Button>
                </Alert>
            );
        }

        return '';
    }

    renderHolidayAlert() {
        const isThisHoliday = getHolidayIconName(
            this.props.availableDays,
            formatIsoDate(this.props.date)
        );

        if (isThisHoliday && !this.props.isHolidayMenu) {
            return (
                <Alert color="info mt-3">
                    <p className="navy mb-3">
                        {this.props.t('holiday-notification')}
                    </p>
                    <Button
                        color="primary"
                        size="sm"
                        onClick={() =>
                            this.props.toggleModalWithDayReplacements()
                        }
                    >
                        {this.props.t('alert/holiday-notification/button')}
                    </Button>
                </Alert>
            );
        }

        return '';
    }

    renderTomorrowAlert() {
        let tomorrowAlert = [];

        if (
            this.props.recipeNotesForNextDay &&
            Object.keys(this.props.recipeNotesForNextDay).length !== 0
        ) {
            tomorrowAlert = [...this.props.recipeNotesForNextDay];
        }

        const uniqTomorrowAlert = [...new Set(tomorrowAlert)];

        if (uniqTomorrowAlert.length > 0) {
            return (
                <Alert color="success" data-test="tomorrow-alert">
                    <h4>{this.props.t('next-day-notification')}</h4>
                    <ul>
                        {uniqTomorrowAlert.map((alert, idx, arr) => (
                            <li key={arr.indexOf(alert)}>{alert}</li>
                        ))}
                    </ul>
                </Alert>
            );
        }

        return '';
    }

    renderDietType() {
        let dietType = '';
        switch (this.props.dietId) {
            case 3:
                dietType = 'diet-type/classic';
                break;
            case 4:
                dietType = 'diet-type/veggie';
                break;
            case 5:
                dietType = 'diet-type/vegan';
                break;
            default:
                dietType = '';
        }

        return (
            <div className="dietType-container">
                <span className="dietType">
                    Meal Plan: <strong>{this.props.t(dietType)}</strong>
                </span>
            </div>
        );
    }

    render() {
        return (
            <Layout page="diet-new">
                <div
                    className={
                        this.props.showModal ||
                        this.props.showModalWithReplacements
                            ? 'modalContainer showModal'
                            : 'modalContainer'
                    }
                >
                    {this.props.renderTimeline('top')}

                    <header>
                        <Container className="text-center">
                            <Row>
                                <Col lg={{ size: 8, offset: 2 }}>
                                    {this.renderDayPlanReadyAlert()}
                                    {this.renderGoalAlert()}
                                    {this.renderMeasurementAlert()}
                                    {this.renderTomorrowAlert()}

                                    <h1 className="heading-3 medium">
                                        {this.props.t(
                                            `meal/weekday${this.props.date.getDay()}`
                                        )}
                                    </h1>
                                    <div className="macro-and-kcal">
                                        {this.renderDietType()}
                                        <Row>
                                            <Col
                                                xs="3"
                                                className="text-center border-right"
                                            >
                                                <h3 className="mb-0 primary semi-bold">
                                                    {this.props.kcal}
                                                </h3>
                                                kcal
                                            </Col>
                                            <Col
                                                xs="3"
                                                className="text-center border-right"
                                            >
                                                <h3 className="mb-0 primary semi-bold">
                                                    {
                                                        this.props.macro.protein
                                                            .percentage
                                                    }
                                                    %
                                                </h3>
                                                {this.props.t('meal/proteins')}
                                            </Col>
                                            <Col
                                                xs="3"
                                                className="text-center border-right"
                                            >
                                                <h3 className="mb-0 primary semi-bold">
                                                    {
                                                        this.props.macro.fat
                                                            .percentage
                                                    }
                                                    %
                                                </h3>
                                                {this.props.t('meal/fat')}
                                            </Col>
                                            <Col xs="3" className="text-center">
                                                <h3 className="mb-0 primary semi-bold">
                                                    {
                                                        this.props.macro
                                                            .carbohydrates
                                                            .percentage
                                                    }
                                                    %
                                                </h3>
                                                {this.props.t(
                                                    'meal/carbohydrates'
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                    {this.renderHolidayAlert()}
                                </Col>
                            </Row>
                        </Container>
                    </header>
                    {this.props.children()}
                </div>

                <CSSTransition
                    in={this.props.showModal}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="view bg-white">
                        {this.props.renderMealLinks()}
                        {this.props.renderMealDetails()}
                        {!this.props.mobileAppMode && (
                            <Footer forceReloadLinks={false} />
                        )}
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={this.props.showModalWithReplacements}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="view bg-white">
                        {this.props.renderMealReplacementsList()}
                        {!this.props.mobileAppMode && (
                            <section className="pt-0">
                                <Container className="text-center">
                                    <Row>
                                        <Col md={{ size: 4, offset: 4 }}>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => {
                                                    window.history.back();
                                                    callNativeAppRemoveLastRoute(
                                                        'diet'
                                                    );
                                                }}
                                                type="button"
                                                className="w-100"
                                            >
                                                {this.props.t('cancel')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        )}
                        {!this.props.mobileAppMode && (
                            <Footer forceReloadLinks={false} />
                        )}
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={this.props.showModalWithDayReplacements}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="view bg-white">
                        {this.props.renderChangeDay()}

                        {!this.props.mobileAppMode && (
                            <section className="pt-0">
                                <Container className="text-center">
                                    <Row>
                                        <Col md={{ size: 4, offset: 4 }}>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => {
                                                    window.history.back();
                                                    callNativeAppRemoveLastRoute(
                                                        'diet'
                                                    );
                                                }}
                                                type="button"
                                                className="w-100"
                                            >
                                                {this.props.t('cancel')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        )}
                        {!this.props.mobileAppMode && (
                            <Footer forceReloadLinks={false} />
                        )}
                    </div>
                </CSSTransition>
            </Layout>
        );
    }
}
export { DayPlan };
export default withFirebase(withMobileAppMode(withGTM(withLocale(DayPlan))));
