import React from 'react';
import PropTypes from 'prop-types';
import ShoppingListPlaceholder from 'ShoppingList/Index/Placeholder/ShoppingListPlaceholder';
import withLoading from 'Layout/withLoading';
import { DateType } from 'types/CommonTypes';
import ShoppingListContainer from './ShoppingListContainer';
import NoDiet from './Actions/NoDiet';

class ShoppingListIndexContainer extends React.Component {
    static propTypes = {
        handleMarkProduct: PropTypes.func.isRequired,
        handlePeriodChange: PropTypes.func.isRequired,
        availableDays: PropTypes.arrayOf(DateType).isRequired,
        shoppingList: PropTypes.shape({
            categories: PropTypes.arrayOf(String).isRequired,
            period: PropTypes.shape({
                start: DateType.isRequired,
                end: DateType.isRequired,
            }),
            __typename: PropTypes.string.isRequired,
        }).isRequired,
        loading: PropTypes.bool.isRequired,
        cacheHit: PropTypes.bool.isRequired,
        hasNetworkError: PropTypes.bool.isRequired,
    };

    render() {
        const shoppingList = this.props.shoppingList;
        if (!shoppingList) {
            return <NoDiet />;
        }

        return (
            <ShoppingListContainer
                handleMarkProduct={this.props.handleMarkProduct}
                handlePeriodChange={this.props.handlePeriodChange}
                availableDays={this.props.availableDays}
                shoppingList={this.props.shoppingList}
                cacheHit={this.props.cacheHit}
                hasNetworkError={this.props.hasNetworkError}
                loading={this.props.loading}
            />
        );
    }
}

export { ShoppingListIndexContainer };

export default withLoading(ShoppingListIndexContainer, ShoppingListPlaceholder);
