import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Collapse } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Price from 'view/Price/Price';
import ShoppingCartProduct from 'view/ShoppingCart/Product/ShoppingCartProduct';
import { ReactComponent as DownArrow } from 'assets/icons/chevron-down.svg';

const SHOW_PRODUCTS_MIN_HEIGHT_EM = 50;

export class ShoppingCartOrderProductsSummaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { showProducts: this.defaultShowProducts };
    }

    // eslint-disable-next-line class-methods-use-this
    get defaultShowProducts() {
        if (typeof window.matchMedia !== 'function') {
            return false;
        }

        return window.matchMedia(
            `(min-height: ${SHOW_PRODUCTS_MIN_HEIGHT_EM}em)`
        ).matches;
    }

    toggle() {
        this.setState(prevState => ({
            showProducts: !prevState.showProducts,
        }));
    }

    renderArrow() {
        if (this.state.showProducts) {
            return (
                <span className="collapse-arrow open">
                    <span className="SVGInline">
                        <DownArrow />
                    </span>
                </span>
            );
        }

        return (
            <span className="collapse-arrow">
                <span className="SVGInline">
                    <DownArrow />
                </span>
            </span>
        );
    }

    renderProductList() {
        return this.props.products.map(product => (
            <Row
                key={product.id}
                data-test="product-row"
                className="summary-product"
            >
                <Col>
                    {' '}
                    <div
                        dangerouslySetInnerHTML={{
                            __html: product.name,
                        }}
                    />
                </Col>
                {product.isItPossibleToBuyMoreThanOne ? (
                    <Col className="text-center">{product.amount}</Col>
                ) : (
                    ''
                )}
                <Col className="price">{`${product.sumPrice}`}</Col>
            </Row>
        ));
    }

    renderProducts() {
        if (this.props.products.length === 1) {
            return this.renderProductList();
        }

        return (
            <Fragment>
                <Row data-test="products-price" onClick={this.toggle}>
                    <Col>
                        {this.props.t('cart/summary/products')}{' '}
                        {this.renderArrow()}
                    </Col>
                    <Col className="price">
                        {this.props.productsPrice.toString()}
                    </Col>
                </Row>
                <Collapse isOpen={this.state.showProducts}>
                    {this.renderProductList()}
                </Collapse>
            </Fragment>
        );
    }

    render() {
        return (
            <section className="cart-summary">
                <Container>
                    <h2>{this.props.t('cart/order-summary')}</h2>
                    {this.renderProducts()}
                    <Row
                        data-test="delivery-option-price"
                        className="delivery-option-price"
                    >
                        <Col>{this.props.t('cart/summary/delivery')}</Col>
                        <Col className="price">
                            {this.props.deliveryOptionPrice.toString()}
                        </Col>
                    </Row>
                    <Row data-test="total-price">
                        <Col>
                            <strong>
                                {this.props.t('cart/summary/total')}
                            </strong>
                        </Col>
                        <Col className="price">
                            <strong>{this.props.totalPrice.toString()}</strong>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

ShoppingCartOrderProductsSummaryComponent.propTypes = {
    t: PropTypes.func.isRequired,
    products: PropTypes.arrayOf(PropTypes.instanceOf(ShoppingCartProduct))
        .isRequired,
    productsPrice: PropTypes.instanceOf(Price).isRequired,
    deliveryOptionPrice: PropTypes.instanceOf(Price).isRequired,
    totalPrice: PropTypes.instanceOf(Price).isRequired,
};

export default withLocale(ShoppingCartOrderProductsSummaryComponent);
