import React, { Component } from 'react';
import { Container, Button, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { PATH_DAY_PLAN_INDEX } from 'DailyDietPlan/DayPlanPaths';
import { formatIsoDate } from 'utils/dateFormatter';
import Layout from 'Layout/Layout';

import eat from 'assets/icons/diet_intro/eat_icon.svg';
import hydration from 'assets/icons/diet_intro/hydration_icon.svg';
import fastCooking from 'assets/icons/diet_intro/fast_cooking_icon.svg';
import groceries from 'assets/icons/diet_intro/groceries_icon.svg';
import changeIngredients from 'assets/icons/diet_intro/change_icon.svg';
import mealsSchedule from 'assets/icons/diet_intro/meals_schedule_icon.svg';
import measurements from 'assets/icons/diet_intro/measurements_icon.svg';
import bodyShape from 'assets/icons/diet_intro/body_shape_icon.svg';
import contact from 'assets/icons/diet_intro/contact_icon.svg';

import mlf from 'assets/images/dayplan/intro_img.png';
import PropTypes from 'prop-types';

class DayPlanIntro extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({
                date: PropTypes.string,
            }),
        }).isRequired,
    };

    newLineFormatter = content => {
        const newContent = content
            .trim()
            .split('\n')
            .reduce((carry, line) => {
                if (carry.length) {
                    carry.push(<br key={line} />);
                }
                carry.push(line);
                return carry;
            }, []);

        return newContent;
    };

    render() {
        let dateString;

        if (this.props.location.state) {
            dateString = formatIsoDate(this.props.location.state.date);
        } else {
            dateString = formatIsoDate(new Date());
        }

        global.localStorage.setItem('dietIsReady', true);

        return (
            <Layout>
                <section>
                    <Container>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }} className="mb-4">
                                <h1 className="title-large semi-bold">
                                    {this.newLineFormatter(
                                        this.props.t('diet-intro/title')
                                    )}
                                </h1>
                                <img
                                    src={mlf}
                                    className="img-fluid mb-4"
                                    alt=""
                                />
                                <p>{this.props.t('diet-intro/p0')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }} className="mb-4">
                                <h2 className="title-medium semi-bold d-flex align-items-center">
                                    <img src={eat} alt="" className="mr-3" />
                                    {this.props.t('diet-intro/p1/title')}
                                </h2>
                                <p>
                                    {this.newLineFormatter(
                                        this.props.t('diet-intro/p1/content')
                                    )}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }} className="mb-4">
                                <h2 className="title-medium semi-bold d-flex align-items-center">
                                    <img
                                        src={hydration}
                                        alt=""
                                        className="mr-3"
                                    />
                                    {this.props.t('diet-intro/p2/title')}
                                </h2>
                                <p>{this.props.t('diet-intro/p2/content')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }} className="mb-4">
                                <h2 className="title-medium semi-bold d-flex align-items-center">
                                    <img
                                        src={fastCooking}
                                        alt=""
                                        className="mr-3"
                                    />
                                    {this.props.t('diet-intro/p3/title')}
                                </h2>
                                <p>{this.props.t('diet-intro/p3/content')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }} className="mb-4">
                                <h2 className="title-medium semi-bold d-flex align-items-center">
                                    <img
                                        src={groceries}
                                        alt=""
                                        className="mr-3"
                                    />
                                    {this.props.t('diet-intro/p4/title')}
                                </h2>
                                <p>{this.props.t('diet-intro/p4/content')}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={{ size: 8, offset: 2 }} className="mb-4">
                                <h2 className="title-medium semi-bold d-flex align-items-center">
                                    <img
                                        src={changeIngredients}
                                        alt=""
                                        className="mr-3"
                                    />
                                    {this.props.t('diet-intro/p5/title')}
                                </h2>
                                <p>{this.props.t('diet-intro/p5/content')}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={{ size: 8, offset: 2 }} className="mb-4">
                                <h2 className="title-medium semi-bold d-flex align-items-center">
                                    <img
                                        src={mealsSchedule}
                                        alt=""
                                        className="mr-3"
                                    />
                                    {this.props.t('diet-intro/p6/title')}
                                </h2>
                                <p>{this.props.t('diet-intro/p6/content')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }} className="mb-4">
                                <h2 className="title-medium semi-bold d-flex align-items-center">
                                    <img
                                        src={measurements}
                                        alt=""
                                        className="mr-3"
                                    />
                                    {this.props.t('diet-intro/p8/title')}
                                </h2>
                                <p>{this.props.t('diet-intro/p8/content')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }} className="mb-4">
                                <h2 className="title-medium semi-bold d-flex align-items-center">
                                    <img
                                        src={bodyShape}
                                        alt=""
                                        className="mr-3"
                                    />
                                    {this.props.t('diet-intro/p7/title')}
                                </h2>
                                <p>{this.props.t('diet-intro/p7/content')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }} className="mb-4">
                                <h2 className="title-medium semi-bold d-flex align-items-center">
                                    <img
                                        src={contact}
                                        alt=""
                                        className="mr-4"
                                    />
                                    {this.props.t('diet-intro/p9/title')}
                                </h2>
                                <p>{this.props.t('diet-intro/p9/content')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }}>
                                <h3 className="title-small semi-bold">
                                    {this.newLineFormatter(
                                        this.props.t('diet-intro/sign')
                                    )}
                                </h3>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <footer className="diet-intro-footer">
                    <Container>
                        <Row>
                            <Col
                                md={{ size: 8, offset: 2 }}
                                className="text-center"
                            >
                                <Button
                                    color="primary"
                                    className="w-100"
                                    tag={Link}
                                    to={{
                                        pathname: `${PATH_DAY_PLAN_INDEX}/${dateString}`,
                                    }}
                                >
                                    {this.props.t('diet-intro/start-diet')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </Layout>
        );
    }
}

export default withLocale(withRouter(DayPlanIntro));
