import ShoppingCartProductsFactory from 'view/ShoppingCart/Product/ShoppingCartProductsFactory';
import { PROMO_CODE_NAMESPACE } from 'view/PromoCode/PromoCodeNamespace';
import ShoppingCartProductOrder from './ShoppingCartProductOrder';

export default class ShoppingCartProductOrderFactory {
    static createFromState(state) {
        const products = ShoppingCartProductsFactory.createFromState(state)
            .products;

        return new ShoppingCartProductOrder(
            state.shoppingCart.token,
            state.shoppingCart.firstSource,
            products,
            state[PROMO_CODE_NAMESPACE] && state[PROMO_CODE_NAMESPACE].value
                ? state[PROMO_CODE_NAMESPACE].value.value
                : ''
        );
    }
}
