import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Alert } from 'reactstrap';
import Layout from 'Layout/Layout';
import withLoading from 'Layout/withLoading';
import ContentPlaceholder from 'Content/Index/ContentPlaceholder';
import withGTM from 'view/GoogleTagManager/withGTM';
import { PATHS } from 'config/paths';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import markel from 'assets/images/sign_up_img.png';
import appStore from 'assets/icons/app_store_badge.svg';
import googlePlay from 'assets/icons/google_play_badge.svg';
import removeAuthCredentials from '@dietlabs/components/src/Auth/setAuthCredentials';
import { GOOGLE_PLAY_URL, APP_STORE_URL } from 'consts';
import { withLocale } from '@dietlabs/components';
import { EVENT_CATEGORY_CONVERSION } from 'view/GoogleTagManager/EventCategories';

const BuyThankYouContainer = ({
    t,
    reportPayment,
    token,
    GTM,
    trackFirebaseEvent,
}) => {
    const [userEmail, setUserEmail] = useState();
    const [isValidationError, setIsValidationError] = useState(false);

    const handleReportPayment = async () => {
        try {
            const res = await reportPayment({ token });
            const result = res.data.sale.reportPayment;
            if (!result) {
                window.location = PATHS.EXTERNAL.HOME;
                return null;
            }

            const type = result.__typename;

            if (type === 'NotReportedPayment') {
                if (result.reportData) {
                    result.reportData = result.reportData.map(JSON.parse);
                    result.reportData.forEach(data => GTM.api.gtm.push(data));
                    GTM.api.trigger({
                        event: EVENT_CATEGORY_CONVERSION,
                    });

                    trackFirebaseEvent('screen_view', {
                        firebase_screen: 'stripe_success',
                    });
                }
            } else if (type === 'CanceledPayment') {
                throw new Error(
                    'Unexpected response. Typename: CanceledPayment'
                );
            } else if (type === 'ValidationException') {
                setIsValidationError(true);
                throw new Error('ValidationException');
            }
            setUserEmail(result.user.email);
            return null;
        } catch (err) {
            throw new Error(err);
        }
    };

    useEffect(() => {
        handleReportPayment();
        removeAuthCredentials();
    }, []);

    const newLineFormatter = content => {
        const newContent = content
            .trim()
            .split('\n')
            .reduce((carry, line) => {
                if (carry.length) {
                    carry.push(<br key={line} />);
                }
                carry.push(line);
                return carry;
            }, []);

        return newContent;
    };

    return (
        <Layout page="buy">
            <section>
                <Container>
                    {isValidationError ? (
                        <Alert color="danger" className="mt-3 mb-0">
                            {t('buy/thank-you/token-error')}
                        </Alert>
                    ) : (
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <Row>
                                    <Col xs={{ size: 10, offset: 1 }}>
                                        <img
                                            src={markel}
                                            alt=""
                                            className="img-fluid mb-4"
                                        />
                                    </Col>
                                </Row>
                                <h4 className="title-small medium">
                                    {t('buy/thank-you/title')}
                                </h4>
                                <p className="mb-0 black">
                                    {t('buy/thank-you/sent-confirmation')}:{' '}
                                    <br />
                                    <strong>{userEmail}</strong>
                                </p>
                                <a
                                    className="mx-2 my-4 d-inline-block"
                                    href={APP_STORE_URL}
                                >
                                    <img src={appStore} alt="App store" />
                                </a>
                                <a
                                    className="mx-2 my-4 d-inline-block"
                                    href={GOOGLE_PLAY_URL}
                                >
                                    <img src={googlePlay} alt="Google Play" />
                                </a>

                                <p className="black">
                                    {newLineFormatter(
                                        t('buy/thank-you/download app')
                                    )}
                                </p>
                            </Col>
                        </Row>
                    )}
                </Container>
            </section>
        </Layout>
    );
};

BuyThankYouContainer.propTypes = {
    t: PropTypes.func.isRequired,
    GTM: PropTypes.shape().isRequired,
    reportPayment: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    trackFirebaseEvent: PropTypes.func.isRequired,
};

export default withFirebase(
    withGTM(withLoading(withLocale(BuyThankYouContainer)), ContentPlaceholder)
);
