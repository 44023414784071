import React from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Button,
    Card,
    CardDeck,
    CardBody,
    CardHeader,
    CardFooter,
} from 'reactstrap';
import { Redirect } from 'react-router';
import { PATHS } from 'config/paths';
import { withLocale } from '@dietlabs/components';
import ShoppingCartProduct from 'view/ShoppingCart/Product/ShoppingCartProduct';
import ShoppingCartUpSellProductDetails from 'view/ShoppingCart/UpSell/ShoppingCartUpSellProductDetailsContainer';
import { ReactComponent as Basket } from 'assets/icons/basket.svg';

export class ShoppingCartUpSellProductComponent extends React.Component {
    render() {
        if (
            !this.props.token ||
            this.props.cartProducts.length === 0 ||
            this.props.upSellProducts.length === 0
        ) {
            return <Redirect to={PATHS.CART.INDEX} />;
        }
        return (
            <section>
                <Container>
                    <CardDeck
                        className={
                            this.props.upSellProducts.length > 1
                                ? 'multi-products'
                                : ''
                        }
                    >
                        {this.props.upSellProducts.map(product => (
                            <Card
                                key={product.id}
                                data-test="product"
                                className="product"
                            >
                                {product.isBestseller ? (
                                    <div className="product-bestseller">
                                        bestseller
                                    </div>
                                ) : (
                                    ''
                                )}
                                <CardHeader>
                                    <span
                                        data-test="product-name"
                                        className="product-name"
                                        dangerouslySetInnerHTML={{
                                            __html: product.name,
                                        }}
                                    ></span>
                                </CardHeader>
                                <CardBody>
                                    <img
                                        src={product.thumbnailUrl}
                                        className="img-fluid mb-3"
                                        alt=""
                                    />

                                    {product.price.promo.amount !==
                                    product.price.regular.amount ? (
                                        <React.Fragment>
                                            <span className="product-save">
                                                {this.props.t(
                                                    'cart/upsell/you-save'
                                                )}{' '}
                                                {`${product.diffPrice}`}
                                            </span>
                                            <span
                                                data-test="product-price"
                                                className="product-price"
                                            >
                                                {`${product.price.promo}`}
                                            </span>
                                            <span
                                                data-test="product-old-price"
                                                className="product-old-price"
                                            >
                                                {`${product.price.regular}`}
                                            </span>
                                        </React.Fragment>
                                    ) : (
                                        <span
                                            data-test="product-price"
                                            className="product-price"
                                        >
                                            {`${product.price.regular}`}
                                        </span>
                                    )}

                                    {/* <span
                                        data-test="product-description"
                                        className="product-description"
                                    >
                                        {product.shortDescription}
                                    </span> */}
                                </CardBody>

                                <CardFooter>
                                    {this.props.productIsInCart(
                                        product.id,
                                        this.props.cartProducts
                                    ) ? (
                                        <Button
                                            color="primary"
                                            data-test="product-added"
                                            disabled
                                        >
                                            <span className="SVGInline pr-1">
                                                <Basket />
                                            </span>{' '}
                                            {this.props.t('cart/upsell/added')}
                                        </Button>
                                    ) : (
                                        <Button
                                            color="primary"
                                            data-test="add-product-button"
                                            onClick={() =>
                                                this.props.add(product.id)
                                            }
                                        >
                                            <span className="SVGInline pr-1">
                                                <Basket />
                                            </span>{' '}
                                            {this.props.t(
                                                'cart/upsell/add-product'
                                            )}
                                        </Button>
                                    )}
                                    <br />
                                    <ShoppingCartUpSellProductDetails
                                        product={product}
                                        data-test="product-details"
                                    />
                                </CardFooter>
                            </Card>
                        ))}
                    </CardDeck>
                </Container>
            </section>
        );
    }
}

ShoppingCartUpSellProductComponent.propTypes = {
    t: PropTypes.func.isRequired,
    upSellProducts: PropTypes.arrayOf(PropTypes.instanceOf(ShoppingCartProduct))
        .isRequired,
    cartProducts: PropTypes.arrayOf(PropTypes.instanceOf(ShoppingCartProduct))
        .isRequired,
    token: PropTypes.string,
    add: PropTypes.func.isRequired,
    productIsInCart: PropTypes.func.isRequired,
};

export default withLocale(ShoppingCartUpSellProductComponent);
