import React from 'react';
import {
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    FormFeedback,
    Row,
    Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import useWindowsSize from 'utils/useWindowsSize';
import { InputErrorType } from 'types/CommonTypes';
import withRequired from './withRequired';

const InputNumber = ({
    step,
    name,
    value,
    handleChange,
    onBlur,
    required,
    disabled,
    unit,
    errors,
    inline,
    hide,
    label,
    labelSize,
    inputWrapperRef,
}) => {
    const { width } = useWindowsSize();

    const renderInput = errorMgs => (
        <React.Fragment>
            <InputGroup
                className={
                    errorMgs ? 'form-control is-invalid' : 'form-control'
                }
            >
                <Input
                    type="number"
                    step={step}
                    name={name}
                    value={value || ''}
                    onChange={handleChange}
                    onBlur={onBlur}
                    required={required}
                    disabled={!!disabled}
                />

                <InputGroupAddon addonType="append">{unit}</InputGroupAddon>
            </InputGroup>
            <FormFeedback>
                {errorMgs
                    ? errorMgs.map((message, k) => <li key={k}>{message}</li>)
                    : ''}
            </FormFeedback>
        </React.Fragment>
    );

    let errorMgs;

    if (errors) {
        errors.details.forEach(error => {
            if (error.fieldName === name) {
                errorMgs = error.messages;
            }
        });
    }

    const formGroupClasses = [];
    if (hide) {
        formGroupClasses.push('d-none');
    }
    if (errorMgs) {
        formGroupClasses.push('is-invalid align-items-center');
    }

    return (
        <div ref={inputWrapperRef}>
            <FormGroup className={formGroupClasses.join(' ')}>
                <Row form className="align-items-center">
                    {label ? (
                        <Label
                            for={name}
                            className={
                                // eslint-disable-next-line no-nested-ternary
                                inline
                                    ? width > 360
                                        ? `col-6 mb-0 ${labelSize || ''}`
                                        : `col-4 mb-0 ${labelSize || ''}`
                                    : `${labelSize || ''}`
                            }
                        >
                            {required ? <sup>*</sup> : ''} {label}
                        </Label>
                    ) : (
                        ''
                    )}
                    {inline ? (
                        <Col xs={width > 360 ? 6 : 8}>
                            {renderInput(errorMgs)}
                        </Col>
                    ) : (
                        renderInput(errorMgs)
                    )}
                </Row>
            </FormGroup>
        </div>
    );
};

InputNumber.propTypes = {
    errors: InputErrorType,
    label: PropTypes.node,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    unit: PropTypes.string,
    value: PropTypes.number,
    step: PropTypes.number,
    validationErrors: PropTypes.object, //eslint-disable-line
    onBlur: PropTypes.func,
    inline: PropTypes.bool,
    inputWrapperRef: PropTypes.object, //eslint-disable-line
};

InputNumber.defaultProps = {
    errors: undefined,
    required: false,
    disabled: false,
    unit: undefined,
    value: undefined,
    step: 0.01,
    validationErrors: undefined,
    label: null,
    onBlur: undefined,
    inline: false,
    inputWrapperRef: undefined,
};

export default withRequired(InputNumber);
