import { MENU } from 'consts';
import ContactPage from './Index/ContactPage';

const PATH_CONTACT = '/contact';
const routes = [
    {
        path: PATH_CONTACT,
        component: ContactPage,
        private: false,
        exact: true,
        title: 'route-title/contact',
        menuType: MENU.SIMPLE,
    },
];

export { routes };
