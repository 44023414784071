import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import endSubscriptionImg from 'assets/images/end_subscription_img.png';
import subscriptionImg from 'assets/images/subscription_img.png';
import { withLocale } from '@dietlabs/components';
import GraphQLFetch from '@dietlabs/components/src/GraphQL/GraphQLFetch';

import { formatLongDate, createDateObject } from 'utils/dateFormatter';
import { callNativeApp } from 'components/Helpers/callNativeApp';
import { callNativeRestorePurchases } from 'components/Helpers/callNativeRestorePurchases';

import { PATHS } from 'config/paths';
import Layout from 'Layout/Layout';
import withMobileAppMode from 'Layout/withMobileAppMode';
import query from './FetchAccess';

class UnauthorizedException extends Component {
    static propTypes = {
        accessTo: PropTypes.instanceOf(Date),
        mobileAppMode: PropTypes.bool.isRequired,
        mobileApp: PropTypes.shape({
            active: PropTypes.bool,
            os: PropTypes.string,
            version: PropTypes.string,
        }).isRequired,
    };

    static defaultProps = {
        accessTo: null,
    };

    checkAccess = () => {
        if (
            this.props.mobileApp.os &&
            this.props.mobileApp.os.toLowerCase().startsWith('ios')
        ) {
            callNativeRestorePurchases();
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        GraphQLFetch.runQuery(query).then(res => {
            if (res.data.me.diet.accessTo) {
                const accessTo = createDateObject(res.data.me.diet.accessTo);

                if (accessTo >= today) {
                    window.location.reload();
                }
            }
        });
    };

    handleClick(event) {
        if (this.props.mobileAppMode) {
            event.preventDefault();
            callNativeApp('/', 'buy-diet');
        }
    }

    render() {
        const { t, accessTo } = this.props;

        // let refreshLabel = t('error/unauthorized/subscription/refresh');

        // if (
        //     this.props.mobileApp.os &&
        //     this.props.mobileApp.os.toLowerCase().startsWith('ios')
        // ) {
        //     refreshLabel = t(
        //         'error/unauthorized/subscription/restore-purchuases'
        //     );
        // }

        let title;
        let paragraphText;
        let image;

        if (accessTo) {
            title = t('error/unauthorized/subscription/expired/title', {
                accessTo: formatLongDate(accessTo),
            });
            paragraphText = t(
                'error/unauthorized/subscription/expired/content'
            );
            image = endSubscriptionImg;
        } else {
            title = t('error/unauthorized/subscription/never-had/title');
            paragraphText = t(
                'error/unauthorized/subscription/never-had/content'
            );
            image = subscriptionImg;
        }

        // Insert <br> between each line
        title = title
            .trim()
            .split('\n')
            .reduce((carry, line) => {
                if (carry.length) {
                    carry.push(<br key={line} />);
                }
                carry.push(line);
                return carry;
            }, []);

        return (
            <Layout page="unauthorized-exception">
                <section className="bg-white mt-5 pt-5">
                    <Container>
                        <Row>
                            <Col
                                xs="12"
                                md={{ size: 6, offset: 3 }}
                                className="text-center"
                            >
                                <img
                                    className="mb-4 img-fluid"
                                    src={image}
                                    alt=""
                                />
                                <h2 className="title-medium semi-bold">
                                    {title}
                                </h2>
                                <p className="text-center">{paragraphText}</p>
                                <p>
                                    {this.props.mobileAppMode ? (
                                        <Button
                                            color="primary"
                                            className="w-100"
                                            onClick={event =>
                                                this.handleClick(event)
                                            }
                                        >
                                            {t('button/buy-subscribtion')}
                                        </Button>
                                    ) : (
                                        <a
                                            className="btn btn-primary w-100"
                                            href={`${PATHS.EXTERNAL.HOME}#buy`}
                                        >
                                            {t('purchase')}
                                        </a>
                                    )}
                                </p>
                                {/* <p>
                                    <Button
                                        color="primary"
                                        className="w-100"
                                        onClick={this.checkAccess}
                                    >
                                        {refreshLabel}
                                    </Button>
                                </p> */}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withMobileAppMode(withLocale(UnauthorizedException));
