import { PATH_DAY_PLAN_INDEX } from 'DailyDietPlan/DayPlanPaths';
import { PATH_FAVMEALS_INDEX } from 'FavMeals/routes';
import { PATH_HYDRATION } from 'Hydration/paths';
import { PATH_ALL_ARTICLES } from '../../Knowledge/paths';
import { PATHS } from '../paths';

export default [
    {
        path: PATHS.EXTERNAL.HOME,
        externalUrl: PATHS.EXTERNAL.HOME,
        translationKey: 'menu/home',
    },
    {
        path: PATH_DAY_PLAN_INDEX,
        translationKey: 'menu/diet',
    },
    {
        path: PATH_FAVMEALS_INDEX,
        translationKey: 'menu/fav-meals',
    },
    {
        path: PATHS.SHOPPING_LIST,
        translationKey: 'menu/shopping-list',
    },
    {
        path: PATH_HYDRATION,
        translationKey: 'menu/hydration',
    },
    {
        path: PATH_ALL_ARTICLES,
        translationKey: 'menu/coach',
    },
    {
        path: PATHS.CONTACT,
        translationKey: 'menu/contact',
    },
];
