import React from 'react';
import ErrorGeneric from 'components/Error/ErrorGeneric';
import { withLocale } from '@dietlabs/components';
import imageUrl from 'assets/images/error_app_icon.png';

const ErrorApplication = props => (
    <ErrorGeneric imageUrl={imageUrl} topLevel {...props} />
);

export default withLocale(ErrorApplication);
