import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import RegisterMutation from './RegisterMutation';

class RegisterConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    createRegister = mutate => async request => {
        const variables = {
            request,
        };

        const response = await mutate({
            variables,
        });

        return response;
    };

    render() {
        const { children } = this.props;

        return (
            <Mutation mutation={RegisterMutation}>
                {mutate =>
                    children({
                        register: this.createRegister(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default RegisterConnectorApollo;
