import React from 'react';
import { Row, Col } from 'reactstrap';

import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';

class MakeDecisionCurrentMeasurements extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        firstMeasurement: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        lastMeasurement: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        goalWeight: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        dietMode: PropTypes.string.isRequired,
    };

    renderStabilisationMeasurements = () => (
        <div className="mb-4">
            <Row>
                <Col xs="3" className="text-center">
                    <h3>
                        <span className="big-number">
                            {Math.round(
                                this.props.firstMeasurement.value * 10
                            ) / 10}
                        </span>
                        {this.props.lastMeasurement.unit}
                        <br />
                        <span className="black medium">
                            {this.props.t('make-decision/start')}
                        </span>
                    </h3>
                </Col>
                <Col xs="3" className="text-center">
                    <h3>
                        <span className="big-number">
                            {Math.round(this.props.lastMeasurement.value * 10) /
                                10}
                        </span>
                        {this.props.lastMeasurement.unit}
                        <br />
                        <span className="black medium">
                            {this.props.t('make-decision/now')}
                        </span>
                    </h3>
                </Col>
                <Col xs="3" className="text-center">
                    <h3>
                        <span className="big-number">
                            {Math.round(this.props.goalWeight.value * 10) / 10}
                        </span>
                        {this.props.goalWeight.unit}
                        <br />
                        <span className="black medium">
                            {this.props.t('make-decision/goal')}
                        </span>
                    </h3>
                </Col>
                <Col xs="3" className="text-center">
                    <h3>
                        <span className="big-number">
                            {Math.round(
                                (this.props.lastMeasurement.value -
                                    this.props.goalWeight.value) *
                                    10
                            ) / 10}
                        </span>
                        {this.props.goalWeight.unit}
                        <br />
                        <span className="black medium">
                            {this.props.t('make-decision/left')}
                        </span>
                    </h3>
                </Col>
            </Row>
        </div>
    );

    render() {
        const {
            t,
            dietMode,
            goalWeight,
            firstMeasurement,
            lastMeasurement,
        } = this.props;

        if (dietMode === 'stabilization') {
            return this.renderStabilisationMeasurements();
        }

        return (
            <Row>
                <Col xs="4" className="text-center">
                    <h3>
                        <span className="big-number">
                            {Math.round(firstMeasurement.value * 10) / 10}
                        </span>
                        {lastMeasurement.unit}
                        <br />
                        <span className="black medium">
                            {t('make-decision/start')}
                        </span>
                    </h3>
                </Col>
                <Col xs="4" className="text-center">
                    <h3>
                        <span className="big-number">
                            {Math.round(lastMeasurement.value * 10) / 10}
                        </span>
                        {lastMeasurement.unit}
                        <br />
                        <span className="black medium">
                            {t('make-decision/now')}
                        </span>
                    </h3>
                </Col>
                <Col xs="4" className="text-center">
                    <h3>
                        <span className="big-number">
                            {Math.round(goalWeight.value * 10) / 10}
                        </span>
                        {goalWeight.unit}
                        <br />
                        <span className="black medium">
                            {this.props.t('make-decision/goal')}
                        </span>
                    </h3>
                </Col>
            </Row>
        );
    }
}

export default withLocale(MakeDecisionCurrentMeasurements);
