import React, { PureComponent } from 'react';
import { func, number, string } from 'prop-types';
import { Redirect } from 'react-router';
import { PATHS } from 'config/paths';
import ShoppingCartIndexPlaceholder from '../../Index/ShoppingCartIndexPlaceholder';

class ShoppingCartProductAddComponent extends PureComponent {
    static propTypes = {
        addProductToCart: func.isRequired,
        productId: number.isRequired,
        source: string,
    };

    static defaultProps = {
        source: undefined,
    };

    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    async componentDidMount() {
        const { productId, addProductToCart, source } = this.props;
        await addProductToCart(productId, source);
        this.setState({ loading: false });
    }

    render() {
        if (this.state.loading) {
            return <ShoppingCartIndexPlaceholder />;
        }

        return <Redirect to={PATHS.CART.INDEX} />;
    }
}

export default ShoppingCartProductAddComponent;
