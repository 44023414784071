import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import goalReachedImg from 'assets/images/goal_reached_img.png';
import { PATHS } from 'config/paths';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';

class GoalReached extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        renderTimeline: PropTypes.func,
    };

    static defaultProps = {
        renderTimeline: undefined,
    };

    newLineFormatter = content => {
        const newContent = content
            .trim()
            .split('\n')
            .reduce((carry, line) => {
                if (carry.length) {
                    carry.push(<br key={line} />);
                }
                carry.push(line);
                return carry;
            }, []);

        return newContent;
    };

    render() {
        return (
            <Layout page="diet">
                {this.props.renderTimeline && this.props.renderTimeline('top')}
                <section className="my-5">
                    <Container>
                        <Row>
                            <Col
                                xs="12"
                                md={{ size: 6, offset: 3 }}
                                className="text-center"
                            >
                                <img
                                    className="mb-4 img-fluid"
                                    src={goalReachedImg}
                                    role="presentation"
                                    alt=""
                                />
                                <h1 className="title-medium semi-bold">
                                    {this.newLineFormatter(
                                        this.props.t(
                                            'info-pages/goal-reached/title'
                                        )
                                    )}
                                </h1>
                                <p>
                                    {this.newLineFormatter(
                                        this.props.t(
                                            'info-pages/goal-reached/content'
                                        )
                                    )}
                                </p>
                                <Button
                                    color="primary"
                                    className="w-100"
                                    tag={Link}
                                    to={PATHS.MAKE_DECISION}
                                >
                                    {this.props.t(
                                        'info-pages/goal-reached/button'
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export { GoalReached };
export default withLocale(GoalReached);
