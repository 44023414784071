import React from 'react';
import RegisterConnectorApollo from '../Connector/Apollo/Register/RegisterConnectorApollo';
import BuySignUpContainer from './BuySignUpContainer';

const BuySignUpPage = () => {
    return (
        <RegisterConnectorApollo>
            {({ register }) => <BuySignUpContainer {...{ register }} />}
        </RegisterConnectorApollo>
    );
};

export default BuySignUpPage;
