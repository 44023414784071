import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import withStep from './StepContext/withStep';

class StepActions extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        // prevStep: PropTypes.func.isRequired,
        nextStep: PropTypes.func.isRequired,
        isNextButtonDisabled: PropTypes.bool.isRequired,
        currentStep: PropTypes.string.isRequired,
        preload: PropTypes.bool.isRequired,
    };

    render() {
        const {
            nextStep,
            isNextButtonDisabled,
            currentStep,
            preload,
        } = this.props;

        if (currentStep === 'start') {
            return (
                <Button color="primary" onClick={nextStep()} className="w-100">
                    {this.props.t('diet-settings/change-your-diet')}
                </Button>
            );
        }

        if (currentStep === 'finish') {
            return '';
        }

        return (
            <Button
                color="primary"
                onClick={nextStep()}
                disabled={isNextButtonDisabled || preload}
                className="w-100"
            >
                {preload && (
                    <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                    />
                )}
                {this.props.t('next')}
            </Button>
        );
    }
}

export default withStep(withLocale(StepActions));
