import React from 'react';
import PropTypes from 'prop-types';
import ShoppingListCategory from 'ShoppingList/Index/Content/Category/ShoppingListCategory';
import { Row, Col, Alert } from 'reactstrap';
import { withLocale } from '@dietlabs/components';

class ShoppingListContent extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        categories: PropTypes.arrayOf(String).isRequired,
        periodCoverage: PropTypes.bool.isRequired,
    };

    static splitCategoryIntoColumns(categories) {
        const sum = categories.reduce(
            (carry, category) => carry + 2 + category.products.length,
            0
        );
        let tempSum = 0;
        const leftColumn = [];
        const rightColumn = [];

        categories.forEach(category => {
            tempSum = tempSum + 2 + category.products.length;
            if (tempSum <= sum / 2) {
                leftColumn.push(category);
            } else {
                rightColumn.push(category);
            }
        });

        return { leftColumn, rightColumn };
    }

    render() {
        const { categories, periodCoverage, t } = this.props;
        const columns = this.constructor.splitCategoryIntoColumns(categories);

        return (
            <React.Fragment>
                {periodCoverage ? (
                    <Alert color="info" className="mb-4">
                        {t(
                            'shopping-list/alert/no-full-covered-by-diet-in-period'
                        )}
                    </Alert>
                ) : (
                    ''
                )}

                <Row>
                    <Col xs="12" sm="6">
                        {columns.leftColumn.map(
                            ({ categoryId, ...category }) => (
                                <ShoppingListCategory
                                    key={categoryId}
                                    {...category}
                                />
                            )
                        )}
                    </Col>
                    <Col xs="12" sm="6">
                        {columns.rightColumn.map(
                            ({ categoryId, ...category }) => (
                                <ShoppingListCategory
                                    key={categoryId}
                                    {...category}
                                />
                            )
                        )}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export { ShoppingListContent };
export default withLocale(ShoppingListContent);
