import React from 'react';
import LoginConnectorApollo from '@dietlabs/components/src/Auth/Login/ByEmailAndPassword/Connector/Apollo/Login/LoginConnectorApollo';
import BuyLogInContainer from './BuyLogInContainer';

const BuySignUpPage = () => {
    return (
        <LoginConnectorApollo>
            {({ login }) => <BuyLogInContainer {...{ login }} />}
        </LoginConnectorApollo>
    );
};

export default BuySignUpPage;
