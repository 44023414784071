import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';

class SettingsSubscriptionsPlaceholder extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: null,
    };

    render() {
        const { t, className } = this.props;
        return (
            <Container className={className}>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <div className="white-box pb-0">
                            <h3 className="title-small semi-bold">
                                {t('settings/subscriptions/heading')}
                            </h3>
                            <p className="placeholder">&nbsp;</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export { SettingsSubscriptionsPlaceholder };
export default withLocale(SettingsSubscriptionsPlaceholder);
