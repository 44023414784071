import React, { Component } from 'react';
import { FormGroup, CustomInput, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import withRequired from './withRequired';

class InputCheckbox2 extends Component {
    render() {
        let errorMgs;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === this.props.name) {
                    errorMgs = error.messages;
                }
            });
        }

        return (
            <FormGroup className={errorMgs ? 'is-invalid' : ''}>
                <CustomInput
                    type="checkbox"
                    id={this.props.id}
                    name={this.props.name}
                    label={this.props.label}
                    value={this.props.value}
                    checked={this.props.checked}
                    onChange={this.props.handleChange}
                    required={this.props.required}
                    className={`${this.props.square ? 'square' : ''}`}
                />

                <FormFeedback>
                    {errorMgs
                        ? errorMgs.map((message, k) => (
                              <li key={k}>{message}</li>
                          ))
                        : ''}
                </FormFeedback>
            </FormGroup>
        );
    }
}

InputCheckbox2.propTypes = {
    checked: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.node,
    required: PropTypes.bool,
    validationErrors: PropTypes.object, //eslint-disable-line
    square: PropTypes.bool,
};

InputCheckbox2.defaultProps = {
    checked: false,
    label: undefined,
    required: false,
    square: false,
};

export default withRequired(InputCheckbox2);
