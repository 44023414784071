/* eslint-disable no-plusplus */
import { useState } from 'react';
import { formatIsoDate, isLeapYear } from '../utils/dateFormatter';

const getFebDays = year => {
    return isLeapYear(year) ? 29 : 28;
};

const handleDates = (minYear, maxYear, ownDate) => {
    const dates = {
        year: 0,
        month: 0,
        pickedDate: {
            year: 0,
            month: 0,
            day: 0,
        },
    };
    const nowYear = new Date().getFullYear();
    const nowMonth = new Date().getMonth();
    const nowDay = new Date().getDate();

    if (ownDate) {
        dates.year = ownDate.year;
        dates.month = ownDate.month;
        dates.pickedDate.year = ownDate.year;
        dates.pickedDate.month = ownDate.month;
        dates.pickedDate.day = ownDate.day;
    } else if (nowYear < minYear) {
        dates.year = minYear;
        dates.month = 0;
        dates.pickedDate.year = minYear;
        dates.pickedDate.month = 0;
        dates.pickedDate.day = 1;
    } else if (nowYear > maxYear) {
        dates.year = maxYear;
        dates.month = 0;
        dates.pickedDate.year = maxYear;
        dates.pickedDate.month = 0;
        dates.pickedDate.day = 1;
    } else {
        dates.year = nowYear;
        dates.month = nowMonth;
        dates.pickedDate.year = nowYear;
        dates.pickedDate.month = nowMonth;
        dates.pickedDate.day = nowDay;
    }
    return dates;
};

const useCalendar = (min, max, minYear, maxYear, ownDate) => {
    const dates = handleDates(minYear, maxYear, ownDate);
    const [year, setYear] = useState(dates.year);
    const [month, setMonth] = useState(dates.month);

    const [{ pickedYear, pickedMonth, pickedDay }, setPickedDate] = useState({
        pickedYear: dates.pickedDate.year,
        pickedMonth: dates.pickedDate.month,
        pickedDay: dates.pickedDate.day,
    });

    const currentDate = formatIsoDate(new Date());

    const [showDateSelection, setShowDateSelection] = useState(false);
    const [step, setStep] = useState(0);

    const pickedDate = new Date(pickedYear, pickedMonth, pickedDay);
    const daysOfMonth = [
        31,
        getFebDays(year),
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31,
    ];
    const indexFromMon = { 0: 6, 1: 0, 2: 1, 3: 2, 4: 3, 5: 4, 6: 5 };
    const firstDayOfMonth = indexFromMon[new Date(year, month, 1).getDay()];
    const [minDate, maxDate] = [
        new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - Math.abs(min)
        ),
        new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() + Math.abs(max)
        ),
    ];

    const days = [];
    for (let i = 0; i < daysOfMonth[month] + firstDayOfMonth; i++) {
        const calendarDay = i - (firstDayOfMonth - 1);
        if (i < firstDayOfMonth) {
            days.push({
                value: '',
                disabled: true,
            });
        } else if (
            year === pickedYear &&
            month === pickedMonth &&
            calendarDay === pickedDay
        ) {
            days.push({
                value: calendarDay,
                disabled: false,
                active: true,
            });
        } else if (min != null || max != null) {
            const isDisabled =
                new Date(year, month, calendarDay) < minDate ||
                new Date(year, month, calendarDay) > maxDate;
            days.push({
                value: calendarDay,
                disabled: isDisabled,
                current:
                    currentDate ===
                    formatIsoDate(new Date(year, month, calendarDay)),
            });
        } else {
            days.push({
                value: calendarDay,
                disabled: false,
                current:
                    currentDate ===
                    formatIsoDate(new Date(year, month, calendarDay)),
            });
        }
    }

    const changeMonth = direction => {
        if (direction !== 'next' && direction !== 'prev') {
            throw new Error(
                `Required next or prev direction. You put ${direction}`
            );
        }
        if (direction === 'next') {
            if (new Date(year, month, 1) < new Date(maxYear, 11, 1)) {
                if (month > 10) {
                    setMonth(0);
                    setYear(prev => prev + 1);
                } else {
                    setMonth(prev => prev + 1);
                }
            }
        } else if (direction === 'prev') {
            if (new Date(year, month, 1) > new Date(minYear, 0, 1)) {
                if (month < 1) {
                    setMonth(11);
                    setYear(prev => prev - 1);
                } else {
                    setMonth(prev => prev - 1);
                }
            }
        }
    };

    const resetPickedDate = initialPickedDate => {
        setPickedDate({
            pickedYear: initialPickedDate.pickedDate.year,
            pickedMonth: initialPickedDate.pickedDate.month,
            pickedDay: initialPickedDate.pickedDate.day,
        });
    };

    const pickYear = pickingYear => {
        setYear(pickingYear);
        setStep(1);
    };

    const pickMonth = pickingMonth => {
        setMonth(pickingMonth);
        setStep(0);
        setShowDateSelection(false);
    };

    const pickDay = day => {
        setPickedDate({ pickedYear: year, pickedMonth: month, pickedDay: day });
    };
    return {
        year,
        month,
        days,
        changeMonth,
        pickDay,
        pickedDate,
        resetPickedDate,
        pickYear,
        pickMonth,
        step,
        showDateSelection,
        setShowDateSelection,
        dates,
    };
};

export default useCalendar;
