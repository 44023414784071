import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { formatLongDate } from '../../utils/dateFormatter';

class SettingsProductsComponent extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        dietAccessTo: PropTypes.instanceOf(Date),
        dietLifetimeAccess: PropTypes.bool.isRequired,
        dietAccessFromSubscription: PropTypes.bool.isRequired,
        videoWorkoutAccessTo: PropTypes.instanceOf(Date),
        videoWorkoutLifetimeAccess: PropTypes.bool.isRequired,
        videoWorkoutAccessFromSubscription: PropTypes.bool.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        dietAccessTo: null,
        videoWorkoutAccessTo: null,
        className: null,
    };

    renderAccess(lifetimeAccess, hasSubscriptions, accessTo) {
        const { t } = this.props;

        if (lifetimeAccess) {
            return <p>{t('settings/products/access/lifetime')}</p>;
        }

        if (hasSubscriptions) {
            return <p>{t('settings/products/access/from-subscription')}</p>;
        }

        if (accessTo == null) {
            return <p>{t('settings/products/access/none')}</p>;
        }

        if (accessTo < new Date()) {
            return (
                <p>
                    {t('settings/products/access/past-end-date {date}').replace(
                        '{date}',
                        formatLongDate(accessTo)
                    )}
                </p>
            );
        }

        return (
            <p>
                {t('settings/products/access/end-date {date}').replace(
                    '{date}',
                    formatLongDate(accessTo)
                )}
            </p>
        );
    }

    renderDiet() {
        const {
            t,
            dietLifetimeAccess: lifetimeAccess,
            dietAccessTo: accessTo,
            dietAccessFromSubscription: accessFromSubscription,
        } = this.props;

        return (
            <Fragment>
                <p className="semi-bold black">
                    {t('settings/products/diet/heading')}
                </p>

                {this.renderAccess(
                    lifetimeAccess,
                    accessFromSubscription,
                    accessTo
                )}
            </Fragment>
        );
    }

    renderWorkout() {
        const {
            t,
            videoWorkoutLifetimeAccess: lifetimeAccess,
            videoWorkoutAccessTo: accessTo,
            videoWorkoutAccessFromSubscription: accessFromSubscription,
        } = this.props;

        return (
            <Fragment>
                <p className="semi-bold black">
                    {t('settings/products/video-workouts/heading')}
                </p>

                {this.renderAccess(
                    lifetimeAccess,
                    accessFromSubscription,
                    accessTo
                )}
            </Fragment>
        );
    }

    render() {
        const { t, className } = this.props;
        return (
            <Container className={className}>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <div className="white-box">
                            <h2 className="title-small semi-bold">
                                {t('settings/products/heading')}
                            </h2>

                            {this.renderDiet()}
                            {this.renderWorkout()}
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export { SettingsProductsComponent };
export default withLocale(SettingsProductsComponent);
