import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withLoading from 'Layout/withLoading';
import CachedDataMessage from '@dietlabs/components/src/CachedDataMessage/CachedDataMessage';
import HistoryChartPeriodSelect from 'Layout/HistoryChartPeriodSelect';
import { withLocale } from '@dietlabs/components';
import Placeholder from './HydrationDayContainerPlaceholder';
import GoalHeader from './Goal/HydrationDayGoalHeader';
import Goal from './Goal/HydrationDayGoal';
import Progress from './Progress/HydrationDayProgress';
import Delete from './Delete/HydrationDayDelete';
import AddContainer from './Add/HydrationAddContainer';
import Hydration from './Hydration';
import Chart from '../History/HydrationHistoryChart';

class HydrationDayContainer extends Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date).isRequired,
        goal: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        value: PropTypes.number.isRequired,
        changeGoal: PropTypes.func.isRequired,
        changeGoalMessages: PropTypes.arrayOf(PropTypes.string.isRequired)
            .isRequired,
        removeLastPortion: PropTypes.func,
        removeLastPortionMessages: PropTypes.arrayOf(
            PropTypes.string.isRequired
        ).isRequired,
        add: PropTypes.func.isRequired,
        messages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        // cacheHit: PropTypes.bool.isRequired,
        hasNetworkError: PropTypes.bool.isRequired,
        t: PropTypes.func.isRequired,
        loading2: PropTypes.bool.isRequired,
        dateFrom: PropTypes.instanceOf(Date).isRequired,
        dateTo: PropTypes.instanceOf(Date).isRequired,
        unit: PropTypes.string,
        defaultDailyGoal: PropTypes.number,
        days: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.instanceOf(Date).isRequired,
                valueValue: PropTypes.number.isRequired,
                goalValue: PropTypes.number.isRequired,
            })
        ),
        historyLength: PropTypes.number.isRequired,
        setHistoryLength: PropTypes.func.isRequired,
    };

    static defaultProps = {
        removeLastPortion: null,
        unit: null,
        defaultDailyGoal: null,
        days: [],
    };

    handleHistoryLengthChange = event => {
        const value = parseInt(event.target.value, 10);
        this.props.setHistoryLength(value);
    };

    render() {
        return (
            <Hydration>
                {() => (
                    <React.Fragment>
                        <CachedDataMessage
                            // cacheHit={this.props.cacheHit}
                            hasNetworkError={this.props.hasNetworkError}
                        />
                        <GoalHeader
                            unit={this.props.goal.unit}
                            goal={this.props.goal.value}
                            value={this.props.value}
                        />

                        <Progress
                            unit={this.props.goal.unit}
                            goal={this.props.goal.value}
                            value={this.props.value}
                        />

                        <Goal
                            date={this.props.date}
                            unit={this.props.goal.unit}
                            goal={this.props.goal.value}
                            value={this.props.value}
                            changeGoal={this.props.changeGoal}
                            messages={this.props.changeGoalMessages}
                        />

                        <AddContainer
                            unit={this.props.goal.unit}
                            messages={this.props.messages}
                            add={this.props.add}
                        />

                        <Delete
                            removeLastPortion={this.props.removeLastPortion}
                            messages={this.props.removeLastPortionMessages}
                        />

                        <section>
                            <h3 className="title-large semi-bold">
                                {this.props.t('route-title/hydration-history')}
                            </h3>
                            <HistoryChartPeriodSelect
                                value={this.props.historyLength}
                                onChange={this.handleHistoryLengthChange}
                            />

                            <Chart
                                loading={this.props.loading2}
                                dateFrom={this.props.dateFrom}
                                dateTo={this.props.dateTo}
                                unit={this.props.unit}
                                days={this.props.days}
                                defaultDailyGoal={this.props.defaultDailyGoal}
                            />
                        </section>
                    </React.Fragment>
                )}
            </Hydration>
        );
    }
}

export { HydrationDayContainer };
export default withLoading(withLocale(HydrationDayContainer), Placeholder);
